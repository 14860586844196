import React from "react";

import { SvgViewer } from "components/common/viewers/SvgViewer";
import { Loader } from "components/common/Loader";
import { shallowEqual, useSelector } from "react-redux";
import { selectScenarioCurfew } from "slices/scenarios/scenariosSlice";
import { deepMemo } from "utils";

export const CurfewsViewer = deepMemo(({ scenario_uid }) => {
  const curfew = useSelector(selectScenarioCurfew(scenario_uid), shallowEqual);

  return (
    <>
      <center>
        <h4>Relevant Curfews</h4>
      </center>
      <Loader show={curfew?.loading} />
      <div style={{ minHeight: "265px" }}>
        <SvgViewer show_zoom_selector svg={curfew?.data} />
      </div>
    </>
  );
});
