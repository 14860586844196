import React, { useEffect, useState } from "react";
import posthog from "posthog-js";

import { edge_options } from "components/common/viewers/SchedulerSVGViewer";

import { MainNav } from "./MainNav";
import { StatsNav } from "./StatsNav";
import { FlightDetails } from "./FlightDetails";
import { DutyChangesModal } from "./DutyChangesModal";
import { ScenarioRosterViewer } from "./ScenarioRosterViewer";
import { ScenarioSchedulerViewer } from "./ScenarioSchedulerViewer";

import {
  pullScenario,
  pullScenarioData,
  pullScenarioScheduleWithFilters,
  pullScenarioSolution,
  pullScenarioSolutionData,
  selectScenarioInfo,
  selectScenarioReady,
} from "slices/scenarios/scenariosSlice";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deepMemo } from "utils";
import { DateTime } from "luxon";
import { getUser } from "slices/userSlice";

export const ScenarioViewer = deepMemo(
  ({
    solution_name,
    scenario_uid,
    vip_flights,
    no_claim_flights,
    onUpdate,
    base_scenario_uid,
    clearMargin = false,
    toggle = false,
    hide = false,
    hide_filters = false,
    children,
  }) => {
    const [show_schedule, setShowSchedule] = useState(true);
    const [show_full, setShowFull] = useState(!hide);
    const [edge_option, setEdgesOption] = useState(edge_options.ALL_CREW);
    const [detail_flight, setDetailFlight] = useState();
    const [show_flight_details, setShowFlightDetails] = useState(false);
    const [sorting, setSorting] = useState({ flights_and_bckp_on_top: true });
    const [filters, setFilters] = useState({});
    const [percentage, setPercentage] = useState("150%");
    const [diff_toggle, setDiffToggle] = useState(!!base_scenario_uid);
    const [flight_day, setFlightDay] = useState();
    const [show_duties, setShowDuties] = useState(false);
    const user = useSelector(getUser, shallowEqual);

    const is_scenario_ready = useSelector(
      selectScenarioReady(scenario_uid),
      shallowEqual
    );
    const raw_reference_time = useSelector(
      selectScenarioInfo(scenario_uid),
      shallowEqual
    )?.data?.reference_time;

    const dispatch = useDispatch();

    const onUpdateSorting = (_sorting) => {
      setSorting(_sorting);
      dispatch(
        pullScenarioScheduleWithFilters(scenario_uid, base_scenario_uid, {
          ..._sorting,
          ...filters,
        })
      );
    };

    const onUpdateFilters = (_filters) => {
      setFilters(_filters);
      dispatch(
        pullScenarioScheduleWithFilters(scenario_uid, base_scenario_uid, {
          ..._filters,
          ...sorting,
        })
      );
    };

    const reference_time = raw_reference_time
      ? DateTime.fromISO(raw_reference_time, { zone: "utc" }).set({
        seconds: 0,
        milliseconds: 0,
      })
      : undefined;

    useEffect(() => {
      if (base_scenario_uid) setDiffToggle(true);
    }, [base_scenario_uid]);

    useEffect(() => {
      if (scenario_uid && base_scenario_uid && !is_scenario_ready) {
        dispatch(pullScenarioSolution(scenario_uid, base_scenario_uid));
      } else if (scenario_uid && !is_scenario_ready) {
        dispatch(pullScenario(scenario_uid));
      } else if (scenario_uid && base_scenario_uid && is_scenario_ready) {
        dispatch(pullScenarioSolutionData(scenario_uid, base_scenario_uid));
      } else if (scenario_uid && is_scenario_ready) {
        dispatch(pullScenarioData(scenario_uid));
      }
    }, [dispatch, scenario_uid, base_scenario_uid, is_scenario_ready]);

    let scenario_actions = {
      onFlightClick: (flight) => {
        setDetailFlight(flight);
        setShowFlightDetails(true);
      },
    };
    if (!!base_scenario_uid) {
      scenario_actions = {
        ...scenario_actions,
        onFlightCrewClick: (flight_id, flight_day) => {
          setShowDuties(true);
          setDetailFlight(flight_id);
          setFlightDay(flight_day);
        },
      };
    }
    if (!!onUpdate) {
      scenario_actions = {
        ...scenario_actions,
        addNoClaim: (f) =>
          onUpdate({ no_claim_flights: [f, ...no_claim_flights] }),
        removeNoClaim: (f) =>
          onUpdate({
            no_claim_flights: no_claim_flights.filter((i) => i !== f),
          }),
        addVip: (f) => onUpdate({ vip_flights: [f, ...vip_flights] }),
        removeVip: (f) =>
          onUpdate({ vip_flights: vip_flights.filter((i) => i !== f) }),
      };
    }

    let title = solution_name ? `Solution ${solution_name}` : "Operations";
    title +=
      reference_time && reference_time.isValid
        ? ` on ${reference_time.toFormat("yyyy/MM/dd HH:mm")}`
        : " ";

    return (
      <>
        <div
          className="sticky-top"
          style={{ borderTop: clearMargin ? "none" : "1px black solid" }}
        >
          <MainNav
            title={title}
            show_schedule={show_schedule}
            show_full={show_full}
            toggle={toggle}
            toggleFull={() => setShowFull((prev) => !prev)}
            toggleView={() => {
              setShowSchedule((prev) => !prev)
              posthog.capture('scenario_viewer_show_schedule')
            }}
            children={children}
          />
          <StatsNav
            scenario_uid={scenario_uid}
            base_scenario_uid={base_scenario_uid}
            edge_option={edge_option}
            show_schedule={show_schedule}
            updateEdgeOption={(option) => {
              setEdgesOption(option)
              posthog.capture('scenario_viewer_edge')
            }}
            reference_time={reference_time}
            sorting={sorting}
            filters={filters}
            updateSorting={data => {
              onUpdateSorting(data)
              posthog.capture('scenario_viewer_sorting')
            }}
            updateFilters={data => {
              onUpdateFilters(data)
              posthog.capture('scenario_viewer_filters')
            }}
            show_diff_toggle={!!base_scenario_uid}
            setDiffToggle={setDiffToggle}
            hide_filters={hide_filters || diff_toggle}
            percentage={percentage}
            show_warnings_default={!base_scenario_uid}
            updatePercentage={data => {
              setPercentage(data)
              posthog.capture('scenario_viewer_percentage')
            }}
            curfew_scenario_uid={base_scenario_uid || scenario_uid}
          />
        </div>
        {show_full && (
          <div style={{ position: "relative", minHeight: "150px" }}>
            <ScenarioSchedulerViewer
              scenario_uid={scenario_uid}
              hide={!show_schedule}
              show_diff={diff_toggle}
              edge_option={edge_option}
              vip_flights={vip_flights}
              no_claim_flights={no_claim_flights}
              sorting={sorting}
              filters={filters}
              percentage={percentage}
              base_scenario_uid={base_scenario_uid}
              {...scenario_actions}
            />
            <ScenarioRosterViewer
              scenario_uid={scenario_uid}
              base_scenario_uid={base_scenario_uid}
              percentage={percentage}
              hide={show_schedule}
              show_diff={diff_toggle}
            />
          </div>
        )}

        {show_flight_details && (
          <FlightDetails
            show={show_flight_details}
            setShow={setShowFlightDetails}
            flight={detail_flight}
            scenario_uid={scenario_uid}
            base_scenario_uid={base_scenario_uid}
            is_demo={user.is_demo}
          />
        )}

        {show_duties && (
          <DutyChangesModal
            show={show_duties}
            setShow={setShowDuties}
            flight={detail_flight}
            flight_day={flight_day}
            scenario_uid={scenario_uid}
            base_scenario_uid={base_scenario_uid}
          />
        )}
      </>
    );
  }
);
