import { sum } from "lodash";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Octicon from "react-octicon";
import { Duration } from "luxon";
import { kpiLegend } from "./utils";

const titleRow = ({ getValue }) => <span title={getValue()}>{getValue()}</span>;
const isValueValid = (value) => value === null || value === undefined || value === ""
const getValueOrDash = (value) => isValueValid(value) ? "-" : value;

export const costCol = (
  name,
  accessor,
  id,
  maxSize,
  currencyFormatter,
  textAlign = "center"
) => ({
  Header: name,
  header: name,
  description: kpiLegend[name],
  id: id,
  accessorFn: accessor,
  style: { textAlign },
  maxSize,
  size: maxSize,
  cell: ({ getValue }) => {
    const value = getValue();
    if (isValueValid(value)) {
      return <span title="-">-</span>;
    }
    const formatedCost = currencyFormatter.format(value);
    return <span title={formatedCost}>{formatedCost}</span>;
  },
});

const idCell = ({ getValue, row: { original } }) => {
  const is_basic = original.internal_id === "orig-1";
  const value = getValue();
  const id_text = is_basic ? `${value} (B)` : value;
  return <span title={id_text}>{id_text}</span>;
};

const sol_status_map = {
  solved: "✅", //  # Completely solved
  unsolved: "❌", //  # Solution is not completely legal
  partial: "🟠", //  # (Only crew) legal solution but with some incomplete flight
  null: "-", //  # Not considered for solving
};

const namedFormatCol = (name, accessor, id, width, textAlign = "center") => ({
  header: name,
  id: id,
  accessorKey: id,
  description: kpiLegend[name],
  width,
  maxSize: width,
  cell: ({ row: { original } }) => {
    if (isValueValid(original)) {
      return <span title="-">-</span>;
    }
    return <span title={accessor(original)}>{accessor(original)}</span>;
  },
});

const namedCol = (name, accessor, id, width, textAlign = "center") => ({
  header: name,
  id: id,
  description: kpiLegend[name],
  accessorKey: accessor.includes ? accessor : undefined,
  accessorFn: accessor.includes ? undefined : accessor,
  width,
  maxSize: width,
  cell: ({ getValue }) => {
    const value = getValue();
    if (isValueValid(value)) {
      return <span title="-">-</span>;
    }
    return <span title={value}>{value}</span>;
  },
});

const balanceViolationsFlights = (d) =>
  sum(
    d.FleetBalance?.map(
      (record) =>
        record.Missing?.filter((value) => value === "FLIGHT").length || 0
    )
  );

const balanceViolationsBackups = (d) =>
  sum(
    d.FleetBalance?.map(
      (record) =>
        record.Missing?.filter((value) => value === "BACKUP").length || 0
    )
  );

const balanceViolationsSpares = (d) =>
  sum(
    d.FleetBalance?.map(
      (record) =>
        record.Missing?.filter((value) => value === "SPARE").length || 0
    )
  );

const IDColumn = {
  header: "ID",
  id: "id",
  accessorKey: "id",
  description: kpiLegend["ID"],
  cell: idCell,
  size: 60,
};

const DayColumn = {
  header: "D",
  description: kpiLegend["D"],
  id: "day",
  cell: ({ row }) => {
    const parent = row.getParentRow();
    if (parent) {
      return <span>D1</span>;
    }
    return <span>D0</span>;
  },
  size: 50,
};

const InternalIDColumn = {
  header: "InternalID",
  id: "internal_id",
  accessorKey: "internal_id",
  cell: titleRow,
  size: 170,
};
const LabelsColumn = {
  header: "Labels",
  description: kpiLegend["Labels"],
  id: "labels",
  accessorFn: (d) => d.labels?.join(", "),
  cell: titleRow,
  size: 200,
};
const TimeColumn = {
  header: "⏱️",
  id: "time",
  accessorFn: (d) => Duration.fromObject({ seconds: d.order_solution_time }).toFormat('mm:ss'),
  cell: ({ getValue, row }) => {
    const parent = row.getParentRow();
    if (parent) {
      return <span></span>;
    }
    return <span>{getValue()}</span>;
  },
  size: 80,
};
const RealTimeColumn = {
  header: "⏱️ (Real)",
  id: "real_time",
  accessorFn: (d) => Duration.fromObject({ seconds: d.solution_time }).toFormat('mm:ss'),
  cell: ({ getValue, row }) => {
    const parent = row.getParentRow();
    if (parent) {
      return <span></span>;
    }
    return <span>{getValue()}</span>;
  },
  size: 80,
};
const DeleteSolutionColumn = (deleteSolution) => ({
  header: "🗑️",
  id: "delete",
  size: 30,
  cell: ({ row }) =>
    row.original.solver === "manual" && (
      <Button size="sm" onClick={() => deleteSolution(row?.original?.plan_uid)}>
        <Octicon name="trashcan" />
      </Button>
    ),
});

export const getColumns = (
  isAdmin,
  marked_uid,
  onMarkSolution,
  deleteSolution,
  currencyFormatter
) => [
    {
      header: " ",
      columns: [
        {
          header: " ",
          id: "selected",
          size: 30,
          cell: ({ row, ...rest }) => {
            return row.getParentRow() ? (
              <span></span>
            ) : (
              <Form.Check
                type="radio"
                style={{ position: "unset" }}
                checked={row?.original?.plan_uid === marked_uid}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onMarkSolution(row?.original?.plan_uid);
                }}
              />
            );
          },
        },
      ],
    },
    {
      header: "ID",
      columns: isAdmin
        ? [
          IDColumn,
          DayColumn,
          InternalIDColumn,
          DeleteSolutionColumn(deleteSolution),
          LabelsColumn,
          TimeColumn,
          RealTimeColumn
        ]
        : [IDColumn, DayColumn, LabelsColumn, TimeColumn],
    },
    {
      header: "Solution",
      columns: [
        {
          header: "✈️",
          id: "sol_status_aircraft",
          description: kpiLegend["Aircraft emoji"],
          accessorKey: "sol_status",
          cell: ({ getValue, row }) => {
            const parent = row.getParentRow();
            if (parent) {
              const { d1 } = parent.original.sol_status;
              const value = sol_status_map[d1.aircraft];
              return <span>{value}</span>;
            }
            const { d0 } = getValue();
            const value = sol_status_map[d0.aircraft];
            return <span>{value}</span>;
          },
          size: 50,
        },
        {
          header: "👩‍✈️",
          id: "sol_status_crew",
          description: kpiLegend["Crew emoji"],
          accessorKey: "sol_status",
          cell: ({ getValue, row }) => {
            const parent = row.getParentRow();
            if (parent) {
              const { d1 } = parent.original.sol_status;
              const value = sol_status_map[d1.crew];
              return <span>{value}</span>;
            }
            const { d0 } = getValue();
            const value = sol_status_map[d0.crew];
            return <span>{value}</span>;
          },
          size: 50,
        },
        {
          header: "⚠️",
          id: "solution_has_warnings",
          description: kpiLegend["Warning emoji"],
          accessorKey: "HasWarnings",
          cell: ({ getValue }) => {
            const value = getValue() ? "⚠️" : "-";
            return <span>{value}</span>;
          },
          size: 50,
        },
      ],
    },
    {
      header: "Cost",
      columns: [
        costCol("COST", (d) => d.TotalCost, "TotalCost", 130, currencyFormatter),
      ],
    },
    {
      header: "Resources",
      columns: [
        namedCol(
          "BU",
          (d) => d.total_activated_backups,
          "total_activated_backups",
          60
        ),
        namedCol(
          "STBY",
          (d) => d.total_activated_standbys,
          "total_activated_standbys",
          60
        ),
      ],
    },
    {
      header: "OPS Disruptions",
      shortHeader: "🧰",
      columns: [
        { id: "ops_status_toggle", isToggle: true },
        namedCol("CNL", (d) => d.TotalCancellations, "TotalCancellations", 60),
        namedCol(
          "RSD",
          (d) => d.TotalRescheduledFlights,
          "TotalRescheduledFlights",
          60
        ),
        namedCol(
          "C15",
          (d) => d.TotalOTP15Violations,
          "TotalOTP15Violations",
          60
        ),
        namedFormatCol(
          "C180 (R)",
          (d) => `${getValueOrDash(d.TotalOTP180Violations)} (${getValueOrDash(d.otp180_risk)})`,
          "TotalOTP180Violations",
          80
        ),
        namedFormatCol(
          "DV / RT",
          (d) => `${getValueOrDash(d.TotalDivertedFlights)} / ${getValueOrDash(d.TotalReroutedFlights)}`,
          "TotalDivertedFlights",
          80
        ),
      ],
    },
    {
      header: "Aircraft Status",
      shortHeader: "🛩️",
      columns: [
        { id: "aircraft_status_toggle", isToggle: true },
        namedFormatCol(
          "FL. B.",
          (d) =>
            `${balanceViolationsFlights(d)} / ${balanceViolationsBackups(
              d
            )} / ${balanceViolationsSpares(d)}`,
          "TotalOffbalance",
          80
        ),
        namedCol(
          "BASE CHG",
          (d) => d.TotalMispositionedAircrafts,
          "TotalMispositionedAircrafts",
          80
        ),
        namedFormatCol(
          "POS (CNL)",
          (d) => `${getValueOrDash(d.TotalPositionalCreated)} (${getValueOrDash(d.TotalPositionalCanceled)})`,
          "TotalPositionalCreated",
          80
        ),
        namedCol(
          "PD",
          (d) => d.total_post_diversion_created,
          "total_post_diversion_created",
          60
        ),
      ],
    },
    {
      header: "Crew Status",
      shortHeader: "👩‍✈️",
      columns: [
        { id: "crew_status_toggle", isToggle: true },
        namedCol(
          "MSP",
          (d) => d.TotalMispositionedCrewMembers,
          "TotalMispositionedCrewMembers",
          60
        ),
        namedCol(
          "REST",
          (d) => d.total_affected_rest_periods,
          "total_affected_rest_periods",
          60
        ),
        namedCol("FDP", (d) => d.total_fdp_risks, "total_fdp_risks", 60),
        namedCol(
          "F. CHG",
          (d) => d.total_crews_forced_change,
          "total_crews_forced_change",
          60
        ),
        namedCol(
          "EXT",
          (d) => d.total_crews_requiring_extension,
          "total_crews_requiring_extension",
          60
        ),
        namedCol(
          "INC. F.",
          (d) => d.total_incomplete_flights,
          "total_incomplete_flights",
          60
        ),
      ],
    },
    {
      header: "Pax Status",
      shortHeader: "🧳",
      columns: [
        { id: "pax_status_toggle", isToggle: true },
        namedFormatCol(
          "OVB / RBK",
          (d) =>
            `${getValueOrDash(d.total_operative_overbooked_pax)}/${getValueOrDash(d.total_operative_ovb_rebooked_pax)} (${getValueOrDash(d.total_overbooked_pax)}/${getValueOrDash(d.total_overbooked_rebooked_pax)})`,
          "total_operative_overbooked_pax",
          100
        ),
        namedFormatCol(
          "CNL / RBK",
          (d) => `${getValueOrDash(d.total_canceled_pax)} / ${getValueOrDash(d.total_canceled_rebooked_pax)}`,
          "total_canceled_pax",
          100
        ),
        namedFormatCol(
          "MCNX / RBK",
          (d) => `${getValueOrDash(d.total_misconnected_pax)} / ${getValueOrDash(d.total_mcnx_rebooked_pax)}`,
          "total_misconnected_pax",
          100
        ),
        namedFormatCol(
          "DIV / RBK",
          (d) => `${getValueOrDash(d.total_island_diverted_pax)} / ${getValueOrDash(d.total_rebooked_pax_idiv)}`,
          "total_island_diverted_pax",
          100
        ),
      ],
    },
    {
      header: "Schedule Changes",
      shortHeader: "🗓️",
      columns: [
        { id: "schedule_status_toggle", isToggle: true },
        namedCol(
          "AC",
          (d) => d.TotalAircraftsReassigned,
          "TotalAircraftsReassigned",
          60
        ),
        namedCol(
          "DUTIES",
          (d) => d.TotalCrewMembersReassigned,
          "TotalCrewMembersReassigned",
          80
        ),
      ],
    },
  ];
