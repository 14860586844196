import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Jumbotron, Row } from "react-bootstrap";

// import * as api from 'api';
import { Auth, Users } from "api";

import { Loader } from "components/common/Loader";

import { fetchBasics } from "slices";

import styles from "./index.module.css";

export const Login = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [email_error, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [password_error, setPasswordError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (Auth.isAuthenticated()) dispatch(push("/builder"));
  }, [dispatch]);

  const submit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!email || !password) {
      setEmailError(!email && "Missing email");
      setPasswordError(!password && "Missing password");
      return;
    }

    setLoading(true);
    Auth.authenticate(email, password)
      .then((user) => {
        dispatch(fetchBasics());
        const roles = user.roles;
        if (
          roles.indexOf("admin") > -1 ||
          roles.indexOf("manager") > -1 ||
          roles.indexOf("user") > -1
        ) {
          if (location.state && location.state.from) {
            dispatch(push(location.state.from));
          } else {
            dispatch(push("/builder"));
          }
        } else {
          dispatch(push("/unauth"));
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response) {
          const { errors } = error.response;
          setEmailError(errors.email);
          setPasswordError(errors.password);
        }
        toast.error(`Login error: ${error.message}`);
      });
  };

  const forgotPassword = () => {
    if (!email) {
      setEmailError("Missing email");
      return;
    }
    Users.forgotPassword(email)
      .then(() => toast.success("Forgot password: Email send successfully"))
      .catch((error) =>
        toast.error(`Error sending the reset lint: ${error.message}`)
      );
  };

  return (
    <Container fluid className={styles.Wrapper}>
      <Loader show={loading} full />
      <Row>
        <Col>
          <Jumbotron className={styles.Center}>
            <Form onSubmit={submit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  onChange={(event) => setEmail(event.target.value)}
                  type="email"
                  placeholder="Enter email"
                  isInvalid={email_error}
                />
                <Form.Text type="text" className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {email_error}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                  isInvalid={password_error}
                />
                <Form.Control.Feedback type="invalid">
                  {password_error}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              <Button onClick={forgotPassword} variant="link">
                forgot password
              </Button>
            </Form>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};
