import React, { useState } from "react";
import { deepMemo, randomId, useDeepCompareEffect } from "utils";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import Octicon from "react-octicon";

import { getDurationFromISO, optimizeSelect } from "../utils";
import { DisplayCurfews } from "./table";

import classNames from "classnames/bind";
import styles from "./index.module.css";
import { toast } from "react-toastify";
import { DateTimeRange } from "components/common/DateTimeRange";

let cx = classNames.bind(styles);

export { DisplayCurfews } from "./table";

const default_event = { value: "BOTH", label: "BOTH" };
const events = [
  default_event,
  { value: "LANDING", label: "LANDING" },
  { value: "TAKEOFF", label: "TAKEOFF" },
];

export const CloseAirportManager = deepMemo(
  ({
    curfews,
    reference_time,
    onUpdate,
    scenario_stations,
    stations_mapping,
    scenario_aicraft_types,
  }) => {
    const [start, setStart] = useState(reference_time);
    const [end, setEnd] = useState(reference_time);
    const [station, setStation] = useState(null);
    const [event, setEvent] = useState(default_event);
    const [alternatives, setAlternatives] = useState([]);

    useDeepCompareEffect(() => {
      setStart(reference_time);
      setEnd(reference_time);
    }, [reference_time]);

    const resetForm = () => {
      setStation(null);
      setEvent(default_event);
      setAlternatives([]);
      setStart(reference_time);
      setEnd(reference_time);
    };

    const isFormValid = () => {
      return !(!station || !start.isValid || !end.isValid || end < start);
    };
    const createCurfew = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isFormValid()) {
        toast.warning("WARNING: Select an station and a valid duration.");
        return;
      }
      onUpdate([
        ...curfews,
        {
          id: randomId(),
          station_id: station?.value,
          aircraft_types: scenario_aicraft_types,
          type: "WEATHER",
          event: event.value,
          alternatives_to_exclude: alternatives.map((a) => a.value),
          start: start.toISO({ includeOffset: false }),
          end: end.toISO({ includeOffset: false }),
        },
      ]);
      resetForm();
    };

    const changeStation = (new_station) => {
      setStation(new_station);
    };

    return (
      <React.Fragment>
        <Form onSubmit={createCurfew} className={styles.Form}>
          <Form.Row className={styles.TopRow}>
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Stations</InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                filterOption={createFilter({ ignoreAccents: false })}
                classNamePrefix="Select"
                value={station}
                className={cx(styles.Selector, "form-control")}
                options={[...scenario_stations]}
                onChange={changeStation}
                components={optimizeSelect.components}
              />
            </InputGroup>
            <DateTimeRange
              reference_time={reference_time}
              onUpdate={(_start, _end) => {
                setStart(_start);
                setEnd(_end);
              }}
            />
          </Form.Row>
          <Form.Row>
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  Alternatives to exclude
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                classNamePrefix="Select"
                className={cx(styles.Selector, "form-control")}
                isMulti
                value={[...alternatives]}
                onChange={(new_alternatives) =>
                  setAlternatives(new_alternatives || [])
                }
                options={[...scenario_stations]}
              />
            </InputGroup>
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Event</InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                classNamePrefix="Select"
                className={cx(styles.Selector, "form-control")}
                value={event}
                onChange={setEvent}
                options={events}
              />
            </InputGroup>
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Duration</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                readOnly
                value={getDurationFromISO(start, end)}
                onChange={() => { }}
              />
            </InputGroup>
            <Form.Group
              as={Col}
              className={cx(styles.ButtonWrapper, "text-center")}
            >
              <Button variant="info" type="submit" className={styles.Button}>
                <Octicon name="plus" />
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <DisplayCurfews
          curfews={curfews}
          onUpdate={onUpdate}
          stations_mapping={stations_mapping}
        />
      </React.Fragment>
    );
  }
);
