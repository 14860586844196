import { HOST, httpMethods } from "./utils";
import posthog from "posthog-js";
import { deleteCookies, getSessionCookie, isPro } from "utils";
export const login = (user, password) =>
  fetch(new URL(HOST + "/login"), {
    method: httpMethods.POST,
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: user, password: password }),
  })
    .then((resp) => (resp.status !== 200 ? Promise.reject(resp) : resp.json()))
    .then(({ response }) => {
      return response.user;
    })
    .catch((err) => {
      if (err.json) {
        return err.json().then(Promise.reject.bind(Promise));
      }
      return Promise.reject(err);
    });

export const logout = () =>
  fetch(new URL(HOST + "/logout"), {
    method: httpMethods.POST,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((resp) => (resp.status !== 200 ? Promise.reject(resp) : resp))
    .catch((err) => {
      if (err.json) {
        return err.json().then(Promise.reject.bind(Promise));
      }
      return Promise.reject(err);
    });

export const Auth = {
  isAuthenticated: () => Boolean(getSessionCookie()),
  authenticate: (user, password) => login(user, password),
  signout: () => {
    deleteCookies();
    if (isPro) posthog.reset(true);
    return logout();
  },
};
