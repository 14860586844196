import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Octicon from "react-octicon";
import { toast } from "react-toastify";
import posthog from "posthog-js";

import { RecoveryQuery, RecoveryQueryRequest } from "api";
import { copyToClipboard, shareByEmail, deepMemo } from "utils";
import { getIsAdmin } from "slices/userSlice";
import { shallowEqual, useSelector } from "react-redux";

export const QueryFeedback = deepMemo(
  ({ query_uid, no_good_solution, markSolution }) => {
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState({});
    const isAdmin = useSelector(getIsAdmin, shallowEqual);

    useEffect(() => {
      const getFeedback = async (fallback_comment) => {
        try {
          const request = new RecoveryQueryRequest()
          const response = await request.get_feedback(query_uid)
          setFeedback(prev => ({ ...prev, ...response }))
        } catch (error) {
          setFeedback({ client_input_general: fallback_comment })
        }
      }

      if (!!query_uid) {
        setLoading(true);
        const cancelable = RecoveryQuery.get_comment_(query_uid);
        cancelable.rq
          .then((result) => {
            getFeedback(result.comment)
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            toast.error(`Comment fetch: ${error.message}`);
          });
        return () => cancelable.c.abort();
      }
    }, [query_uid]);

    const saveFeedback = () => {
      if (loading) return;
      try {
        const request = new RecoveryQueryRequest()
        request.upsert_feedback(query_uid, feedback)
        toast.success("Feedback saved");
      } catch (error) {
        toast.error(`Feedback submit: ${error.message}`);
      }
    }

    const showOldFeedback = () => alert(JSON.stringify(feedback, null, '\t'))

    const toggleNoGoodSolution = () => {
      if (no_good_solution) {
        markSolution("NotNone")
      } else {
        markSolution("None")
      }
    }


    return (
      <>
        <Navbar
          bg="light-grey"
          variant="dark"
          className="justify-content-between trim-navbar"
        >
          <Navbar.Brand style={{ fontSize: "1rem" }}>Feedback</Navbar.Brand>
          <Nav>
            <Form.Group
              style={{ marginBottom: 0 }}
              controlId="recoveryQuery.solution"
            >
              <Form.Check
                checked={no_good_solution}
                type="radio"
                style={{ color: "#fff" }}
                onChange={toggleNoGoodSolution}
                onClick={toggleNoGoodSolution}
                label="No good solutions"
              />
            </Form.Group>
          </Nav>
        </Navbar>
        <Container fluid className="fluid-top">
          <Row>
            <Col md={1}>
              <Form.Group controlId="recoveryQuery.case">
                <Form.Label>Case Type:</Form.Label>
                <div className="mb-3">
                  <Form.Check
                    inline
                    label="Simulation"
                    name="case_type"
                    type="radio"
                    id="simulated"
                    checked={feedback?.case_type === "simulated"}
                    onChange={(event) => setFeedback(prev => ({ ...prev, case_type: event.target.id }))}
                  />
                  <Form.Check
                    inline
                    label="Real"
                    name="case_type"
                    type="radio"
                    id="real_clean"
                    checked={feedback?.case_type === "real_clean"}
                    onChange={(event) => setFeedback(prev => ({ ...prev, case_type: event.target.id }))}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="recoveryQuery.client_input_general">
                <Form.Label>Other comments</Form.Label>
                <Form.Control
                  as="textarea"
                  value={feedback?.client_input_general}
                  onChange={(event) => setFeedback(prev => ({ ...prev, client_input_general: event.target.value }))}
                />
              </Form.Group>
            </Col>
          </Row>
          {isAdmin && <Row>
            <Col>
              <Form.Group controlId="recoveryQuery.bba_input_private">
                <Form.Label>BBA annotations (INTERNAL)</Form.Label>
                <Form.Control
                  as="textarea"
                  value={feedback?.bba_input_private}
                  onChange={(event) => setFeedback(prev => ({ ...prev, bba_input_private: event.target.value }))}
                />
              </Form.Group>
            </Col>
          </Row>}
          <Row className="mb-3">
            <Col className="text-right">
              {isAdmin && <Button variant="warning" onClick={showOldFeedback}>
                <Octicon name="info" />
              </Button>}
              <Button variant="link" onClick={() => {
                copyToClipboard()
                posthog.capture('recovery_feedback_share_clipboard')
              }}>
                <Octicon name="link" />
              </Button>
              <Button variant="link" onClick={() => {
                shareByEmail()
                posthog.capture('recovery_feedback_share_email')
              }}>
                <Octicon name="mail" />
              </Button>
              <Button variant="bb" onClick={saveFeedback}>
                Save Feedback
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
);
