import React from "react";
import find from "lodash/find";
import { Badge, Table } from "react-bootstrap";
import { Dot, colors } from "../../BaseTable";

export const AircraftFleetBalanceTable = ({ data = [] }) => {
  const fleets = [...new Set(data.map((arft) => arft.AircraftFleet))];
  const base_stations = [
    ...new Set(
      data.filter((arft) => arft.IsBase).map((arft) => arft.NewStation)
    ),
  ];
  const stations = [
    ...new Set(
      data.filter((arft) => !arft.IsBase).map((arft) => arft.NewStation)
    ),
  ];

  const Total = ({ value, color }) =>
    !!value && (
      <Badge
        style={{
          backgroundColor: color || "",
          minWidth: "15px",
          display: "inline-block",
          textAlign: "right",
          marginRight: "5px",
        }}
        variant="secondary"
      >
        {value}
      </Badge>
    );

  const getRecordTotals = (record = {}) => {
    const flight =
      record.Missing?.filter((value) => value === "FLIGHT").length || 0;
    const spare =
      record.Missing?.filter((value) => value === "SPARE").length || 0;
    const backup =
      record.Missing?.filter((value) => value === "BACKUP").length || 0;
    const overfeed =
      record.Overfeed?.filter((value) => value === "ANY").length || 0;
    return {
      flight,
      spare,
      backup,
      overfeed,
    };
  };

  const renderAllTotals = (record) => {
    const { flight, spare, backup, overfeed } = getRecordTotals(record);

    return (
      <>
        <Total value={!!flight && `-${flight}`} color={colors.Red} />
        <Total value={!!spare && `-${spare}`} color={colors.Blue} />
        <Total value={!!backup && `-${backup}`} color={colors.Yellow} />
        <Total value={!!overfeed && `+${overfeed}`} />
      </>
    );
  };

  const legend = [
    { color: colors.Blue, text: "Next day spare" },
    { color: colors.Yellow, text: "Next day backup" },
    { color: colors.Red, text: "Next day flight" },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>Aircraft Fleet Balance</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {legend.map((l) => (
            <Dot key={l.color} color={l.color} text={l.text} />
          ))}
        </div>
      </div>
      <Table bordered>
        <tbody>
          <tr>
            <th />
            <th>Stations</th>
            {fleets.map((fleet) => (
              <th key={fleet}>{fleet}</th>
            ))}
          </tr>
          {base_stations.map((station, key) => (
            <tr key={`${station}-base`}>
              {key === 0 ? <th rowSpan={base_stations.length}>Bases</th> : null}
              <td>{station}</td>
              {fleets.map((fleet) => (
                <td key={`${station}-${fleet}`}>
                  {renderAllTotals(
                    find(data, {
                      IsBase: true,
                      NewStation: station,
                      AircraftFleet: fleet,
                    })
                  )}
                </td>
              ))}
            </tr>
          ))}

          {stations.map((station, key) => (
            <tr key={`${station}-other`}>
              {key === 0 ? <th rowSpan={stations.length}>Others</th> : null}
              <td>{station}</td>
              {fleets.map((fleet) => (
                <td key={`${station}-${fleet}`}>
                  {renderAllTotals(
                    find(data, {
                      IsBase: false,
                      NewStation: station,
                      AircraftFleet: fleet,
                    })
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
