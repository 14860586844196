import React from "react";
import { ScenarioRequest } from "api";
import { Button } from "react-bootstrap";
import { costCol, hiddenCol, simpleCol, useDeepCompareCallback } from "utils";
import * as FileSaver from "file-saver";
import { BaseTable } from "pages/Recover/components/SolutionDetails/components/BaseTable";


export const SolutionCostsTable = (
  { data, loading, currencyFormatter, base_scenario_uid, solution_uid, elementName = "SolutionComponent", scope = "full" }
) => {

  const onExportCostsXLSX = useDeepCompareCallback(async () => {
    const request = new ScenarioRequest();
    const response = await request.get_xlsx_costs(
      solution_uid,
      base_scenario_uid,
      { scope }
    );
    const blob = await response.blob();
    FileSaver.saveAs(
      blob,
      `costs_${solution_uid}__${base_scenario_uid}.xlsx`
    );
  }, [solution_uid, base_scenario_uid]);

  const title = base_scenario_uid ? <>Costs <Button onClick={onExportCostsXLSX}>Export</Button></> : "Costs";

  return (
    <BaseTable
      title={title}
      data={data}
      loading={loading}
      columns={[
        simpleCol(elementName),
        hiddenCol("Details"),
        costCol("Cost", "Cost", "Cost", 150, currencyFormatter),
      ]}
      SubComponent={({ row }) => {
        return (
          <BaseTable
            style={{ margin: 20 }}
            data={row.Details}
            loading={loading}
            columns={[
              hiddenCol("Details"),
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              costCol("Cost", "Cost", "Cost", 150, currencyFormatter),
            ]}
            SubComponent={({ row }) => {
              return (
                <BaseTable
                  style={{ margin: 20 }}
                  data={row.Details}
                  loading={loading}
                  columns={[
                    simpleCol("Type", 150),
                    simpleCol("Pax", 100),
                    costCol("Cost", "Cost", "Cost", 150, currencyFormatter),
                    simpleCol("Notes"),
                  ]}
                />
              );
            }}
          />
        );
      }}
    />
  );
};
