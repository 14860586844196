import { Loader } from "components/common/Loader";
import { ScenarioViewer } from "components/common/ScenarioViewer";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import Media from "react-media";
import { DateTime } from "luxon";
import { DisruptionsManager } from "./components/DisruptionsManager";
import { TimeSelector } from "./components/TimeSelector";
import { ErrorNav } from "components/common/ErrorNav";
import { ScenarioRequest, RecoveryQueryRequest } from "api";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  deepMemo,
  humanDuration,
  useDeepCompareCallback,
  useDeepCompareEffect,
} from "utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchBuilder } from "slices";
import { ScenarioDifficulty } from "./components/ScenarioDifficulty";
import { RecoverNav } from "./components/RecoverNav";
import configSlice from "slices/configSlice";

let create_scenario_request = new ScenarioRequest();

export const Builder = ({ match: { params } }) => (
  <BuilderComponent base_scenario_uid={params.base_scenario_uid} />
);

export const BuilderComponent = deepMemo(({ base_scenario_uid }) => {
  const [loading, setLoading] = useState(false);
  const [preparing_rq, setPreparingRQ] = useState(false);
  const [is_tt_active, setStateTT] = useState(false);
  const [creating_scenario, setCreatingScenario] = useState(false);
  const [reference_time, setCurrentTime] = useState(DateTime.utc());
  const [timeout_error, setTimeoutError] = useState({});
  const [user_changes, setUserChanges] = useState({
    aogs: [],
    curfews: [],
    delays: [],
    no_claim_flights: null,
    no_shows: [],
    vip_flights: null,
  });
  const [invalid_aogs, setInvalidAOGs] = useState([]);
  const show_solve_acmis = useSelector(configSlice.selectors.getSolveAcmis, shallowEqual);

  let history = useHistory();
  let dispatch = useDispatch();

  const baseCreateScenario = useDeepCompareCallback(
    async (modifiers = {}) => {
      create_scenario_request = new ScenarioRequest();
      try {
        let final_reference_time;
        if (DateTime.isDateTime(modifiers?.reference_time)) {
          final_reference_time = modifiers.reference_time.toISO({
            includeOffset: false,
          });
        } else if (DateTime.isDateTime(reference_time)) {
          final_reference_time = reference_time.toISO({ includeOffset: false });
        }

        return await create_scenario_request.create_scenario({
          ...user_changes,
          ...modifiers,
          reference_time: final_reference_time,
        });
      } catch (err) {
        if (err?.result?.invalid_aogs) {
          setInvalidAOGs(err?.result?.invalid_aogs || []);
        } else {
          throw err;
        }
      }
    },
    [reference_time, user_changes]
  );

  // Function to create a new scenario
  const createScenario = useDeepCompareCallback(
    async (modifiers = {}) => {
      setCreatingScenario(true);
      try {
        return await baseCreateScenario(modifiers);
      } catch (err) {
        throw err;
      } finally {
        setCreatingScenario(false);
      }
    },
    [reference_time, user_changes]
  );

  // Function to create and pull a new scenario
  const createScenarioAndPush = useDeepCompareCallback(
    async (modifiers = {}) => {
      setCreatingScenario(true);
      try {
        const response = await baseCreateScenario(modifiers);
        history.push(`/builder/${response.uid}`);
      } catch (err) {
        throw err;
      } finally {
        setCreatingScenario(false);
      }
    },
    [reference_time, user_changes]
  );

  // Fetch escentials only on first load
  useDeepCompareEffect(() => {
    dispatch(fetchBuilder());
  }, []);

  useDeepCompareEffect(() => {
    const runAsync = async () => {
      create_scenario_request.abort();
      try {
        await createScenarioAndPush({
          reference_time,
        });
      } catch (err) {
        console.warn(
          `Scenario with reference time ${reference_time} was aborted.`
        );
      }
    };
    if (!base_scenario_uid) runAsync();
  }, [history, base_scenario_uid, createScenario]);

  // Pull scenario metadata on update
  useDeepCompareEffect(() => {
    const request = new ScenarioRequest();
    const getScenario = async () => {
      try {
        const response = await request.get(base_scenario_uid);
        setCurrentTime(
          DateTime.fromISO(response.reference_time, { zone: "utc" })
        );
        setUserChanges(response.user_changes);
        if (response.is_out_of_sync) {
          if (response.has_aims_backup) {
            setTimeoutError({
              type: "warning",
              message: `Operations below were last synced with AIMS ${humanDuration(
                response.reference_time_offset_seconds * -1
              )}. ACARS, MVT and SLOT messages may have been applied for a more accurate picture`,
            });
          } else {
            setTimeoutError({
              message: `Operations below were last synced with AIMS ${humanDuration(
                response.reference_time_offset_seconds * -1
              )}`,
            });
          }
        }
      } catch (err) {
        console.warn(`base_scenario_uid: ${err.message}`);
        history.push(`/builder/`);
      }
    };
    if (base_scenario_uid) {
      getScenario();
    }
    return () => {
      request.abort();
      create_scenario_request.abort();
    };
  }, [base_scenario_uid]);

  // Loading scroller control
  useLayoutEffect(() => {
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = "visible";
    if (loading || creating_scenario) document.body.style.overflow = "hidden";
  }, [loading, creating_scenario]);

  // Scenario functions
  const onChangeTime = async (dt) => {
    setCurrentTime(dt);
    create_scenario_request.abort();
    try {
      await createScenarioAndPush({ reference_time: dt });
    } catch (err) {
      if (err.name === "AbortError") setCreatingScenario(true);
      console.warn(
        `Problem or aborted request on time change ${dt?.toISO({
          includeOffset: false,
        })} - ${err.message}`
      );
    }
  };

  const onRecoverWithoutUpdate = useCallback(
    async (params = {}) => {
      if (timeout_error?.type === "warning") {
        toast.warning("On Backup mode Recover is not available.");
        return;
      }
      setLoading(true);
      setPreparingRQ(true);
      params = {
        base_scenario_uid,
        ...params,
        name: `Query: ${reference_time?.toFormat("yyyy/MM/dd HH:mm")}`,
      };
      try {
        const request = new RecoveryQueryRequest();
        const response = await request.create(params);
        history.push(`/recover/${response.uid}`);
      } catch (err) {
        setPreparingRQ(false);
        setLoading(false);
        toast.error(`RecoveryQuery failed: ${err.message}`);
      }
    },
    [history, base_scenario_uid, reference_time, timeout_error?.type]
  );

  const onRecoverWithUpdate = useCallback(async (params = {}) => {
    if (timeout_error?.type === "warning") {
      toast.warning("On Backup mode Recover is not available.");
      return;
    }
    try {
      create_scenario_request.abort();
      const response = await createScenario({
        reference_time: DateTime.utc(),
      });
      onRecoverWithoutUpdate({ base_scenario_uid: response?.uid, ...params });
    } catch (err) {
      console.warn(`Problem or aborted recover ${err.message}`);
    }
  }, [createScenario, timeout_error?.type, onRecoverWithoutUpdate]);

  const onUpdateDisruptions = async (updates) => {
    setUserChanges({ ...user_changes, ...updates });
    try {
      create_scenario_request.abort();
      await createScenarioAndPush({
        reference_time,
        ...user_changes,
        ...updates,
        base_scenario_uid,
      });
    } catch (err) {
      console.warn(`Problem or aborted disruption ${err.message}`);
    }
  };

  return (
    <Media
      queries={{
        mobile: "(max-width: 599px)",
        desktop: "(min-width: 600px)",
      }}
    >
      {(matches) => (
        <>
          {matches.mobile && (
            <>
              <Loader
                show={loading || creating_scenario}
                message="Preparing data"
              />
              <ScenarioDifficulty scenario_uid={base_scenario_uid} />
              <ScenarioViewer clearMargin scenario_uid={base_scenario_uid} />
            </>
          )}
          {matches.desktop && (
            <>
              <TimeSelector
                reference_time={reference_time}
                onSelect={(dt) => {
                  setStateTT(true);
                  onChangeTime(dt);
                }}
                onRefresh={(dt) => {
                  setStateTT(false);
                  onChangeTime(dt);
                }}
              />
              <ErrorNav error={timeout_error.message} bg={timeout_error.type} />
              <Loader show={preparing_rq} message="Loading new query" />
              <DisruptionsManager
                loading={creating_scenario}
                reference_time={reference_time}
                base_scenario_uid={base_scenario_uid}
                user_changes={user_changes}
                invalid_aogs={invalid_aogs}
                setDisruptions={(updates) =>
                  setUserChanges({ ...user_changes, ...updates })
                }
                onUpdate={onUpdateDisruptions}
              />
              <div
                style={{
                  position: "relative",
                  height: "calc(100vh - 118px)",
                }}
              >
                <Loader
                  show={loading || creating_scenario}
                  message="Preparing data"
                />
                <ScenarioDifficulty scenario_uid={base_scenario_uid} />
                <ScenarioViewer
                  clearMargin
                  scenario_uid={base_scenario_uid}
                  vip_flights={user_changes?.vip_flights || []}
                  no_claim_flights={user_changes?.no_claim_flights || []}
                  onUpdate={onUpdateDisruptions}
                >
                  <RecoverNav
                    onRecoverWithUpdate={onRecoverWithUpdate}
                    onRecoverWithoutUpdate={onRecoverWithoutUpdate}
                    show_solve_acmis={show_solve_acmis}
                    is_tt_active={is_tt_active}
                  />
                </ScenarioViewer>
              </div>
            </>
          )}
        </>
      )}
    </Media>
  );
});
