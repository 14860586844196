import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { AircraftTargets } from "api";

export const slice = createSlice({
  name: 'aircraft_target',
  initialState: {
    current: [],
    updated: {}
  },
  reducers: {
    replace: (state, { payload }) => {
      state.current = payload;
    },
    changeUpdate: (state, { payload: { id, change } }) => ({
      ...state,
      updated: { ...state.updated, [id]: { ...state.updated[id], ...change } }
    }),
    removeUpdate: (state, { payload }) => {
      delete state.updated[payload];
    }
  }
});

export const getAircraftTargets = () => (dispatch) =>
  AircraftTargets.list()
    .then(({ aircraft_targets }) =>
      dispatch(slice.actions.replace(aircraft_targets))
    )
    .catch((error) => toast.error(`Aircraft Targets error: ${error.message}`));

export const addAircraftTarget = (aircraft_target) => (dispatch) =>
  AircraftTargets.create(aircraft_target)
    .then(() => {
      dispatch(getAircraftTargets());
      toast.success("Successfuly added aircraft target.");
    })
    .catch((error) => toast.error(`Aircraft Targets error: ${error.message}`));

export const updateAircraftTarget = (id, aircraft_target) => (dispatch) =>
  AircraftTargets.update(id, aircraft_target)
    .then(() => {
      dispatch(getAircraftTargets());
      dispatch(slice.actions.removeUpdate(id));
      toast.success("Successfuly updated aircraft target.");
    })
    .catch((error) => toast.error(`Aircraft Targets error: ${error.message}`));

export const deleteAircraftTarget = (id) => (dispatch) =>
  AircraftTargets.delete(id)
    .then(() => {
      dispatch(getAircraftTargets());
      toast.success("Successfuly removed aircraft target.");
    })
    .catch((error) => toast.error(`Aircraft Targets error: ${error.message}`));
