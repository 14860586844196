import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const countriesSlice = createSlice({
  name: "countries",
  initialState: [],
  reducers: {
    fetchCountriesSucceeded: (
      state,
      { payload: { countries, countries_labels } }
    ) => {
      return countries.map((country, index) => ({
        value: country,
        label: countries_labels[index],
      }));
    },
  },
});

countriesSlice.selectors = {
  getCountries: (state) => state.countries,
};

export default countriesSlice;

export const getCountries = createSelector(
  (state) => state.countries,
  (state) => state
);

export const fetchCountries = () => (dispatch) => {
  return api
    .getCountries()
    .then((result) =>
      dispatch(countriesSlice.actions.fetchCountriesSucceeded(result))
    )
    .catch((error) => toast.error(`Countries fetch failed ${error.message}`));
};
