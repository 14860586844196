import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Auth } from "api";
import aircraftTypesSlice from "slices/aircraftTypesSlice";
import aircraftsSlice from "slices/aircraftsSlice";
import configSlice from "slices/configSlice";
import countriesSlice from "slices/countriesSlice";
import crewsSlice from "slices/crewsSlice";
import disruptionsSlice from "slices/disruptionsSlice";
import flightsSlice from "slices/flightsSlice";
import legendSlice from "slices/legendSlice";
import queuesSlice from "slices/queues/queuesSlice";
import rolesSlice from "slices/rolesSlice";
import stationsSlice from "slices/stationsSlice";
import { targetReducer } from "slices/targets";
import temporalRulesSlice from "slices/temporalRulesSlice";
import timeTravelSlice from "slices/timeTravelSlice";
import userSlice from "slices/userSlice";
import usersSlice from "slices/usersSlice";
import { solutionsSlice } from "slices/solutions/solutionsSlice";
import { scenariosSlice } from "slices/scenarios/scenariosSlice";

export const history = createBrowserHistory();

const appReducer = combineReducers({
  user: userSlice.reducer,
  users: usersSlice.reducer,
  roles: rolesSlice.reducer,
  aircraftTypes: aircraftTypesSlice.reducer,
  aircrafts: aircraftsSlice.reducer,
  flights: flightsSlice.reducer,
  legend: legendSlice.reducer,
  stations: stationsSlice.reducer,
  crews: crewsSlice.reducer,
  countries: countriesSlice.reducer,
  config: configSlice.reducer,
  queues: queuesSlice.reducer,
  temporalRules: temporalRulesSlice.reducer,
  timeTravel: timeTravelSlice.reducer,
  disruptions: disruptionsSlice.reducer,
  targets: targetReducer,
  solutions: solutionsSlice.reducer,
  scenarios: scenariosSlice.reducer,
  router: connectRouter(history),
});

const store = configureStore({
  reducer: (state, action) => {
    if (action.type === "USER_LOGOUT") {
      Auth.signout();
      state = undefined;
    }

    return appReducer(state, action);
  },
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    routerMiddleware(history),
  ],
});

export default store;
