import React, { useEffect, useState } from "react";
import { Badge, Nav, Navbar, Tab } from "react-bootstrap";

import { LegendViewer } from "./components/LegendViewer";
import { CurfewsViewer } from "./components/CurfewsViewer";
import { StandByReport } from "./components/StandByReport";
import { StandByTable } from "./components/StandByTable";
import { Warnings, UncrewDuties } from "./components/Warnings";
import { OTPReport } from "./components/OTPReport";
import { OVBReport } from "./components/OVBReport";
import { MCNXReport } from "./components/MCNXReport";
import Media from 'react-media';

import { PlanSortingControl } from "./components/PlanSortingControl";
import { PlanFilterControl } from "./components/PlanFilterControl";
import { PlanEdgesControl } from "./components/PlanEdgesControl";

import styles from "./index.module.css";
import { ZoomSelector } from "components/common/viewers/SvgViewer";
import Octicon from "react-octicon";
import { deepMemo } from "utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  pullScenarioData,
  selectScenarioStandbys,
  selectScenarioWarningsD0,
  selectScenarioWarningsD1,
} from "slices/scenarios/scenariosSlice";
import { TextElipse } from "components/common/Loader";
import { ScenarioRequest } from "api";
import { SolutionCostsTable } from "components/common/SolutionCostsTable";
import { getOrgCurrencyFormatter } from "slices/userSlice";

const computeTotalWarnings = (ww) =>
  ww?.infeasible_crews?.length +
  ww?.infeasible_aircrafts?.length +
  ww?.infeasible_curfews?.length +
  ww?.aircraft_balance_violations?.length +
  ww?.infeasible_crews_next_day?.length || 0;

const computeTotalUncrewDuties = (ww) => ww?.incomplete_flights?.length || 0;

const formatOperationalCostTotal = (total) => {
  if (total === undefined || total === null) return '-'
  if (total >= 1000000) {
    return (total / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (total >= 1000) {
    return (total / 1000).toFixed(0) + 'k';
  } else {
    return total.toString();
  }
}

export const StatsNav = ({
  show_schedule,
  edge_option,
  sorting,
  filters,
  percentage,
  reference_time,
  updateSorting,
  updateFilters,
  updateEdgeOption,
  scenario_uid,
  base_scenario_uid,
  hide_filters,
  updatePercentage,
  show_diff_toggle,
  setDiffToggle,
  show_warnings_default,
  curfew_scenario_uid,
}) => {
  const [active_key, setActiveKey] = useState("");
  const [show_warnings, setShowWarnings] = useState(show_warnings_default);
  const [operational_cost, setOperationalCost] = useState(show_warnings_default);
  const dispatch = useDispatch();

  const warnings_d0 =
    useSelector(selectScenarioWarningsD0(scenario_uid), shallowEqual)?.data ||
    {};
  const warnings_d1 =
    useSelector(selectScenarioWarningsD1(scenario_uid), shallowEqual)?.data ||
    {};
  const { standbys_d0, standbys_d1, standbys_report_d0, standbys_report_d1 } =
    useSelector(selectScenarioStandbys(scenario_uid), shallowEqual)?.data || {};
  const currencyFormatter = useSelector(
    getOrgCurrencyFormatter,
    shallowEqual
  );

  const warnings_ready =
    Object.keys(warnings_d0).length !== 0 &&
    Object.keys(warnings_d1).length !== 0;

  const total_warnings_d0 = computeTotalWarnings(warnings_d0);
  const total_warnings_d1 = computeTotalWarnings(warnings_d1);
  const total_uncrew_duties_d0 = computeTotalUncrewDuties(warnings_d0);
  const total_uncrew_duties_d1 = computeTotalUncrewDuties(warnings_d1);

  const fetchOperationalCost = async () => {
    const request = new ScenarioRequest();
    const response = await request.get_operational_cost(scenario_uid);
    setOperationalCost(response.operational_cost);
  };

  const toggleWarnings = () => {
    if (!warnings_ready)
      dispatch(pullScenarioData(scenario_uid, base_scenario_uid));
    fetchOperationalCost(scenario_uid)
    setShowWarnings(true);
  };

  useEffect(() => {
    setShowWarnings(false);
  }, [scenario_uid]);

  return (
    <StatsContent
      active_key={active_key}
      standbys_d0={standbys_d0}
      standbys_d1={standbys_d1}
      standbys_report_d0={standbys_report_d0}
      standbys_report_d1={standbys_report_d1}
      warnings={warnings_d0}
      warnings_d1={warnings_d1}
      setActiveKey={setActiveKey}
      total_warnings={total_warnings_d0}
      total_warnings_d1={total_warnings_d1}
      total_uncrew_duties={total_uncrew_duties_d0}
      total_uncrew_duties_d1={total_uncrew_duties_d1}
      operational_cost_d0={operational_cost.d0}
      operational_cost_d1={operational_cost.d1}
      currencyFormatter={currencyFormatter}
      show_schedule={show_schedule}
      show_diff_toggle={show_diff_toggle}
      hide_filters={hide_filters}
      filters={filters}
      reference_time={reference_time}
      updateFilters={updateFilters}
      sorting={sorting}
      updateSorting={updateSorting}
      updateEdgeOption={updateEdgeOption}
      edge_option={edge_option}
      percentage={percentage}
      updatePercentage={updatePercentage}
      setDiffToggle={setDiffToggle}
      curfew_scenario_uid={curfew_scenario_uid}
      show_warnings={show_warnings}
      toggleWarnings={toggleWarnings}
      warnings_ready={warnings_ready}
    />
  );
};

const StatsContent = deepMemo(
  ({
    active_key,
    standbys_d0,
    standbys_d1,
    standbys_report_d0,
    standbys_report_d1,
    warnings,
    warnings_d1,
    setActiveKey,
    total_warnings,
    total_warnings_d1,
    total_uncrew_duties,
    total_uncrew_duties_d1,
    operational_cost_d0,
    operational_cost_d1,
    currencyFormatter,
    show_schedule,
    show_diff_toggle,
    hide_filters,
    filters,
    reference_time,
    updateFilters,
    sorting,
    updateSorting,
    updateEdgeOption,
    edge_option,
    percentage,
    updatePercentage,
    setDiffToggle,
    curfew_scenario_uid,
    show_warnings,
    toggleWarnings,
    warnings_ready,
  }) => {
    return (
      <>
        <Navbar collapseOnSelect expand="md" bg="dark-grey" variant="dark" className={styles.Navbar}>
          <Nav
            className="mr-auto"
            activeKey={active_key}
            onSelect={(tab) =>
              setActiveKey((prev) => (tab === prev ? "" : tab))
            }
          >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav.Item>
                <Nav.Link eventKey="legend">Legend</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="curfews">Curfews</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="standbys">SB D0</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="standbys_d1">SB D1</Nav.Link>
              </Nav.Item>
              {standbys_report_d0?.length > 0 && (
                <Nav.Item>
                  <Nav.Link eventKey="report_d0">Stations D0</Nav.Link>
                </Nav.Item>
              )}
              {standbys_report_d1?.length > 0 && (
                <Nav.Item>
                  <Nav.Link eventKey="report_d1">Stations D1</Nav.Link>
                </Nav.Item>
              )}
              {show_warnings && !warnings_ready && (
                <Nav.Item>
                  <Nav.Link>
                    <TextElipse>Loading warnings</TextElipse>
                  </Nav.Link>
                </Nav.Item>
              )}
              {show_warnings && warnings_ready && (
                <>
                  <Nav.Item>
                    <Nav.Link eventKey="warnings">
                      {"Warn. D0 "}
                      <Badge variant={total_warnings ? "danger" : "info"}>
                        {total_warnings}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="warnings_d1">
                      {"Warn. D1 "}
                      <Badge variant={total_warnings_d1 ? "danger" : "info"}>
                        {total_warnings_d1}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="uncrew_duties">
                      {"Uncrew Duties D0 "}
                      <Badge variant={total_uncrew_duties ? "danger" : "info"}>
                        {total_uncrew_duties}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="uncrew_duties_d1">
                      {"Uncrew Duties D1 "}
                      <Badge variant={total_uncrew_duties_d1 ? "danger" : "info"}>
                        {total_uncrew_duties_d1}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="otp_15">
                      {"OTP 15 "}
                      <Badge
                        variant={warnings?.otp15?.length ? "danger" : "info"}
                      >
                        {warnings?.otp15?.length}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="otp_180">
                      {"OTP 180 "}
                      <Badge
                        variant={warnings?.otp180?.length ? "danger" : "info"}
                      >
                        {warnings?.otp180?.length}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ovb">
                      {"OVB "}
                      <Badge
                        variant={warnings?.total_overbooking ? "danger" : "info"}
                      >
                        {warnings?.total_overbooking}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="mcxn">
                      {"MCXN "}
                      <Badge variant={warnings?.total_mcnx ? "danger" : "info"}>
                        {warnings?.total_mcnx}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="opd0">
                      {"OC D0 "}
                      <Badge variant={"info"}>
                        {formatOperationalCostTotal(operational_cost_d0?.TotalCost)}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="opd1">
                      {"OC D1 "}
                      <Badge variant={"info"}>
                        {formatOperationalCostTotal(operational_cost_d1?.TotalCost)}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              {!show_warnings && (
                <Nav.Item>
                  <Nav.Link onClick={toggleWarnings}>
                    {warnings_ready ? "Show warnings" : "Load warnings"}
                  </Nav.Link>
                </Nav.Item>
              )}
            </Navbar.Collapse>
          </Nav>

          <Media queries={{
            mobile: "(max-width: 599px)",
            desktop: "(min-width: 600px)"
          }}>
            {matches =>
              <>
                {matches.mobile && (<></>)}
                {matches.desktop && (
                  <Nav>
                    {show_diff_toggle && (
                      <Nav.Item>
                        <Nav.Link onClick={() => setDiffToggle((prev) => !prev)}>
                          <Octicon name="diff" />
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {show_schedule && (
                      <>
                        {!hide_filters && (
                          <>
                            <Nav.Item>
                              <PlanFilterControl
                                filters={filters}
                                reference_time={reference_time}
                                onUpdate={updateFilters}
                                as={Nav.Link}
                              />
                            </Nav.Item>
                            <Nav.Item>
                              <PlanSortingControl
                                sorting={sorting}
                                onUpdate={updateSorting}
                                as={Nav.Link}
                              />
                            </Nav.Item>
                          </>
                        )}
                        <Nav.Item>
                          <PlanEdgesControl
                            as={Nav.Link}
                            onApply={updateEdgeOption}
                            edge_option={edge_option}
                          />
                        </Nav.Item>
                      </>
                    )}
                    <Nav.Item style={{ width: "100px" }}>
                      <ZoomSelector value={percentage} onChange={updatePercentage} />
                    </Nav.Item>
                  </Nav>
                )}
              </>
            }
          </Media>


        </Navbar>
        <Tab.Content className={styles.Content}>
          <Tab.Pane active={active_key === "legend"} title="Legend">
            <LegendViewer />
          </Tab.Pane>
          <Tab.Pane active={active_key === "curfews"} title="Curfews">
            <CurfewsViewer scenario_uid={curfew_scenario_uid} />
          </Tab.Pane>
          <Tab.Pane active={active_key === "report_d0"} title="Stations D0">
            <StandByReport standbys_report={standbys_report_d0} />
          </Tab.Pane>
          <Tab.Pane active={active_key === "report_d1"} title="Stations D1">
            <StandByReport standbys_report={standbys_report_d1} />
          </Tab.Pane>
          <Tab.Pane active={active_key === "standbys"} title="Standbys D0">
            <StandByTable standbys={standbys_d0} scope="D0" />
          </Tab.Pane>
          <Tab.Pane active={active_key === "standbys_d1"} title="Standbys D1">
            <StandByTable standbys={standbys_d1} scope="D1" />
          </Tab.Pane>
          {show_warnings && (
            <>
              <Tab.Pane active={active_key === "warnings"} title="Warnings D0">
                <Warnings
                  infeasible_crews={warnings?.infeasible_crews}
                  infeasible_aircrafts={warnings?.infeasible_aircrafts}
                  infeasible_curfews={warnings?.infeasible_curfews}
                  aircraft_balance_violations={
                    warnings?.aircraft_balance_violations
                  }
                  infeasible_crews_next_day={
                    warnings?.infeasible_crews_next_day
                  }
                />
              </Tab.Pane>
              <Tab.Pane
                active={active_key === "warnings_d1"}
                title="Warnings D1"
              >
                <Warnings
                  infeasible_crews={warnings_d1?.infeasible_crews}
                  infeasible_aircrafts={warnings_d1?.infeasible_aircrafts}
                  infeasible_curfews={warnings_d1?.infeasible_curfews}
                  aircraft_balance_violations={
                    warnings_d1?.aircraft_balance_violations
                  }
                  infeasible_crews_next_day={
                    warnings_d1?.infeasible_crews_next_day
                  }
                />
              </Tab.Pane>
              <Tab.Pane
                active={active_key === "uncrew_duties"}
                title="Uncrew Duties D0"
              >
                <UncrewDuties uncrew_duties={warnings?.incomplete_flights} />
              </Tab.Pane>
              <Tab.Pane
                active={active_key === "uncrew_duties_d1"}
                title="Uncrew Duties D1"
              >
                <UncrewDuties uncrew_duties={warnings_d1?.incomplete_flights} />
              </Tab.Pane>

              <Tab.Pane active={active_key === "otp_15"} title="OTP 15">
                <OTPReport title="OTP 15" data={warnings?.otp15} />
              </Tab.Pane>
              <Tab.Pane active={active_key === "otp_180"} title="OTP 180">
                <OTPReport title="OTP 180" data={warnings?.otp180} />
              </Tab.Pane>
              <Tab.Pane active={active_key === "ovb"} title="OVB">
                <OVBReport ovb_flights={warnings?.ovb_flights} />
              </Tab.Pane>
              <Tab.Pane active={active_key === "mcxn"} title="MCXN">
                <MCNXReport mcnx_itineraries={warnings?.mcnx_itineraries} />
              </Tab.Pane>
              <Tab.Pane active={active_key === "opd0"} title="OP D0">
                <SolutionCostsTable data={operational_cost_d0?.Costs} scope="d0" currencyFormatter={currencyFormatter} elementName="AircraftID" />
              </Tab.Pane>
              <Tab.Pane active={active_key === "opd1"} title="OP D1">
                <SolutionCostsTable data={operational_cost_d1?.Costs} scope="d1" currencyFormatter={currencyFormatter} elementName="AircraftID" />
              </Tab.Pane>
            </>
          )}
        </Tab.Content>
      </>
    );
  }
);
