import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";
import Select from "react-select";
import find from "lodash/find";

import { randomId } from "utils";

import styles from "./index.module.css";

let cx = classNames.bind(styles);

const percentage_options = [
  { label: "x1", value: "100%" },
  { label: "x1.25", value: "125%" },
  { label: "x1.5", value: "150%" },
  { label: "x1.75", value: "175%" },
  { label: "x2", value: "200%" },
];

const percentageValueToOption = (value) =>
  find(percentage_options, (option) => option.value === value);

export const ZoomSelector = ({ value, onChange }) => {
  return (
    <Select
      value={percentageValueToOption(value) || percentage_options[0]}
      onChange={(option) => onChange(option.value)}
      options={percentage_options}
    />
  );
};

export const SvgViewer = ({
  style,
  svg = "",
  hide = false,
  _figure_id = `figure_${randomId()}`,
  _ref = React.createRef(),
  default_percentage = "100%",
  show_zoom_selector = false,
}) => {
  const [percentage, setPercentage] = useState(default_percentage);

  useEffect(() => {
    if (default_percentage) {
      setPercentage(default_percentage);
    }
  }, [default_percentage]);

  useEffect(() => {
    // Remove uneeded titles
    window.jQuery(`.${_figure_id} svg`).css("width", percentage);
    window.jQuery(`.${_figure_id} g > title`).remove();
    // Move zlink:title to data-title tag
    window.jQuery(`.${_figure_id} g > a`).each(function () {
      var $t = window.jQuery(this);
      $t.attr({
        "data-title": $t.attr("xlink:title"),
      }).removeAttr("xlink:title");
    });
    // Force all tooltips desapear on close
    window.jQuery(`.${_figure_id}`).on("tooltipclose", function () {
      window.jQuery(".ui-tooltip").remove();
    });
    // Wrap tooltips
    window.jQuery(`.${_figure_id}`).tooltip({
      items: "[data-title]",
      track: true,
      content: function () {
        return window.jQuery(this).attr("data-title").replace(/\n/g, "<br>");
      },
    });
  });

  return (
    <div
      className={cx({
        [styles.Hide]: hide,
      })}
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {show_zoom_selector && (
        <div style={{ width: "100px", position: "absolute", top: 0, right: 0 }}>
          <ZoomSelector value={percentage} onChange={setPercentage} />
        </div>
      )}
      <ScrollContainer style={{ width: "100%", cursor: "all-scroll" }}>
        <div
          style={style}
          className={cx(_figure_id, styles.Wrapper, {
            [styles.Hide]: hide,
          })}
          ref={_ref}
          dangerouslySetInnerHTML={{
            __html: svg,
          }}
        />
      </ScrollContainer>
    </div>
  );
};
