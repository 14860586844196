import React, { useState } from "react";
import Datetime from "react-datetime";
import { Col, InputGroup } from "react-bootstrap";
import { DateTime } from "luxon";
import { deepMemo, useDeepCompareEffect } from "utils";

const roundDateTime = (dt) => dt.plus({ minute: 5 - (dt.minute % 5) });

export const DateTimeRange = deepMemo(
  ({ default_start, default_end, reference_time, onUpdate }) => {
    const [start, setStart] = useState(
      roundDateTime(default_start || reference_time)
    );
    const [end, setEnd] = useState(
      roundDateTime(default_end || reference_time)
    );

    useDeepCompareEffect(() => {
      onUpdate(start, end);
    }, [onUpdate, start, end]);

    const updateStart = (dt) => {
      let new_start = DateTime.fromISO(dt.toISOString(), { zone: "utc" });
      if (start.hour === 23 && new_start.hour === 0)
        new_start = new_start.plus({ days: 1 });
      if (start.hour === 0 && new_start.hour === 23)
        new_start = new_start.plus({ days: 1 });
      if (start.minute >= 55 && new_start.minute <= 5)
        new_start = new_start.plus({ hours: 1 });
      if (start.minute <= 5 && new_start.minute >= 55)
        new_start = new_start.plus({ hours: 1 });
      if (new_start > end) setEnd(new_start);
      setStart(new_start);
    };

    const updateEnd = (dt) => {
      let new_end = DateTime.fromISO(dt.toISOString(), { zone: "utc" });
      if (end.hour === 23 && new_end.hour === 0)
        new_end = new_end.plus({ days: 1 });
      if (end.hour === 0 && new_end.hour === 23)
        new_end = new_end.plus({ days: 1 });
      if (end.minute >= 55 && new_end.minute <= 5)
        new_end = new_end.plus({ hours: 1 });
      if (end.minute <= 5 && new_end.minute >= 55)
        new_end = new_end.plus({ hours: 1 });
      if (new_end < start) setStart(new_end);
      setEnd(new_end);
    };

    return (
      <>
        <Col>
          <Datetime
            value={start.toJSDate()}
            initialViewMode="time"
            onChange={updateStart}
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm"
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff" },
            }}
            utc
            timeConstraints={{ minutes: { step: 5 } }}
            isValidDate={(current) =>
              DateTime.fromISO(current.toISOString()) >
              reference_time.minus({ days: 1 })
            }
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Start</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
        <Col>
          <Datetime
            value={end.toJSDate()}
            initialViewMode="time"
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff" },
            }}
            onChange={updateEnd}
            isValidDate={(current) =>
              DateTime.fromISO(current.toISOString()) > start.minus({ days: 1 })
            }
            utc
            timeConstraints={{ minutes: { step: 5 } }}
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm"
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">End</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
      </>
    );
  }
);
