import React, { Component } from "react";
import autoBind from "auto-bind";
import ScrollContainer from "react-indiana-drag-scroll";

import { randomId } from "utils";
import { SvgViewer } from "../SvgViewer";

export class RosterSVGViewer extends Component {
  static defaultProps = {
    svg: "",
    hide: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this._figure_id = `roster_plan_${randomId()}`;
    autoBind(this);
  }

  render() {
    return (
      <ScrollContainer style={{ width: "100%" }}>
        <SvgViewer
          _ref={this.myRef}
          hide={this.props.hide}
          _figure_id={this._figure_id}
          svg={this.props.svg}
          default_percentage={this.props.percentage}
        />
      </ScrollContainer>
    );
  }
}
