import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { SortIcon } from "components/common/Icons";

const criteria_enum = {
  FLEET: "FLEET",
  FINAL_STATION: "FINAL_STATION",
  CURRENT_STATION: "CURRENT_STATION",
};

const criteria_options = [
  { id: criteria_enum.FLEET, label: "Fleet" },
  { id: criteria_enum.FINAL_STATION, label: "Final station" },
  { id: criteria_enum.CURRENT_STATION, label: "Current station" },
];

export const PlanSortingControl = ({ as = Button, sorting, onUpdate }) => {
  const Component = as;
  const [show, setShow] = useState(false);
  const [criteria, setCriteria] = useState(
    sorting.criteria || criteria_enum.FLEET
  );
  const [flights_and_bckp_on_top, setToggle] = useState(
    !!sorting.flights_and_bckp_on_top
  );

  useEffect(() => {
    setCriteria(sorting.criteria || "FLEET");
    setToggle(!!sorting.flights_and_bckp_on_top);
  }, [sorting.criteria, sorting.flights_and_bckp_on_top]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleApply = () => {
    onUpdate({
      criteria: criteria,
      flights_and_bckp_on_top: flights_and_bckp_on_top,
    });
    handleClose();
  };

  return (
    <>
      <Component variant="primary" onClick={handleShow}>
        <SortIcon />
      </Component>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sorting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <fieldset>
              <Form.Group controlId="formGridEmail">
                <Form.Label>Choose the sorting criteria</Form.Label>

                {criteria_options.map((sc) => (
                  <Form.Check
                    type="radio"
                    checked={criteria === sc.id}
                    name="sorting_criteria"
                    key={sc.id}
                    id={sc.id}
                    onChange={(e) => e.target.checked && setCriteria(sc.id)}
                    label={sc.label}
                  />
                ))}
              </Form.Group>
            </fieldset>
            <Form.Group controlId="formHorizontalCheck">
              <Form.Check
                label="Move aircrafts with flights to the top"
                type="checkbox"
                checked={flights_and_bckp_on_top}
                onChange={() => setToggle((prev) => !prev)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
