import {
  authorizedRequest,
  authorizedRequestCancel,
  httpMethods,
} from "../utils";

export const RecoveryQuery = {
  get: (uid) => authorizedRequest(`/recovery_query/${uid}`, httpMethods.GET),
  get_: (uid) =>
    authorizedRequestCancel(`/recovery_query/${uid}`, httpMethods.GET),
  create: (args) =>
    authorizedRequest("/recovery_query", httpMethods.POST, args),
  create_: (args) =>
    authorizedRequestCancel("/recovery_query", httpMethods.POST, args),
  update_comment: (uid, comment) =>
    authorizedRequest(`/recovery_query/${uid}/comment`, httpMethods.POST, {
      comment,
    }),
  get_comment: (uid) =>
    authorizedRequest(`/recovery_query/${uid}/comment`, httpMethods.GET),
  get_comment_: (uid) =>
    authorizedRequestCancel(`/recovery_query/${uid}/comment`, httpMethods.GET),
  solve: (uid) =>
    authorizedRequest(`/recovery_query/${uid}/recover`, httpMethods.POST),
  mark_solution: (uid, solution_uid) =>
    authorizedRequest(
      `/recovery_query/${uid}/solution/${solution_uid}`,
      httpMethods.POST
    ),
  next_day: (r_uid, s_uid) =>
    authorizedRequest(`/recovery_query/${r_uid}/d+1/${s_uid}`, httpMethods.GET),
};
