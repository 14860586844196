import { fetchUser } from "./userSlice";
import { fetchConfig } from "./configSlice";
import { fetchFlights } from "./flightsSlice";
import { fetchCountries } from "./countriesSlice";
import { fetchAircraftTypes } from "./aircraftTypesSlice";
import { fetchStations } from "./stationsSlice";
import { fetchTemporalRules } from "./temporalRulesSlice";
import { fetchRoles } from "./rolesSlice";
import { fetchAircrafts } from "./aircraftsSlice";
import { fetchCrews } from "./crewsSlice";
import { fetchLegend } from "./legendSlice";

export const fetchBasics = () => (dispatch) => {
  dispatch(fetchUser());
  dispatch(fetchRoles());
  dispatch(fetchLegend());
};

export const fetchBuilder = () => (dispatch) => {
  dispatch(fetchFlights());
  dispatch(fetchConfig());
  dispatch(fetchCountries());
  dispatch(fetchAircraftTypes());
  dispatch(fetchAircrafts());
  dispatch(fetchCrews());
  dispatch(fetchStations());
  dispatch(fetchTemporalRules());
};

export const fetchRecover = () => (dispatch) => {
  dispatch(fetchFlights());
  dispatch(fetchCountries());
  dispatch(fetchAircraftTypes());
  dispatch(fetchAircrafts());
  dispatch(fetchStations());
};

export const fetchFilters = () => (dispatch) => {
  dispatch(fetchFlights());
  dispatch(fetchCountries());
  dispatch(fetchAircraftTypes());
  dispatch(fetchAircrafts());
  dispatch(fetchStations());
};
