import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";

import * as api from "api";

const timeTravelSlice = createSlice({
  name: "timeTravel",
  initialState: {
    plans: {},
    isFetching: false,
  },
  reducers: {
    fetchtimeTravelStart: (state) => ({ ...state, isFetching: true }),
    fetchtimeTravelSucceeded: (state, action) => ({
      ...state,
      plans: action.payload,
      isFetching: false,
    }),
    fetchtimeTravelFail: (state) => ({ ...state, isFetching: false }),
    cleanTimeTravel: (state) => ({ ...state, plans: {} }),
  },
});

timeTravelSlice.selectors = {
  getTimeTravel: (state) => state.timeTravel,
};

export default timeTravelSlice;

export const fetchtimeTravel = (reference_date) => (dispatch) => {
  dispatch(timeTravelSlice.actions.fetchtimeTravelStart());
  return api
    .getTimeline(moment(reference_date).format("YYYY-MM-DD HH:mm:SS"))
    .then(({ timeline }) => {
      const flat_plans = {};
      for (let index in timeline) {
        let plan = timeline[index];
        flat_plans[plan.slice(0, 16).replace("T", " ")] = plan;
      }
      dispatch(timeTravelSlice.actions.fetchtimeTravelSucceeded(flat_plans));
    })
    .catch((error) => {
      toast.error(`Error fetching plans: ${error.message}`);
      dispatch(timeTravelSlice.actions.fetchtimeTravelFail());
    });
};
