import head from "lodash/head";
import sortBy from "lodash/sortBy";
import { sum } from "lodash";

import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import * as utils from "utils";

import { RecoveryPulling } from "./fetch";
import {
  isSolutionRedundant,
  label_criterias,
  createDeepEqualSelector,
} from "./utils";
import { orderBy } from "lodash";
import { DateTime } from "luxon";

const solutionsAdapter = createEntityAdapter({
  selectId: (solution) => solution.plan_uid,
});

const getInitialState = (loading = false) =>
  solutionsAdapter.getInitialState({
    loading,
    completed: false,
    error: undefined,
    selected_solution_uid: undefined,
    marked_solution_uid: undefined,
    is_next_day: false,
    parent_uid: undefined,
    base_scenario_uid: undefined,
    scenario_uid: undefined,
    parent_base_scenario_uid: undefined,
  });

export const solutionsSlice = createSlice({
  name: "solutions",
  initialState: getInitialState(),
  reducers: {
    fetchRQStart: (state) => getInitialState(true),
    fetchRQEnd: (state) => {
      state.loading = false;
      state.completed = true;
    },
    fetchRQFail: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.completed = true;
    },
    fetchRQAddSolutions: (state, { payload }) => {
      const raw_solutions = payload.solutions.map((solution) => ({ ...solution.d0, ...solution }));
      const ordered_raw_solutions = orderBy(raw_solutions, ["timestamp", "plan_uid"]);
      const solutions = ordered_raw_solutions.map((solution, idx) => ({
        ...solution,
        order_solution_time: DateTime.fromISO(solution.timestamp).diff(DateTime.fromISO(payload.timestamp), 'seconds').seconds,
        is_redundant: isSolutionRedundant(
          solution,
          ordered_raw_solutions.slice(0, idx)
        ),
      }));
      solutionsAdapter.setAll(state, solutions);
    },
    selectSolution: (state, { payload }) => {
      state.selected_solution_uid = payload;
    },
    markSolution: (state, { payload }) => {
      state.marked_solution_uid = payload;
    },

    deleteSolution: (state, { payload }) => {
      solutionsAdapter.removeOne(state, payload);
    },

    setRQInfo: (state, { payload }) => {
      state.is_next_day = payload.is_next_day;
      state.parent_uid = payload.parent_uid;
      state.base_scenario_uid = payload.base_scenario_uid;
      state.scenario_uid = payload.scenario_uid;
      state.parent_base_scenario_uid = payload.parent_base_scenario_uid;
    },
  },
});

const getVirtualCost = (sol, d1 = {}) => {
  if (d1 === null) { d1 = {} }
  const totalCost = sol.TotalCost + (d1.TotalCost || 0);
  const totalOTP15Violations = (sol.TotalOTP15Violations || 0) + (d1.TotalOTP15Violations || 0);
  const totalMispositionedCrewMembers = (sol.TotalMispositionedCrewMembers || 0) + (d1.TotalMispositionedCrewMembers || 0);

  const fleetBalance = sol.FleetBalance || [];
  const d1FleetBalance = d1.FleetBalance || [];

  const fleetBalanceSum = sum(fleetBalance.map(record => (record.Missing?.filter(value => value === "FLIGHT").length || 0)));
  const d1FleetBalanceSum = sum(d1FleetBalance.map(record => (record.Missing?.filter(value => value === "FLIGHT").length || 0)));

  return totalCost +
    1000 * totalOTP15Violations +
    500 * totalMispositionedCrewMembers +
    50000 * (fleetBalanceSum + d1FleetBalanceSum);
}

const canHaveLabels = (sol) =>
  !sol.is_redundant &&
  !sol.is_private &&
  !sol.is_trivial &&
  sol.can_be_recommended;

const addLabels = (solutions) => {
  const recommended_solution = head(
    sortBy(
      solutions.filter((sol) => canHaveLabels(sol)),
      (sol) => getVirtualCost(sol, sol.d1)
    )
  );
  solutions = solutions.map((sol) =>
    sol.plan_uid === recommended_solution?.plan_uid
      ? { ...sol, is_useful: true, labels: [...(sol.labels || []), "*"] }
      : sol
  );
  for (const label in label_criterias) {
    const best_solution = head(
      sortBy(
        solutions.filter(
          (sol) => canHaveLabels(sol)
        ).map((sol) => {
          const result = { ...sol };
          for (const criteria of label_criterias[label]) {
            if (result.d1 && result.d0) {
              result[criteria] = (result.d0[criteria] || 0) + (result.d1[criteria] || 0);
            } else {
              // Fallback case to support deprecated metrics formats
              result[criteria] = (result[criteria] || 0);
            }
          }
          return result
        }),
        label_criterias[label]
      )
    );
    if (best_solution) {
      solutions = solutions.map((sol) =>
        sol.plan_uid === best_solution.plan_uid
          ? { ...sol, is_useful: true, labels: [...(sol.labels || []), label] }
          : sol
      );
    }
  }
  return solutions;
};

const getSlice = (state) => state.solutions;
export const getRQError = createDeepEqualSelector(
  (state) => state.solutions.error,
  (state) => state
);
export const getRQLoading = createDeepEqualSelector(
  (state) => state.solutions.loading,
  (state) => state
);
export const getRQCompleted = createDeepEqualSelector(
  (state) => state.solutions.completed,
  (state) => state
);
export const getRQSelectedSolution = (state) =>
  solutionsAdapter
    .getSelectors()
    .selectById(getSlice(state), getSlice(state).selected_solution_uid);
export const getRQMarkedSolutionUid = createDeepEqualSelector(
  (state) => state.solutions.marked_solution_uid,
  (state) => state
);
export const getRQIsNextDay = createDeepEqualSelector(
  (state) => state.solutions.is_next_day,
  (state) => state
);
export const getRQParentUid = createDeepEqualSelector(
  (state) => state.solutions.parent_uid,
  (state) => state
);
export const getRQBaseScenarioUid = createDeepEqualSelector(
  (state) => state.solutions.base_scenario_uid,
  (state) => state
);
export const getRQParentBaseScenarioUid = createDeepEqualSelector(
  (state) => state.solutions.parent_base_scenario_uid,
  (state) => state
);
export const getRQScenarioUid = createDeepEqualSelector(
  (state) => state.solutions.scenario_uid,
  (state) => state
);
export const getFullSolutions = (state) =>
  addLabels(
    solutionsAdapter
      .getSelectors()
      .selectAll(getSlice(state))
      .filter((solution) => solution.has_crew_solution)
  );
export const getOnlyAcftSolutions = (state) =>
  addLabels(
    solutionsAdapter
      .getSelectors()
      .selectAll(getSlice(state))
      .filter((solution) => !solution.has_crew_solution)
  );

export const getAllSolutions = (state) =>
  addLabels(solutionsAdapter.getSelectors().selectAll(getSlice(state)));

export const getSolutionsOptions = (isAdmin) => (state) =>
  addLabels(
    solutionsAdapter
      .getSelectors()
      .selectAll(getSlice(state))
      .filter(
        (solution) =>
          isAdmin || (!solution.is_redundant && !solution.is_private)
      )
  ).map((solution) => ({
    label: solution.internal_id,
    value: solution.plan_uid,
  }));

const TIMEOUT_SECONDS = 15 * 60; // 15 minutes in seconds

export const fetchRecoveryQuerySolutions = (query_uid) => (dispatch) => {
  const puller = new RecoveryPulling();
  dispatch(solutionsSlice.actions.fetchRQStart());
  puller
    .subscribe(query_uid, (rq) => {
      dispatch(solutionsSlice.actions.fetchRQAddSolutions({ solutions: rq.solutions, timestamp: rq.date }));
      dispatch(solutionsSlice.actions.markSolution(rq.solution_uid));
      dispatch(
        solutionsSlice.actions.setRQInfo({
          is_next_day: rq.is_next_day,
          parent_uid: rq.parent_uid,
          base_scenario_uid: rq.base_plan,
          scenario_uid: rq.plan,
          parent_base_scenario_uid: rq.parent_base_uid,
        })
      );
      const exec_start_datetime = DateTime.fromISO(rq.exec_start_time, { zone: "utc" });
      const exceed_timeout = utils.isTimeout(exec_start_datetime.toJSDate(), TIMEOUT_SECONDS);
      if (rq.is_solved || exceed_timeout) {
        if (rq.has_faild) {
          dispatch(solutionsSlice.actions.fetchRQFail(rq.error));
        } else {
          dispatch(solutionsSlice.actions.fetchRQEnd());
        }
        return false;
      }
      return true;
    })
    .catch((e) => {
      dispatch(solutionsSlice.actions.fetchRQFail(e.statusText));
      return false;
    });
};
