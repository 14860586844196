import React from "react";
import classNames from "classnames";
import { Nav, Navbar } from "react-bootstrap";
import Octicon from "react-octicon";
import { AirplaneIcon, CrewIcon } from "components/common/Icons";

import styles from "./index.module.css";
let cx = classNames.bind(styles);

export const MainNav = ({
  title,
  show_schedule,
  toggleView,
  show_full,
  toggle,
  toggleFull,
  children,
}) => (
  <Navbar
    bg="light-grey"
    variant="dark"
    className={cx(styles.Navbar, "justify-content-between")}
  >
    <Navbar.Brand className={styles.Brand}>{title}</Navbar.Brand>
    <Navbar.Toggle />
    {children}
    <Nav>
      <Nav.Item>
        <Nav.Link onClick={toggleView}>
          <AirplaneIcon active={show_schedule} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link onClick={toggleView}>
          <CrewIcon active={!show_schedule} />
        </Nav.Link>
      </Nav.Item>
      {toggle && (
        <Nav.Item>
          <Nav.Link onClick={toggleFull}>
            {show_full ? (
              <Octicon name="chevron-up" />
            ) : (
              <Octicon name="chevron-down" />
            )}
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  </Navbar>
);
