import React, { Component } from "react";
import autoBind from "auto-bind";
import ScrollContainer from "react-indiana-drag-scroll";

import { randomId } from "utils";
import { SvgViewer } from "../SvgViewer";

export const edge_options = {
  ALL_CREW: "CREW",
  CAB: "CAB",
  COCK: "COCK",
  MCXN_CREW: "MCXN CREW",
  ALL_PAX: "PAX",
  MCXN_PAX: "MCXN PAX",
  NONE: "NONE",
};

export class SchedulerSVGViewer extends Component {
  static defaultProps = {
    svg: "",
    hide: false,
    vip_flights: [],
    no_claim_flights: [],
    edge_option: edge_options.ALL_CREW,
    onFlightClick: () => {},
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this._figure_id = `aircraft_plan_${randomId()}`;
    this.edges_selector = `.${this._figure_id} g[data-class="edge"]`;
    autoBind(this);
  }

  componentDidMount() {
    this.setupFlightContextMenu(!this.hasFlightActions());
    this.toggleEdges();
  }


  setupFlightContextMenu(skip_actions = false) {
    const selector = `.${this._figure_id} g[id$="_FB"][data-class="node"]`;
    window.jQuery(selector).unbind();

    if (this.props.onFlightCrewClick) {
      window.jQuery.contextMenu({
        selector: selector,
        callback: (key, options) => {
          const flight_id = window
            .jQuery(options.$trigger)
            .attr("id")
            .slice(0, -3);

          const flight_day = window.jQuery(options.$trigger).data("day");
          if (key === "crew_duty")
            this.props.onFlightCrewClick(flight_id, flight_day);
        },
        items: {
          crew_duty: { name: "View duty" },
        },
      });
    }

    if (!skip_actions) {
      window.jQuery.contextMenu({
        selector: selector,
        callback: (key, options) => {
          const flight_id = window
            .jQuery(options.$trigger)
            .attr("id")
            .slice(0, -3);
          const is_vip = this.props.vip_flights.indexOf(flight_id) > -1;
          const is_no_claim =
            this.props.no_claim_flights.indexOf(flight_id) > -1;
          if (key === "vip" && is_vip) this.props.removeVip(flight_id);
          if (key === "vip" && !is_vip) this.props.addVip(flight_id);
          if (key === "no_claim" && is_no_claim)
            this.props.removeNoClaim(flight_id);
          if (key === "no_claim" && !is_no_claim)
            this.props.addNoClaim(flight_id);
        },
        items: {
          vip: { name: "VIP" },
          no_claim: { name: "No claim" },
        },
      });
    }
    window.jQuery(selector).bind("contextmenu", function (e) {
      window.jQuery(".ui-tooltip").remove();
    });
    window.jQuery(selector).click((e) => {
      this.props.onFlightClick(window.jQuery(e.target).attr("id").slice(0, -3));
    });
  }

  hasFlightActions() {
    return (
      this.props.addNoClaim &&
      this.props.removeNoClaim &&
      this.props.addVip &&
      this.props.removeVip
    );
  }

  toggleEdges() {
    // hide all edges allways
    window.jQuery(this.edges_selector).hide();
    // edges selectors
    if (this.props.edge_option === edge_options.ALL_CREW) {
      const crew = `${this.edges_selector}[data-group="Crew"]`;
      window.jQuery(crew).show();
    }
    if (this.props.edge_option === edge_options.CAB) {
      const cabin = `${this.edges_selector}[data-group="Cabin"]`;
      window.jQuery(cabin).show();
    }
    if (this.props.edge_option === edge_options.COCK) {
      const cockpit = `${this.edges_selector}[data-group="Cockpit"]`;
      window.jQuery(cockpit).show();
    }
    if (this.props.edge_option === edge_options.MCXN_CREW) {
      const crew_mscx = `${this.edges_selector}[data-group="Crew"][data-is_broken]`;
      window.jQuery(crew_mscx).show();
    }
    if (this.props.edge_option === edge_options.ALL_PAX) {
      const pax = `${this.edges_selector}[data-group="Pax"]`;
      window.jQuery(pax).show();
    }
    if (this.props.edge_option === edge_options.MCXN_PAX) {
      const pax_mscx = `${this.edges_selector}[data-group="Pax"][data-is_broken]`;
      window.jQuery(pax_mscx).show();
    }
  }

  componentDidUpdate() {
    this.setupFlightContextMenu(!this.hasFlightActions());
    this.toggleEdges();
  }

  render() {
    return (
      <ScrollContainer style={{ width: "100%" }}>
        <SvgViewer
          _ref={this.myRef}
          hide={this.props.hide}
          _figure_id={this._figure_id}
          svg={this.props.svg}
          default_percentage={this.props.percentage}
        />
      </ScrollContainer>
    );
  }
}
