import { ModifiedDuties } from "pages/Recover/components/SolutionDetails/components/CrewTables";
import React, { useEffect } from "react";

import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  pullScenarioDuties,
  selectScenarioDutiesD0,
  selectScenarioDutiesD1,
} from "slices/scenarios/scenariosSlice";

import styles from "./index.module.css";

export const DutyChangesModal = ({
  show,
  setShow,
  flight,
  flight_day,
  scenario_uid,
  base_scenario_uid,
}) => {
  const handleClose = (event) => {
    setShow(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (scenario_uid && base_scenario_uid)
      dispatch(pullScenarioDuties(scenario_uid, base_scenario_uid));
  }, [dispatch, scenario_uid, base_scenario_uid]);

  const duties_d0 =
    useSelector(selectScenarioDutiesD0(scenario_uid), shallowEqual) || {};
  const duties_d1 =
    useSelector(selectScenarioDutiesD1(scenario_uid), shallowEqual) || {};

  return (
    <Modal
      size="lg"
      dialogClassName={styles.Dialog}
      show={show}
      onHide={handleClose}
      animation={false}
      restoreFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>DutyChanges</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModifiedDuties
          title="Crew Duties D0"
          duties={duties_d0.data || []}
          loading={duties_d0.loading}
          base_scenario_uid={base_scenario_uid}
          solution_scenario_uid={scenario_uid}
          crew_text={(flight_day === "d0" && flight) || ""}
        />
        <ModifiedDuties
          title="Crew Duties D1"
          duties={duties_d1.data || []}
          loading={duties_d1.loading}
          base_scenario_uid={base_scenario_uid}
          solution_scenario_uid={scenario_uid}
          crew_text={(flight_day === "d1" && flight) || ""}
        />
      </Modal.Body>
    </Modal>
  );
};
