import { createSelector, createSlice } from "@reduxjs/toolkit";

import {
  fetchStats,
  fetchQueuedJobs,
  fetchCanceledJobs,
  fetchDeferredJobs,
  fetchFailedJobs,
  fetchFinishedJobs,
  fetchScheduledJobs,
  fetchStartedJobs,
  fetchJobDetails,
  cancelJob,
  requeueJob,
} from "./fetch";

export const pullStats = () => (dispatch) => {
  dispatch(fetchStats());
};

export const pullQueuedJobs = (queue_name) => (dispatch) => {
  dispatch(fetchQueuedJobs({ queue_name }));
};

export const pullCanceledJobs = (queue_name) => (dispatch) => {
  dispatch(fetchCanceledJobs({ queue_name }));
};

export const pullDeferredJobs = (queue_name) => (dispatch) => {
  dispatch(fetchDeferredJobs({ queue_name }));
};

export const pullFailedJobs = (queue_name) => (dispatch) => {
  dispatch(fetchFailedJobs({ queue_name }));
};

export const pullFinishedJobs = (queue_name) => (dispatch) => {
  dispatch(fetchFinishedJobs({ queue_name }));
};

export const pullScheduledJobs = (queue_name) => (dispatch) => {
  dispatch(fetchScheduledJobs({ queue_name }));
};

export const pullStartedJobs = (queue_name) => (dispatch) => {
  dispatch(fetchStartedJobs({ queue_name }));
};

export const pullJobDetails =
  (queue_name = "", job_id = "") =>
  (dispatch) => {
    dispatch(fetchJobDetails({ queue_name, job_id }));
  };

export const pullCancelJob = (queue_name, job_id) => (dispatch) => {
  dispatch(cancelJob({ queue_name, job_id }));
};

export const pullRequeueJob = (queue_name, job_id) => (dispatch) => {
  dispatch(requeueJob({ queue_name, job_id }));
};

const buildFetchCases = (builder, thunk, name) => {
  builder
    .addCase(thunk.fulfilled, (state, action) => {
      state[name] = { ...state[name], ...action.payload };
    })
    .addCase(thunk.rejected, (state, action) => {
      console.log(
        `buildFetchCases REJECTED: registry=${name} action=${JSON.stringify(
          action
        )} state=${JSON.stringify(state)}`
      );
    })
    .addCase(thunk.pending, (state, action) => {
      if (!state[name]) state[name] = {};
    });
};

export const queuesSlice = createSlice({
  name: "queues",
  initialState: {
    stats: {},
    queued_jobs: {},
    canceled_jobs: {},
    deferred_jobs: {},
    failed_jobs: {},
    finished_jobs: {},
    scheduled_jobs: {},
    started_jobs: {},
    job_details: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    buildFetchCases(builder, fetchStats, "stats");
    buildFetchCases(builder, fetchQueuedJobs, "queued_jobs");
    buildFetchCases(builder, fetchCanceledJobs, "canceled_jobs");
    buildFetchCases(builder, fetchDeferredJobs, "deferred_jobs");
    buildFetchCases(builder, fetchFailedJobs, "failed_jobs");
    buildFetchCases(builder, fetchFinishedJobs, "finished_jobs");
    buildFetchCases(builder, fetchScheduledJobs, "scheduled_jobs");
    buildFetchCases(builder, fetchStartedJobs, "started_jobs");
    buildFetchCases(builder, fetchJobDetails, "job_details");
    buildFetchCases(builder, cancelJob, "job_details");
    buildFetchCases(builder, requeueJob, "job_details");
  },
});

export default queuesSlice;

const getQueues = (state) => state.queues;

export const selectStats = createSelector(getQueues, (state) => state.stats);

export const selectQueuedJobs = createSelector(
  getQueues,
  (state) => state.queued_jobs
);

export const selectCanceledJobs = createSelector(
  getQueues,
  (state) => state.canceled_jobs
);

export const selectDeferredJobs = createSelector(
  getQueues,
  (state) => state.deferred_jobs
);

export const selectFailedJobs = createSelector(
  getQueues,
  (state) => state.failed_jobs
);

export const selectFinishedJobs = createSelector(
  getQueues,
  (state) => state.finished_jobs
);

export const selectScheduledJobs = createSelector(
  getQueues,
  (state) => state.scheduled_jobs
);

export const selectStartedJobs = createSelector(
  getQueues,
  (state) => state.started_jobs
);

export const selectJobDetails = (job_id) =>
  createSelector(
    getQueues,
    (state) => state.job_details && state.job_details[job_id]
  );
