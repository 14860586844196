import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ScenarioRequest } from "api";

import { Loader } from "components/common/Loader";

export const SolutionRedirect = ({ match: { params } }) => {
  let history = useHistory();

  useEffect(() => {
    const fetchScenario = async () => {
      try {
        const request = new ScenarioRequest();
        const response = await request.get(params.uid);
        history.push(`/recover/${response.query_uid}/${response.uid}`);
      } catch (err) {
        toast.error(`Error: ${err.message}`);
        history.goBack();
      }
    };
    if (!!params?.uid) fetchScenario();
  }, [params, history]);

  return (
    <div>
      <Loader show full message="Redirecting to the new solution view ..." />
    </div>
  );
};
