import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { TemporalRules } from "api";
import { DateTime } from "luxon";

const temporalRules = createSlice({
  name: "temporalRules",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    fetchTemporalLoadingStart: (state) => {
      state.loading = true;
    },
    fetchTemporalRulesSucceeded: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchTemporalLoadingEnd: (state) => {
      state.loading = false;
    },
    createRuleFinish: (state, action) => {
      state.list.push(action.payload);
      state.loading = false;
    },
    deleteRuleFinish: (state, action) => {
      state.list = state.list.filter((rule) => rule.uid !== action.payload);
    },
  },
});

export default temporalRules;

export const getTemporalRules = createSelector(
  (state) => state.temporalRules,
  (state) => state?.list || []
);
export const getTotalTemporalRules = createSelector(
  (state) => state.temporalRules,
  (state) => state?.list.length
);
export const getActiveTemporalRules = createSelector(
  getTemporalRules,
  (state) =>
    state.filter(
      (rule) =>
        DateTime.fromFormat(rule.valid_from, "yyyy-MM-dd", {
          zone: "utc",
        }).startOf("day") < DateTime.utc().endOf("day") &&
        DateTime.utc().startOf("day") <
        DateTime.fromFormat(rule.valid_to, "yyyy-MM-dd", {
          zone: "utc",
        }).endOf("day")
    ) || []
);
export const getTotalActiveTemporalRules = createSelector(
  (state) => state.temporalRules,
  (state) => getActiveTemporalRules(state).length
);
export const getTemporalRulesLoading = createSelector(
  (state) => state.temporalRules,
  (state) => state?.loading
);
export const getTemporalRulesByKind = (kind) =>
  createSelector(getTemporalRules, (state) =>
    state.filter((rule) => rule.kind === kind)
  );
export const getActiveTemporalRulesByKind = (kind) =>
  createSelector(
    (state) => state.temporalRules,
    (state) =>
      getActiveTemporalRules(state).filter((rule) => rule.kind === kind)
  );

export const fetchTemporalRules = () => async (dispatch) => {
  dispatch(temporalRules.actions.fetchTemporalLoadingStart());
  try {
    const { rules } = await TemporalRules.list();
    dispatch(temporalRules.actions.fetchTemporalRulesSucceeded(rules));
  } catch {
    dispatch(temporalRules.actions.fetchTemporalLoadingEnd());
  }
};

export const createTemporalRule = (temporal_rule, callback) => async (dispatch) => {
  dispatch(temporalRules.actions.fetchTemporalLoadingStart());
  try {
    const { rule } = await TemporalRules.create(temporal_rule);
    dispatch(temporalRules.actions.createRuleFinish(rule));
    callback();
  } catch (error) {
    toast.error(`Temporal rule creation failed: ${error.message}`);
    dispatch(temporalRules.actions.fetchTemporalLoadingEnd());
  }
};

export const deleteTemporalRule = (rule_uid, callback) => async (dispatch) => {
  try {
    await TemporalRules.delete(rule_uid);
    dispatch(temporalRules.actions.deleteRuleFinish(rule_uid));
    callback();
  } catch (error) {
    toast.error(`Temporal rule deletion failed: ${error.message}`);
  }
};
