import React from "react";
import { Badge, Nav, Navbar } from "react-bootstrap";

export const sections = {
  WARNINGS: "warnings",
  CREWS: "crews",
  MEMOS: "memos",
};

export const Navigation = ({
  active_key,
  setActiveKey,
  total_warnings,
  total_crew,
  total_memos,
}) => {
  const Item = ({ eventKey, name, total }) => (
    <Nav.Item>
      <Nav.Link active={active_key === eventKey} eventKey={eventKey}>
        {`${name} `}
        {!!total && <Badge variant="info">{total}</Badge>}
      </Nav.Link>
    </Nav.Item>
  );
  return (
    <Navbar
      bg="dark-grey"
      variant="dark"
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <Nav className="mr-auto" onSelect={setActiveKey}>
        <Item
          eventKey={sections.WARNINGS}
          name="Warnings"
          total={total_warnings}
        />
        <Item eventKey={sections.CREWS} name="Crews" total={total_crew} />
        <Item eventKey={sections.MEMOS} name="Memos" total={total_memos} />
      </Nav>
    </Navbar>
  );
};
