import React from "react";
import Octicon from "react-octicon";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactTable from "react-table-6";
import { deepMemo, hiddenCol, namedCol } from "utils";
import { DateTime } from "luxon";
import { getDurationFromISO } from "../utils";

export const DisplayAogs = deepMemo(
  ({
    aogs,
    invalid_aogs = [],
    onUpdate,
    aircraft_mapping = {},
    stations_mapping = {},
  }) => {
    const columns = [
      hiddenCol("id"),
      namedCol(
        "Aircraft",
        (d) => aircraft_mapping[d.aircraft_id],
        "AIRCRAFT",
        150
      ),
      namedCol("Station", (d) => stations_mapping[d.station_id]),
      namedCol(
        "Start",
        (d) => DateTime.fromISO(d.start).toFormat("yyyy/MM/dd HH:mm"),
        "START",
        150
      ),
      namedCol(
        "End",
        (d) => DateTime.fromISO(d.end).toFormat("yyyy/MM/dd HH:mm"),
        "END",
        150
      ),
      namedCol(
        "Duration",
        (d) => getDurationFromISO(d.start, d.end),
        "DURATION",
        200
      ),
      {
        id: "actions",
        Header: "Actions",
        style: { textAlign: "center" },
        maxWidth: 100,
        Cell: (row) => (
          <ButtonGroup>
            <Button
              size="sm"
              onClick={() =>
                onUpdate(aogs.filter((aog) => aog.id !== row.original.id))
              }
            >
              <Octicon name="trashcan" />
            </Button>
          </ButtonGroup>
        ),
      },
    ];

    return (
      <ReactTable
        multiSort
        data={aogs}
        columns={columns}
        minRows={3}
        resizable={false}
        defaultPageSize={5}
        noDataText={"No user AOGs found"}
        getTrProps={(state, rowInfo) => ({
          style: {
            background:
              rowInfo && invalid_aogs.indexOf(rowInfo.row.id) > -1
                ? "#e15759"
                : "",
          },
        })}
      />
    );
  }
);
