import { authorizedRequest, httpMethods } from "../utils";

export const TemporalRules = {
  list: (reference_date) =>
    authorizedRequest(
      reference_date
        ? `/rules?reference_date=${reference_date
            .toDate()
            .toLocaleString("en-GB")}`
        : "/rules",
      httpMethods.GET
    ),
  get: (id) => authorizedRequest(`/rules/${id}`, httpMethods.GET),
  create: (rule) => authorizedRequest(`/rules`, httpMethods.POST, rule),
  update: (id, rule) =>
    authorizedRequest(`/rules/${id}`, httpMethods.PATCH, rule),
  delete: (id) => authorizedRequest(`/rules/${id}`, httpMethods.DELETE),
};
