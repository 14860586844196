import React from "react";
import { deepMemo } from "utils";

import { DisplayAogs } from "./AOGManager/table";
import { DisplayCurfews } from "./CloseAirportManager/table";

export const DisruptionsSummary = deepMemo(
  ({
    aogs,
    onUpdateAogs,
    curfews,
    onUpdateCurfews,
    aircraft_mapping,
    stations_mapping,
  }) => {
    return (
      <>
        <center>
          <h4>AOGs</h4>
        </center>
        <DisplayAogs
          aogs={aogs}
          onUpdate={onUpdateAogs}
          aircraft_mapping={aircraft_mapping}
          stations_mapping={stations_mapping}
        />
        <center>
          <h4>Airport Closures</h4>
        </center>
        <DisplayCurfews
          curfews={curfews}
          onUpdate={onUpdateCurfews}
          stations_mapping={stations_mapping}
        />
      </>
    );
  }
);
