import React from "react";
import {
  columns_aircraft_balance,
  columns_uncrew_duties,
  columns_infeasible_aircrafts,
  columns_infeasible_crews,
  columns_infeasible_curfews,
  columns_infeasible_d1crews,
} from "./columns";
import { StatsTable } from "./StatsTable";

export const Warnings = ({
  infeasible_crews = [],
  infeasible_aircrafts = [],
  infeasible_curfews = [],
  aircraft_balance_violations = [],
  infeasible_crews_next_day = [],
}) => {
  const has_warnings =
    infeasible_crews.length ||
    infeasible_aircrafts.length ||
    infeasible_curfews.length ||
    aircraft_balance_violations.length ||
    infeasible_crews_next_day.length;
  return has_warnings ? (
    <>
      <StatsTable
        title="Crews"
        data={infeasible_crews}
        columns={columns_infeasible_crews}
      />
      <StatsTable
        title="Aircrafts"
        data={infeasible_aircrafts}
        columns={columns_infeasible_aircrafts}
      />
      <StatsTable
        title="Curfews"
        data={infeasible_curfews}
        columns={columns_infeasible_curfews}
      />
      <StatsTable
        title="Aircraft balance violations"
        data={aircraft_balance_violations}
        columns={columns_aircraft_balance}
      />
      <StatsTable
        title="Crews (D+1)"
        data={infeasible_crews_next_day}
        columns={columns_infeasible_d1crews}
      />
    </>
  ) : (
    <div>
      <center>
        <h1>No Warnings</h1>
      </center>
    </div>
  );
};

export const UncrewDuties = ({ uncrew_duties = [] }) => {
  const has_uncrew_duties = uncrew_duties.length;
  return has_uncrew_duties ? (
    <>
      <StatsTable
        title="Uncrew duties"
        data={uncrew_duties}
        columns={columns_uncrew_duties}
      />
    </>
  ) : (
    <div>
      <center>
        <h1>No Uncrew Duties</h1>
      </center>
    </div>
  );
};
