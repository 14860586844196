import { authorizedRequest, httpMethods } from "../utils";

export const AircraftTargets = {
  list: () => authorizedRequest("/aircraft_target", httpMethods.GET),
  create: (aircraft_target) =>
    authorizedRequest(`/aircraft_target`, httpMethods.POST, aircraft_target),
  update: (id, aircraft_target) =>
    authorizedRequest(
      `/aircraft_target/${id}`,
      httpMethods.PATCH,
      aircraft_target
    ),
  delete: (id, force) =>
    authorizedRequest(`/aircraft_target/${id}`, httpMethods.DELETE),
};
