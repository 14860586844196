export const organizations = [
  { value: "volotea", label: "Volotea (VOE)" },
  { value: "vueling", label: "Vueling (VLG)" },
  { value: "viva", label: "Vivaaerobus (VIV)" },
  { value: "flydubai", label: "FlyDubai (FZ)" },
  { value: "flydubaidev", label: "FlyDubai (FZ-DEV)" },
  { value: "aegean", label: "Aegean (AEE)" },
];

export const getOptionByValue = (options, value) =>
  options.find((o) => o.value === value);
