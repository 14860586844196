import { RecoveryQuery } from "api";
import * as utils from "utils";

let cancelables = [];
const INTERVAL = 10 * 1000; // 10 seconds in milliseconds

// TODO: Future https://javascript.info/long-polling

export class RecoveryPulling {
  cancelables = [];
  async subscribe(uid, cb) {
    this.clean();
    const cancelable = RecoveryQuery.get_(uid);
    cancelables.push(cancelable.c);
    return cancelable.rq.then((r) => {
      if (cb(r) && !this.killAll) {
        utils.sleep(INTERVAL).then(() => {
          this.subscribe(uid, cb);
        });
      }
    });
  }
  clean() {
    this.cancelables.forEach((controller) => {
      controller.abort();
    });
    this.cancelables = [];
  }
  unsubscribe() {
    this.killAll = true;
    this.cancelables.forEach((controller) => {
      controller.abort();
    });
    this.cancelables = [];
  }
}
