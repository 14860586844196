import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

import { Navigation, sections } from "./Navigation";
import { FlightCrew } from "./FlightCrew";
import { FlightWarnings } from "./FlightWarnings";
import { Memos } from "./Memos";
import {
  selectScenarioProblems,
  selectScenarioFlightCrews,
  selectScenarioFlightCrewsLoading,
  selectScenarioMemos,
  pullFlightsData,
} from "slices/scenarios/scenariosSlice";

import styles from "./index.module.css";
import { deepMemo } from "utils";

export const FlightDetails = deepMemo(
  ({ show, setShow, flight, scenario_uid, base_scenario_uid, is_demo }) => {
    const [active_key, setActiveKey] = useState(sections.CREWS);
    const {
      flight_problems = {},
      crew_problems = {},
      pax_problems = {},
    } = useSelector(selectScenarioProblems(scenario_uid), shallowEqual)?.data ||
      {};
    const flight_crew = useSelector(
      selectScenarioFlightCrews(scenario_uid, flight),
      shallowEqual
    );
    const loading = useSelector(
      selectScenarioFlightCrewsLoading(scenario_uid),
      shallowEqual
    );
    const memos =
      useSelector(selectScenarioMemos(scenario_uid), shallowEqual)?.data || {};

    const handleClose = (event) => {
      setShow(false);
      setActiveKey(sections.CREWS);
    };
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(pullFlightsData(scenario_uid, base_scenario_uid));
    }, [dispatch, scenario_uid, base_scenario_uid]);

    const total_flight_crew = flight_crew?.length;
    const total_warnings =
      (crew_problems[flight]?.length || 0) +
      (flight_problems[flight]?.length || 0) +
      (pax_problems[flight]?.length || 0);
    const total_memos = memos[flight]?.length;
    const flight_name = flight?.slice(0, -13)

    return (
      <Modal
        size="lg"
        dialogClassName={styles.Dialog}
        show={show}
        onHide={handleClose}
        animation={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Flight details: {is_demo ? "YY-XXXX" : flight_name}</Modal.Title>
        </Modal.Header>
        <Navigation
          active_key={active_key}
          setActiveKey={setActiveKey}
          total_warnings={total_warnings}
          total_crew={total_flight_crew}
          total_memos={total_memos}
        />
        <Modal.Body>
          <FlightWarnings
            show={sections.WARNINGS === active_key}
            crew_problems={crew_problems[flight]}
            flight_problems={flight_problems[flight]}
            pax_problems={pax_problems[flight]}
          />
          <FlightCrew
            flight={flight}
            crews={flight_crew}
            crew_problems={crew_problems[flight]}
            scenario_uid={scenario_uid}
            base_scenario_uid={base_scenario_uid}
            show={sections.CREWS === active_key}
            loading={loading}
          />
          <Memos memos={memos[flight]} show={sections.MEMOS === active_key} />
        </Modal.Body>
      </Modal>
    );
  }
);
