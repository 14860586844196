import { authorizedRequest, httpMethods } from "../utils";

export const Period = {
  list: () => authorizedRequest("/period", httpMethods.GET),
  create: (period) => authorizedRequest(`/period`, httpMethods.POST, period),
  update: (id, period) =>
    authorizedRequest(`/period/${id}`, httpMethods.PATCH, period),
  delete: (id, force) =>
    authorizedRequest(
      force ? `/period/${id}?force=true` : `/period/${id}`,
      httpMethods.DELETE
    ),
};
