import React from "react";
import classNames from "classnames";
import { chunk } from "lodash";
import Tooltip from "react-tooltip-lite";
import moment from "moment";

import { AirplaneIcon, CrewIcon } from "components/common/Icons";

import styles from "./StandByReport.module.css";
let cx = classNames.bind(styles);

const hasVirtualResources = (target) =>
  !!target.early?.virtual_aircraft?.length ||
  !!target.early?.virtual_crew?.length;

export const hasEarlyAircraftTarget = (target) =>
  !!target.early?.aircraft_target?.length;
const hasEarlyCrewTarget = (target) => !!target.early?.crew_target?.length;
const hasEarlyTarget = (target) =>
  hasEarlyAircraftTarget(target) && hasEarlyCrewTarget(target);

export const hasLateAircraftTarget = (target) =>
  !!target.late?.aircraft_target?.length;
const hasLateCrewTarget = (target) => !!target.late?.crew_target?.length;
const hasLateTarget = (target) =>
  hasLateAircraftTarget(target) && hasLateCrewTarget(target);

const hasFullTargets = (target) =>
  hasEarlyTarget(target) && hasLateTarget(target);

const hasCrewTargets = (target) =>
  hasEarlyCrewTarget(target) || hasLateCrewTarget(target);
const hasAircraftTarget = (target) =>
  hasEarlyAircraftTarget(target) || hasLateAircraftTarget(target);

const alphabeticalSort = (a, b) => {
  var A = a.toUpperCase(); // ignore upper and lowercase
  var B = b.toUpperCase(); // ignore upper and lowercase
  if (A < B) {
    return -1; //A comes first
  }
  if (A > B) {
    return 1; // B comes first
  }
  return 0; // names must be equal
};

const orderReport = (standbys_report) => {
  let new_standbys_report = standbys_report
    .map((r) => ({ ...r }))
    .filter((r) => r.early !== null || r.late !== null);
  const full_targets = new_standbys_report
    .filter((t) => hasFullTargets(t))
    .sort((a, b) => alphabeticalSort(a.base, b.base));
  const only_crew_targets = new_standbys_report
    .filter((t) => hasCrewTargets(t) && !hasAircraftTarget(t))
    .sort((a, b) => alphabeticalSort(a.base, b.base));
  const available_resources = new_standbys_report
    .filter((t) => hasVirtualResources(t) && !hasCrewTargets(t))
    .sort((a, b) => alphabeticalSort(a.base, b.base));
  return [...full_targets, ...only_crew_targets, ...available_resources];
};

export const StandByReport = ({ standbys_report = [], references }) => {
  const new_standbys_report = orderReport(standbys_report);
  return (
    <div className={styles.StandByGridWrapper}>
      {new_standbys_report.map((s, index) => (
        <StationBox
          key={s.base}
          station={s.base}
          boxRef={references && references[index]}
          first_shift={s.early}
          second_shift={s.late}
          is_hot_stby={s.is_hot}
        />
      ))}
    </div>
  );
};

const getClassName = (status) =>
  cx(styles.Base, {
    [styles.Available]: status === "available",
    [styles.Missing]: status === "missing",
    [styles.MissingFuture]: status === "missing_future",
    [styles.Virtual]: status === "virtual",
    [styles.Na]: !status,
  });

const StationBox = ({ station, first_shift, second_shift, boxRef, is_hot_stby }) => (
  <div style={{ margin: "10px" }}>
    <table
      style={{
        position: "relative",
        borderColor: is_hot_stby ? "black" : undefined,
      }}
      className={styles.StandByBoxWrapper}
      ref={boxRef}
    >
      <tbody>
        <tr>
          <td className={styles.StandByBoxTitle} colSpan="2">
            {station}
          </td>
        </tr>
        <tr>
          <CrewBoxCell
            status={first_shift?.crew_status}
            violations={first_shift?.crew_violations}
            targets={first_shift?.crew_target}
            virtual={first_shift?.virtual_crew}
          />
          <AircraftBoxCell
            status={first_shift?.aircraft_status}
            violations={first_shift?.aircraft_violations}
            targets={first_shift?.aircraft_target}
            virtual={first_shift?.virtual_aircraft}
          />
        </tr>
        <tr>
          <CrewBoxCell
            status={second_shift?.crew_status}
            violations={second_shift?.crew_violations}
            targets={second_shift?.crew_target}
            virtual={second_shift?.virtual_crew}
          />
          <AircraftBoxCell
            status={second_shift?.aircraft_status}
            violations={second_shift?.aircraft_violations}
            targets={second_shift?.aircraft_target}
            virtual={second_shift?.virtual_aircraft}
          />
        </tr>
      </tbody>
    </table>
  </div>
);

const formatDate = (date) => moment(date).format("HH:mm");

const formatCrewViolation = (v) =>
  `${v.required} (${v.role}) ${formatDate(v.start)} ~ ${formatDate(v.end)}`;
const formatCrewTargets = (t) => `${t.required} (${t.role})`;

const CrewBoxCell = ({
  status,
  violations = [],
  targets = [],
  virtual = [],
}) => {
  if (!(violations.length || targets.length || virtual.length)) {
    return (
      <td className={getClassName(status)}>
        <CrewIcon viewBox="0 0 512 512" />
      </td>
    );
  }
  const home_targets = targets.filter((t) => !t.is_airport);
  const airport_targets = targets.filter((t) => !!t.is_airport);
  const home_virtual = virtual.filter((t) => !t.is_airport);
  const airport_virtual = virtual.filter((t) => !!t.is_airport);
  const home_violations = violations.filter((t) => !t.is_airport);
  const airport_violations = violations.filter((t) => !!t.is_airport);

  return (
    <Tooltip
      useHover
      content={
        <div>
          {!!(
            home_violations.length ||
            home_targets.length ||
            home_virtual.length
          ) && <div> HOME </div>}
          {!!home_targets.length && <div>Target:</div>}
          {chunk(home_targets, 2).map((g, i) => (
            <div key={i}>{g.map((t) => formatCrewTargets(t)).join(", ")}</div>
          ))}
          {!!home_virtual.length && <div>Available:</div>}
          {chunk(home_virtual, 2).map((g, i) => (
            <div key={i}>{g.map((t) => formatCrewTargets(t)).join(", ")}</div>
          ))}
          {!!home_violations.length && <div>Missing:</div>}
          {home_violations.map((v) => (
            <div key={formatCrewViolation(v)}>{formatCrewViolation(v)}</div>
          ))}
          {!!(
            airport_violations.length ||
            airport_targets.length ||
            airport_virtual.length
          ) && <div> AIRPORT </div>}
          {!!airport_targets.length && <div>Target:</div>}
          {chunk(airport_targets, 2).map((g, i) => (
            <div key={i}>{g.map((t) => formatCrewTargets(t)).join(", ")}</div>
          ))}
          {!!airport_virtual.length && <div>Available:</div>}
          {chunk(airport_virtual, 2).map((g, i) => (
            <div key={i}>{g.map((t) => formatCrewTargets(t)).join(", ")}</div>
          ))}
          {!!airport_violations.length && <div>Missing:</div>}
          {airport_violations.map((v) => (
            <div key={formatCrewViolation(v)}>{formatCrewViolation(v)}</div>
          ))}
        </div>
      }
      direction="down"
      tagName="td"
      className={getClassName(status)}
    >
      <CrewIcon viewBox="0 0 512 512" />
    </Tooltip>
  );
};

const formatAircraftViolation = (v) =>
  `${v.required} (${v.fleet}) ${formatDate(v.start)} ~ ${formatDate(v.end)}`;
const formatAircraftTargets = (t) => `${t.required} (${t.fleet})`;

const AircraftBoxCell = ({
  status,
  violations = [],
  targets = [],
  virtual = [],
}) =>
  violations.length || targets.length || virtual.length ? (
    <Tooltip
      useHover
      content={
        <div>
          {!!targets.length && <div>Target:</div>}
          {targets.map((t) => formatAircraftTargets(t)).join(", ")}
          {!!virtual.length && <div>Available:</div>}
          {virtual.map((t) => formatAircraftTargets(t)).join(", ")}
          {!!violations.length && <div>Missing:</div>}
          {violations.map((v) => (
            <div key={formatAircraftViolation(v)}>
              {formatAircraftViolation(v)}
            </div>
          ))}
        </div>
      }
      direction="down"
      tagName="td"
      className={getClassName(status)}
    >
      <AirplaneIcon viewBox="-25 -25 450 450" />
    </Tooltip>
  ) : (
    <td className={getClassName(status)}>
      <AirplaneIcon viewBox="-25 -25 450 450" />
    </td>
  );
