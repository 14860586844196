import React from "react";
import { simpleCol } from "utils";

import { StatsTable } from "./StatsTable";

const mscx_columns = [
  simpleCol("Flights", 250),
  simpleCol("DelayedFlight", 200),
  simpleCol("MisconnectionTimes"),
  simpleCol("MisconnectedPassengers", 200),
];

export const MCNXReport = ({ mcnx_itineraries = [] }) =>
  mcnx_itineraries.length ? (
    <StatsTable title="MCXN" columns={mscx_columns} data={mcnx_itineraries} />
  ) : (
    <div>
      <center>
        <h1>No MCXN events</h1>
      </center>
    </div>
  );
