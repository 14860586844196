import { Duration } from "luxon";

const minutes_options = [
  {
    value: String(Duration.fromObject({ hours: 0, minutes: 30 }).as("minutes")),
    label: "00:30",
  },
  {
    value: String(Duration.fromObject({ hours: 1, minutes: 0 }).as("minutes")),
    label: "01:00",
  },
  {
    value: String(Duration.fromObject({ hours: 1, minutes: 30 }).as("minutes")),
    label: "01:30",
  },
  {
    value: String(Duration.fromObject({ hours: 2, minutes: 0 }).as("minutes")),
    label: "02:00",
  },
  {
    value: String(Duration.fromObject({ hours: 2, minutes: 30 }).as("minutes")),
    label: "02:30",
  },
  {
    value: String(Duration.fromObject({ hours: 3, minutes: 0 }).as("minutes")),
    label: "03:00",
  },
  {
    value: String(Duration.fromObject({ hours: 3, minutes: 30 }).as("minutes")),
    label: "03:30",
  },
  {
    value: String(Duration.fromObject({ hours: 4, minutes: 0 }).as("minutes")),
    label: "04:00",
  },
  {
    value: String(Duration.fromObject({ hours: 4, minutes: 30 }).as("minutes")),
    label: "04:30",
  },
  {
    value: String(Duration.fromObject({ hours: 5, minutes: 0 }).as("minutes")),
    label: "05:00",
  },
  {
    value: String(Duration.fromObject({ hours: 5, minutes: 30 }).as("minutes")),
    label: "05:30",
  },
];

export const buildConditions = ({
  stations = [],
  countries = [],
  aircraftTypes = [],
  flights = [],
  flightsStatus = [],
  serviceType = [],
}) => [
    {
      id: "PASSING",
      label: "passing through",
      options: stations,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "station"
    {
      id: "DEPARTURE_STATION",
      label: "departuring from",
      options: stations,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "station"
    {
      id: "ARRIVAL_STATION",
      label: "arriving to",
      options: stations,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "station"
    // {
    //   id: "SECTOR",
    //   label: "operating sectors",
    //   options: stations,
    //   paramExtractor: (results) =>
    //     results?.options?.map((option) => option.value),
    // }, // "route"
    {
      id: "COUNTRY",
      label: "operating in country",
      options: countries,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "country"
    {
      id: "SERVICE_TYPE",
      label: "with service type",
      options: serviceType,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "service_type"
    {
      id: "FLIGHT_STATUS",
      label: "with flight status",
      options: flightsStatus,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "flight_status"
    {
      id: "FLEET",
      label: "where aircraft fleet",
      options: aircraftTypes,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "fleet"
    {
      id: "DELAY",
      label: "with more delay than",
      options: minutes_options,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "threshold"
    // { id: "HAS_MEMOS", label: "has memos" },
    {
      id: "LOAD_FACTOR_LARGER",
      label: "with load factor larger than",
      percentage: true,
      paramExtractor: (results) => parseInt(results.percentage),
    },
    {
      id: "LOAD_FACTOR_SMALLER",
      label: "with load factor smaller than",
      percentage: true,
      paramExtractor: (results) => parseInt(results.percentage),
    },
    {
      id: "IS_VIP",
      label: "are vip",
      paramExtractor: (results) => results.active,
    },
    {
      id: "HAS_MEMO",
      label: "has memos",
      paramExtractor: (results) => results.active,
    },
    // { id: "HAS_CREW_PROBLEMS", label: "has crew problems" },
    {
      id: "FLIGHT_NUMBER",
      label: "with flight numbers",
      options: flights,
      paramExtractor: (results) =>
        results?.options?.map((option) => option.value),
    }, // "flight_number"
    // { id: "CODE_SHARE", label: "with code shared", options: carrier_options }, // "code_share"
  ];
