import { RequestBuilder } from "../utils";

export class RecoveryQueryRequest extends RequestBuilder {
  async get(uid) {
    return this._get(`/recovery_query/${uid}`);
  }
  async list() {
    return this._get(`/recovery_query`);
  }
  async create(args) {
    return this._post("/recovery_query", args);
  }
  async update_comment(uid, comment) {
    return this._post(`/recovery_query/${uid}/comment`, {
      comment,
    });
  }
  async get_comment(uid) {
    return this._get(`/recovery_query/${uid}/comment`);
  }
  async mark_solution(uid, solution_uid) {
    return this._post(`/recovery_query/${uid}/solution/${solution_uid}`);
  }
  async delete_solution(uid, solution_uid) {
    return this._delete(`/recovery_query/${uid}/solution/${solution_uid}`);
  }
  async next_day(r_uid, s_uid) {
    return this._get(`/recovery_query/${r_uid}/d+1/${s_uid}`);
  }
  async get_feedback(uid) {
    return this._get(`/recovery_query/${uid}/feedback`);
  }
  async upsert_feedback(uid, feedback) {
    return this._post(`/recovery_query/${uid}/feedback`, feedback);
  }
}
