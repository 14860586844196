import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import { toast } from "react-toastify";

import { RecoveryQueryRequest } from "api";
import { getIsAdmin } from "slices/userSlice";
import { ScenarioViewer } from "components/common/ScenarioViewer";

import { QueryFeedback } from "./components/QueryFeedback";
import { RecoveryQueryTable } from "./components/RecoveryQueryTable";
import { SolutionDetails } from "./components/SolutionDetails";
import { deepMemo, useDeepCompareCallback, useDeepCompareEffect } from "utils";
import { fetchRecover } from "slices";
import {
  fetchRecoveryQuerySolutions,
  getRQBaseScenarioUid,
  getRQCompleted,
  getRQIsNextDay,
  getRQMarkedSolutionUid,
  getRQParentBaseScenarioUid,
  getRQParentUid,
  getRQScenarioUid,
  getRQSelectedSolution,
  solutionsSlice,
} from "slices/solutions/solutionsSlice";

const nextDayStyles = {
  color: "#fff !important",
  borderRadius: 0,
  backgroundColor: "#3157a3",
  borderColor: "#3157a3",
};

const SolveAgainBar = deepMemo(
  ({ query_uid, completed, base_scenario_uid, solution_uid }) => {
    let history = useHistory();
    const onSolveAgain = useDeepCompareCallback(async (solve_acmis = false) => {
      try {
        const request = new RecoveryQueryRequest();
        const response = await request.create({
          parent_rq_uid: query_uid, solve_acmis
        });
        history.push(`/recover/${response.uid}`);
        window.location.reload();
      } catch (err) {
        toast.error(`Requesting recover: ${err.message}`);
      }
    }, [completed, history, query_uid]);

    const onBuilderBase = useDeepCompareCallback(() => {
      history.push(`/builder/${base_scenario_uid}`);
    }, [history, base_scenario_uid]);
    const onBuilderSolution = useDeepCompareCallback(() => {
      history.push(`/builder/${solution_uid}`);
    }, [history, solution_uid]);
    return (
      <Navbar>
        <Nav>
          <Nav.Item>
            <Button onClick={() => onSolveAgain()}>Solve Again</Button>
          </Nav.Item>
          <Nav.Item>
            <Button onClick={() => onSolveAgain(true)}>Solve Again (With ACMIs)</Button>
          </Nav.Item>
          {!!base_scenario_uid && (
            <Nav.Item>
              <Button onClick={onBuilderBase}>Go to Builder w/t Base</Button>
            </Nav.Item>
          )}
          {!!solution_uid && (
            <Nav.Item>
              <Button onClick={onBuilderSolution}>
                Go to Builder w/t Solution
              </Button>
            </Nav.Item>
          )}
          {!!base_scenario_uid && (
            <Nav.Item>{`Base (UID): ${base_scenario_uid}`}</Nav.Item>
          )}
        </Nav>
      </Navbar>
    );
  }
);

const NextDayNav = deepMemo(({ next_day_query, query_uid, solution }) => (
  <Navbar bg="light-grey" variant="dark" className="trim-navbar">
    <Nav className="justify-content-center">
      <Nav.Item>
        {next_day_query ? (
          <Button
            as={NavLink}
            size="lg"
            target="_blank"
            style={nextDayStyles}
            to={`/next_day/${next_day_query}`}
          >
            View Next Day Solutions
          </Button>
        ) : (
          <Button
            as={NavLink}
            size="lg"
            target="_blank"
            style={nextDayStyles}
            to={`/plan_redirect/${query_uid}/${solution}`}
          >
            Solve Next Day
          </Button>
        )}
      </Nav.Item>
    </Nav>
  </Navbar>
));

const NextDayInfo = deepMemo(({ parent_uid, base_scenario_uid }) => (
  <Navbar bg="dark-grey" variant="dark" className="trim-navbar">
    <Navbar.Brand>{`Next Day Solver`}</Navbar.Brand>
    <Nav>
      <Nav.Item>
        <Nav.Link
          as={NavLink}
          to={`/recover/${parent_uid}/${base_scenario_uid}`}
        >
          Back to D0 solution
        </Nav.Link>
      </Nav.Item>
    </Nav>
  </Navbar>
));

export const Recover = ({ match: { params } }) => (
  <RecoverComponent query_uid={params.uid} solution_uid={params.solution_uid} />
);

export const RecoverComponent = deepMemo(({ query_uid, solution_uid }) => {
  const selected_solution_uid = solution_uid;
  const isAdmin = useSelector(getIsAdmin, shallowEqual);
  const completed = useSelector(getRQCompleted, shallowEqual);
  const marked_solution_uid = useSelector(getRQMarkedSolutionUid, shallowEqual);
  const is_next_day = useSelector(getRQIsNextDay, shallowEqual);
  const parent_base_scenario_uid = useSelector(
    getRQParentBaseScenarioUid,
    shallowEqual
  );
  const scenario_uid = useSelector(getRQScenarioUid, shallowEqual);
  const parent_uid = useSelector(getRQParentUid, shallowEqual);
  const base_scenario_uid = useSelector(getRQBaseScenarioUid, shallowEqual);
  const selected_solution = useSelector(getRQSelectedSolution, shallowEqual);
  let history = useHistory();

  let dispatch = useDispatch();
  document.body.style.overflow = "visible";

  useDeepCompareEffect(() => {
    if (query_uid) {
      dispatch(fetchRecoveryQuerySolutions(query_uid));
    }
  }, [dispatch, query_uid]);

  useDeepCompareEffect(() => {
    dispatch(solutionsSlice.actions.selectSolution(selected_solution_uid));
  }, [dispatch, selected_solution_uid]);

  useDeepCompareEffect(() => {
    dispatch(fetchRecover());
  }, [dispatch]);

  const onSolutionMark = useCallback(
    async (solution_uid) => {
      try {
        const request = new RecoveryQueryRequest();
        await request.mark_solution(query_uid, solution_uid);
      } finally {
        dispatch(solutionsSlice.actions.markSolution(solution_uid));
      }
    },
    [dispatch, query_uid]
  );

  const deleteSolution = useCallback(
    async (solution_uid) => {
      try {
        const request = new RecoveryQueryRequest();
        await request.delete_solution(query_uid, solution_uid);
        dispatch(solutionsSlice.actions.deleteSolution(solution_uid));
        toast.success(`Solution ${solution_uid} successfully deleted`);
        history.push(`/recover/${query_uid}`);
      } catch (err) {
        toast.error(`Problem deleting solution: ${err.message}`);
      }
    },
    [dispatch, query_uid, history]
  );

  const fetchSolutions = useCallback(() => {
    dispatch(fetchRecoveryQuerySolutions(query_uid));
  }, [dispatch, query_uid]);

  const diff_scenario_uid = is_next_day
    ? parent_base_scenario_uid
    : scenario_uid;

  return (
    <>
      {isAdmin && (
        <SolveAgainBar
          query_uid={query_uid}
          completed={completed}
          base_scenario_uid={base_scenario_uid}
          solution_uid={solution_uid}
        />
      )}
      {isAdmin && is_next_day && (
        <NextDayInfo
          parent_uid={parent_uid}
          base_scenario_uid={base_scenario_uid}
        />
      )}
      <RecoveryQueryTable
        marked_solution_uid={marked_solution_uid}
        onMarkSolution={onSolutionMark}
        deleteSolution={deleteSolution}
        selected_solution_uid={selected_solution_uid}
        query_uid={query_uid}
        base_scenario_uid={base_scenario_uid}
        show_d1={true}
        onSolutionUploadSuccess={fetchSolutions}
        solution_uid={solution_uid}
      />
      {!!selected_solution_uid && !!diff_scenario_uid && (
        <ScenarioViewer
          solution_name={
            selected_solution?.display_id || selected_solution?.id || "..."
          }
          scenario_uid={selected_solution_uid}
          base_scenario_uid={diff_scenario_uid}
        />
      )}
      <QueryFeedback
        query_uid={query_uid}
        no_good_solution={marked_solution_uid === "None"}
        markSolution={onSolutionMark}
      />
      {!!selected_solution_uid && (
        <>
          <br />
          {isAdmin && (
            <NextDayNav
              next_day_query={selected_solution?.query_uid}
              query_uid={query_uid}
              base_plan={scenario_uid}
              solution={selected_solution_uid}
            />
          )}
          <SolutionDetails
            title="Solution Metrics D0"
            solution_scenario_uid={selected_solution_uid}
            base_scenario_uid={diff_scenario_uid}
            scope={"d0"}
          />
          {selected_solution?.d1 && (
            <SolutionDetails
              title="Solution Metrics D1"
              solution_scenario_uid={selected_solution_uid}
              base_scenario_uid={diff_scenario_uid}
              scope={"d1"}
            />
          )}
        </>
      )}
      <ScenarioViewer toggle hide scenario_uid={diff_scenario_uid} />
    </>
  );
});
