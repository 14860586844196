import React from "react";
import ReactTable from "react-table-6";

export const BaseTable = ({
  title,
  data = [],
  columns,
  loading,
  defaultSorted,
  bgLookup = () => "",
  legend = [],
  ...props
}) => (
  <>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h4>{title}</h4>
      <div style={{ display: "flex", alignItems: "center" }}>
        {legend.map((l) => (
          <Dot key={l.color} color={l.color} text={l.text} />
        ))}
      </div>
    </div>
    <ReactTable
      data={data === null ? [] : data}
      resizable={false}
      style={{ marginBottom: "20px" }}
      columns={columns}
      minRows={3}
      loading={loading}
      defaultPageSize={5}
      defaultSorted={defaultSorted}
      getTrProps={(state, rowInfo) => ({
        style: {
          background: bgLookup(rowInfo?.row || {}),
        },
      })}
      {...props}
    />
  </>
);

const DotStyles = {
  width: "20px",
  height: "20px",
  display: "inline-block",
  borderRadius: "50%",
  marginRight: "5px",
  marginLeft: "10px",
};

export const Dot = ({ color, text }) => (
  <>
    <span style={{ ...DotStyles, backgroundColor: color }} /> {text}
  </>
);

export const colors = {
  Red: "#e15759",
  Yellow: "#edc949",
  Blue: "#76b7b2",
  Purple: "#ad8bc9",
  Orange: "#ffa500",
  Green: "#bfed49",
};
