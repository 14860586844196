import React, { useRef, useState } from "react";
import styles from "./SolutionsTable.module.css";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import { backgroundColorByRow } from "../utils";
import Tooltip from "react-tooltip-lite";

export const SolutionsTable = ({
  solutions,
  columns,
  selected_uid,
  setSelectedSolutionUid,
  show_d1,
}) => {
  const [sorting, setSorting] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    ops_status_toggle: false,
    aircraft_status_toggle: false,
    crew_status_toggle: false,
    pax_status_toggle: false,
    schedule_status_toggle: false,
  });

  const table = useReactTable({
    data: solutions,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    getSubRows: (row) => (row.d1 ? [row.d1] : []),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const tableContainerRef = useRef(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div ref={tableContainerRef} className={styles.Container}>
      <table className={styles.Table}>
        <thead className={styles.Thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={styles.Tr}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={styles.Th}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : () => header.column.columnDef.shortHeader && header.column.columns.map(column => !column.isToggle && column.toggleVisibility())}>
                        <Tooltip
                          useHover
                          content={
                            <div>
                              {header.column.columnDef.description || header.column.columnDef.header}
                            </div>

                          }
                          direction="down"
                          tagName="span"
                        >
                          {flexRender(
                            header.column.columnDef.shortHeader && header.colSpan === 1 ? header.column.columnDef.shortHeader : header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </Tooltip>
                      </div>
                    )
                    }
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td
                className={styles.Td}
                style={{
                  height: `${paddingTop}px`,
                }}
              />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            const is_selected =
              selected_uid && row.original.plan_uid === selected_uid;

            const border_style = is_selected ? "3px blue solid" : "";
            const subRow = row.subRows?.[0];
            const showSubRow = subRow && show_d1;
            return (
              <React.Fragment key={row.id}>
                <tr
                  style={{
                    background: backgroundColorByRow(row.original),
                    cursor: "default",
                    userSelect: "none",
                  }}
                  onClick={(e, handleOriginal) => {
                    setSelectedSolutionUid(row?.original?.plan_uid);
                    if (handleOriginal) {
                      handleOriginal();
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={styles.Td}
                        style={{
                          width: cell.column.getSize(),
                          borderTop: border_style,
                          borderBottom: showSubRow ? "" : border_style,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {showSubRow && (
                  <tr
                    style={{
                      background: backgroundColorByRow({
                        ...row.original,
                        ...subRow.original,
                      }),
                      cursor: "default",
                      userSelect: "none",
                    }}
                    onClick={(e, handleOriginal) => {
                      setSelectedSolutionUid(row?.original?.plan_uid);
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }}
                  >
                    {subRow.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={styles.Td}
                          style={{
                            borderBottom: border_style,
                            width: cell.column.getSize(),
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                )}
              </React.Fragment>
            );
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div >
  );
};
