import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { StatsTable } from "../StatsNav/components/StatsTable";
import {
  columns_crew_problems,
  columns_flight_problems,
  columns_pax_problems,
} from "../StatsNav/components/columns";

export const FlightWarnings = ({
  show,
  crews,
  flight_problems,
  crew_problems,
  pax_problems,
}) => {
  return (
    <Container fluid style={{ display: show ? "block" : "none" }}>
      <Row>
        <Col>
          <StatsTable
            title="Aircraft Warnings"
            data={flight_problems}
            minRows={3}
            columns={columns_flight_problems}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StatsTable
            title="Crew Warnings"
            data={crew_problems}
            minRows={3}
            columns={columns_crew_problems}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StatsTable
            title="Pax Warnings"
            data={pax_problems}
            minRows={3}
            columns={columns_pax_problems}
          />
        </Col>
      </Row>
    </Container>
  );
};
