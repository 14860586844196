import { createSlice, createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const aircraftTypesSlice = createSlice({
  name: "aircraftTypes",
  initialState: [],
  reducers: {
    fetchAircraftTypesSucceeded: (
      state,
      { payload: { aircraft_types, aircraft_types_labels } }
    ) => {
      return aircraft_types.map((aircraft, index) => ({
        value: aircraft,
        label: aircraft_types_labels[index],
      }));
    },
  },
});

aircraftTypesSlice.selectors = {
  getAircraftTypes: (state) => state.aircraftTypes,
};

export default aircraftTypesSlice;

const getAircraftTypesState = (state) => state.aircraftTypes;

export const getAircraftTypes = createSelector(getAircraftTypesState, (state) =>
  state.map((at) => at.value)
);

export const getAircraftTypesOptions = createSelector(
  getAircraftTypesState,
  (state) => state
);

export const fetchAircraftTypes = () => (dispatch) => {
  return api
    .getAircraftTypes()
    .then((response) =>
      dispatch(aircraftTypesSlice.actions.fetchAircraftTypesSucceeded(response))
    )
    .catch((error) =>
      toast.error(`AircraftTypes fetch failed: ${error.message}`)
    );
};
