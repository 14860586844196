import React, { useMemo } from "react";

import { shallowEqual, useSelector } from "react-redux";

import {
  getAllSolutions,
  getRQCompleted,
  getRQError,
  getRQLoading,
  getRQSelectedSolution,
} from "slices/solutions/solutionsSlice";
import { getIsAdmin, getIsManager, getOrgCurrencyFormatter } from "slices/userSlice";
import { getColumns } from "./columns";

import { Alert } from "react-bootstrap";
import { SolutionsNav } from "./components/SolutionsNav";
import { SolutionsTable } from "./components/SolutionsTable";
import { useHistory } from "react-router";
import { deepCompareEquals } from "utils";

export function RecoveryQueryTable({
  marked_solution_uid,
  onMarkSolution,
  deleteSolution,
  query_uid,
  base_scenario_uid,
  onSolutionUploadSuccess,
  show_d1,
  solution_uid,
}) {
  const all_solutions = useSelector(getAllSolutions, shallowEqual);
  const currencyFormatter = useSelector(
    getOrgCurrencyFormatter,
    deepCompareEquals
  );
  const isAdmin = !!useSelector(getIsAdmin, shallowEqual);
  const isManager = !!useSelector(getIsManager, shallowEqual);
  const selected_solution = useSelector(getRQSelectedSolution, shallowEqual);
  const loading = useSelector(getRQLoading, shallowEqual);
  const error = useSelector(getRQError, shallowEqual);
  const completed = useSelector(getRQCompleted, shallowEqual);
  let history = useHistory();

  const columns = useMemo(
    () =>
      getColumns(
        isAdmin,
        marked_solution_uid,
        onMarkSolution,
        deleteSolution,
        currencyFormatter
      ),
    [
      isAdmin,
      marked_solution_uid,
      onMarkSolution,
      deleteSolution,
      currencyFormatter,
    ]
  );

  const setSelectedSolutionUid = (solution_uid) =>
    history.push(`/recover/${query_uid}/${solution_uid}`);

  let solutions = all_solutions;
  if (!isAdmin) {
    solutions = solutions.filter(
      (solution) => !solution.is_redundant
    );
  }
  if (!isManager && !isAdmin) {
    solutions = solutions.filter(
      (solution) => !solution.is_private
    );
  }

  return (
    <>
      <SolutionsNav
        loading={loading}
        completed={completed}
        error={error}
        query_uid={query_uid}
        base_scenario_uid={base_scenario_uid}
        onSolutionUploadSuccess={onSolutionUploadSuccess}
        showAddSolution={isAdmin}
        show_legend={isAdmin}
        solution_uid={solution_uid}
      />
      <SolutionsTable
        solutions={solutions}
        columns={columns}
        selected_uid={selected_solution?.plan_uid}
        loading={loading}
        setSelectedSolutionUid={setSelectedSolutionUid}
        show_d1={show_d1}
      />
      <Alert variant="warning" show={!!selected_solution?.Warnings}>
        <strong>
          D0: WARNINGS for solution{" "}
          {selected_solution?.display_id || selected_solution?.id}:{" "}
        </strong>
        <ul>
          {selected_solution?.Warnings?.split("\n").map((warning) => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
      </Alert>
      <Alert variant="warning" show={!!selected_solution?.d1?.Warnings}>
        <strong>
          D1: WARNINGS for solution{" "}
          {selected_solution?.display_id || selected_solution?.id}:{" "}
        </strong>
        <ul>
          {selected_solution?.d1?.Warnings?.split("\n").map((warning) => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
      </Alert>
    </>
  );
}
