import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, Jumbotron, Row } from 'react-bootstrap';

import { Users } from "api";

import styles from "./index.module.css";

export const Reset = ({ match }) => {
  const [password, setPassord] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const dispatch = useDispatch();

  const submit = event => {
    event.preventDefault();
    event.stopPropagation();
    const token = match.params.token;
    if (password === confirm_password) {
      Users.resetPassword(token, password)
        .then(() => {
          dispatch(push("/login"));
          toast.success("Reset successful");
        })
        .catch((error) => {
          toast.error(`Password error: ${error.message}`);
        });
    } else {
      toast.error('Passwords should match');
    }
  };

  return (
    <Container fluid className={styles.Wrapper}>
      <Row>
        <Col>
          <Jumbotron className={styles.Center}>
            <Form onSubmit={submit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  onChange={event => setPassord(event.target.value)}
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  onChange={event => setConfirmPassword(event.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Reset password
              </Button>
            </Form>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};
