import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { QueuesRequest } from "api/resources/QueuesRequest";

export const fetchStats = createAsyncThunk(
  "queues/pullStats",
  async () => {
    try {
      const request = new QueuesRequest();
      return await request.get_stats();
    } catch(error) {
      toast.error(`Fetch Queues Stats failed: ${error.message}`);
    }
  }
);

export const fetchQueuedJobs = createAsyncThunk(
  "queues/pullQueuedJobs",
  async ({ queue_name })  => {
    try {
      const request = new QueuesRequest();
      return await request.get_queued_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Queued Jobs failed: ${error.message}`);
    }
  }
);

export const fetchCanceledJobs = createAsyncThunk(
  "queues/pullCanceledJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_canceled_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Canceled Jobs failed: ${error.message}`);
    }
  }
);

export const fetchDeferredJobs = createAsyncThunk(
  "queues/pullDeferredJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_deferred_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Deferred Jobs failed: ${error.message}`);
    }
  }
);

export const fetchFailedJobs = createAsyncThunk(
  "queues/pullFailedJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_failed_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Failed Jobs failed: ${error.message}`);
    }
  }
);

export const fetchFinishedJobs = createAsyncThunk(
  "queues/pullFinishedJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_finished_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Finished Jobs failed: ${error.message}`);
    }
  }
);

export const fetchScheduledJobs = createAsyncThunk(
  "queues/pullScheduledJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_scheduled_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Scheduled Jobs failed: ${error.message}`);
    }
  }
);

export const fetchStartedJobs = createAsyncThunk(
  "queues/pullStartedJobs",
  async ({ queue_name }) => {
    try {
      const request = new QueuesRequest();
      return await request.get_started_jobs(
        queue_name
      );
    } catch(error) {
      toast.error(`Fetch Started Jobs failed: ${error.message}`);
    }
  }
);

export const fetchJobDetails = createAsyncThunk(
  "queues/pullJobDetails",
  async ({ queue_name, job_id }) => {
    if (queue_name === '' || job_id === '') return;
    try {
      const request = new QueuesRequest();
      return await request.get_job_details(
        queue_name,
        job_id
      );
    } catch(error) {
      toast.error(`Fetch Job Details failed: ${error.message}`);
    }
  }
);

export const cancelJob = createAsyncThunk(
  "queues/pullCancelJob",
  async ({ queue_name, job_id }) => {
    try {
      const request = new QueuesRequest();
      return await request.cancel_job(
        queue_name,
        job_id
      );
    } catch(error) {
      toast.error(`Cancel Job failed: ${error.message}`);
    }
  }
);

export const requeueJob = createAsyncThunk(
  "queues/pullRequeueJob",
  async ({ queue_name, job_id }) => {
    try {
      const request = new QueuesRequest();
      return await request.requeue_job(
        queue_name,
        job_id
      );
    } catch(error) {
      toast.error(`Requeue Job failed: ${error.message}`);
    }
  }
);
