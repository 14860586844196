import { combineReducers } from "@reduxjs/toolkit";

import { slice as periodSlice } from './period';
import { slice as crewTargetSlice } from './crew';
import { slice as aircraftTargetSlice } from './aircraft';

export const targetReducer = combineReducers({
  period: periodSlice.reducer,
  crew_target: crewTargetSlice.reducer,
  aircraft_target: aircraftTargetSlice.reducer
});
