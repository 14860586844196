import React from "react";

export const Help = () => (
  <iframe
    title="occam-help-docs"
    src="https://big-blue-analytics.helpscoutdocs.com"
    frameBorder="0"
    style={{ height: "calc(-8px + 100vh)", width: "100%", marginTop: "-54px" }}
    allowFullScreen={true}
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
  ></iframe>
);
