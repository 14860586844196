import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const stationsSlice = createSlice({
  name: "stations",
  initialState: [],
  reducers: {
    fetchStationsSucceeded: (state, action) => {
      return action.payload;
    },
  },
});

stationsSlice.selectors = {
  getStations: (state) => state.stations,
};

export default stationsSlice;

export const getStations = (state) => state.stations;
export const getStationMapping = createSelector(getStations, (state) => {
  const mapping = {};
  state.forEach((station) => {
    mapping[station.value] = station.label;
  });
  return mapping;
});

export const fetchStations = () => (dispatch) => {
  return api
    .getStations()
    .then(({ stations }) =>
      dispatch(stationsSlice.actions.fetchStationsSucceeded(stations))
    )
    .catch((error) => toast.error(`Stations fetch failed: ${error.message}`));
};
