import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const aircraftsSlice = createSlice({
  name: "aircrafts",
  initialState: [],
  reducers: {
    fetchAircraftsSucceeded: (
      state,
      { payload: { aircrafts, aircrafts_labels } }
    ) => {
      return aircrafts.map((aircraft, index) => ({
        value: aircraft,
        label: aircrafts_labels[index],
      }));
    },
  },
});

aircraftsSlice.selectors = {
  getAircrafts: (state) => state.aircrafts,
};

export default aircraftsSlice;

export const getAircrafts = (state) => state.aircrafts;
export const getAircraftMapping = createSelector(getAircrafts, (state) => {
  const mapping = {};
  state.forEach((aircraft) => {
    mapping[aircraft.value] = aircraft.label;
  });
  return mapping;
});

export const fetchAircrafts = () => (dispatch) => {
  return api
    .getAircrafts()
    .then((result) =>
      dispatch(aircraftsSlice.actions.fetchAircraftsSucceeded(result))
    )
    .catch((error) => toast.error(`Aircrafts fetch failed ${error.message}`));
};
