import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Button, Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import Octicon from "react-octicon";
import posthog from "posthog-js";

import { TimelineRequest } from "api";
import {
  range,
  sleep,
  useDeepCompareCallback,
  useDeepCompareEffect,
  deepMemo,
} from "utils";

import classNames from "classnames/bind";
import styles from "./index.module.css";
import { toast } from "react-toastify";
import DatePickerUTC from "components/common/DatePickerUTC";

let cx = classNames.bind(styles);

const colors = {
  Missing: "#e15759",
  Current: "#59a14f",
  Future: "#808080",
  Past: "#3157a3",
};

const computeTimeColor = (now, time, timeline_reference) =>
  now - time < 0
    ? colors.Future
    : timeline_reference?.indexOf(
      time.toISO({
        suppressMilliseconds: true,
        includeOffset: false,
      })
    ) !== -1
      ? colors.Past
      : colors.Missing;

class TimelinePulling {
  cancelables = [];
  subscribe(reference_date, cb) {
    const request = new TimelineRequest();
    this.cancelables.push(request);
    const pullTimer = async () => {
      try {
        const response = await request.get({
          reference_date: reference_date.toISO({ includeOffset: false }),
        });
        if (this.killAll) return;
        cb(response);
      } catch {
        return;
      }
      await sleep(1000 * 60 * 2); // 2 minut interval refresh
      this.subscribe(reference_date, cb);
    };
    pullTimer();
  }
  unsubscribe() {
    this.killAll = true;
    this.cancelables.forEach((controller) => {
      controller.abort();
    });
    this.cancelables = [];
  }
}

export const TimeSelector = deepMemo(
  ({ reference_time, onSelect, onRefresh }) => {
    const reference_time_element = useRef(null);
    const [timeline_data, setTimelineData] = useState([]);
    const [scope, setScope] = useState(0);
    const [loading, setLoading] = useState(false);
    const current_index = reference_time?.toFormat("yyyy/MM/dd HH:mm");
    const [reference_date, setReferenceDate] = useState(reference_time);

    const formatTimelineData = useDeepCompareCallback(
      (scope_start, scope_end, timeline_reference = []) => {
        let start = DateTime.fromISO(scope_start, {
          zone: "utc",
        });
        let end = DateTime.fromISO(scope_end, {
          zone: "utc",
        });
        const { minutes } = end.diff(start, "minutes").toObject();
        setScope(minutes);
        const now = DateTime.utc();
        return range(0, minutes, 1).map((increment, _) => {
          const time = start.plus({ minutes: increment });
          return {
            time,
            id: time.toFormat("yyyy/MM/dd HH:mm"),
            isHour: time.minute === 0,
            label: time.toFormat("HH:mm"),
            isFuture: now - time < 0,
            color: computeTimeColor(now, time, timeline_reference),
          };
        });
      },
      [reference_date]
    );

    useDeepCompareEffect(() => {
      const puller = new TimelinePulling();
      if (reference_date) {
        setLoading(true);
        puller.subscribe(reference_date, (response) => {
          setTimelineData(
            formatTimelineData(
              response?.scope_start,
              response?.scope_end,
              response?.timeline
            )
          );
          setLoading(false);
        });
      }
      return () => puller.unsubscribe();
    }, [reference_date, formatTimelineData]);

    useEffect(() => {
      setReferenceDate(reference_time);
      ReactTooltip.rebuild();
    }, [reference_time]);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [timeline_data]);

    const onReferenceDateChange = (date) => {
      const new_reference_time = DateTime.fromJSDate(date, { zone: "utc" }).set(
        {
          hours: reference_time.hour,
          minutes: reference_time.minute,
          seconds: 0,
          milliseconds: 0,
        }
      );
      const now = DateTime.utc().set({ second: 0, millisecond: 0 });
      onSelect(new_reference_time > now ? now : new_reference_time);
      posthog.capture('builder_timeselector_datechange')
      ReactTooltip.hide(reference_time_element.current);
    };

    const onTimeChange = (timeline_point) => {
      if (timeline_point.isFuture) {
        toast.warning("Future moments in time are not available for selection");
        return;
      }
      onSelect(timeline_point.time);
    };
    return (
      <div className={styles.Form}>
        <Row className={styles.Row}>
          <Col md={1} className={cx(styles.Col, styles.RefreshButton)}>
            <Button onClick={() => onRefresh(DateTime.utc())}>
              <Octicon name="sync" />
            </Button>
          </Col>
          <Col md={2} className={cx(styles.Col, styles.DateCol)}>
            <DatePickerUTC
              className={cx("form-control", styles.DatePicker)}
              selected={reference_date}
              onChange={onReferenceDateChange}
              popperPlacement="top-start"
              dateFormat="dd/MM/yyyy HH:mm"
              maxDate={new Date()}
              minDate={DateTime.fromFormat(
                "2020/07/27 10:00",
                "yyyy/MM/dd HH:mm"
              ).toJSDate()} // Manually blocking all past plans
            />
          </Col>
          <Col className={styles.Col}>
            <div className={styles.Wrapper}>
              {loading ? <span className={styles.Loading}></span> : <span />}
              {timeline_data.map((point) => (
                <span
                  key={point.id}
                  style={{
                    width: `${100 / scope}%`,
                    backgroundColor: point.color,
                  }}
                  data-tip={point.label}
                  data-effect="solid"
                  ref={
                    (current_index === point.id && reference_time_element) ||
                    undefined
                  }
                  data-place="bottom"
                  data-background-color={
                    current_index === point.id ? "#59a14f" : point.color
                  }
                  data-border-color={
                    current_index === point.id ? "#59a14f" : point.color
                  }
                  data-delay-hide={current_index === point.id ? 999999 : 0}
                  className={cx(point.class, styles.Point, {
                    [styles.Hour]: point.isHour,
                    [styles.CurrentPoint]: current_index === point.id,
                  })}
                  onClick={() => onTimeChange(point)}
                >
                  {point.isHour && (
                    <span className={styles.Label}>{point.label}</span>
                  )}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
);
