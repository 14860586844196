import React from "react";
import DatePicker from "react-datepicker";

export default function DatePickerUTC({ selected, ...props }) {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const apiTZ = "utc";

  return (
    <DatePicker
      selected={selected
        ?.setZone(apiTZ)
        .setZone(localTZ, { keepLocalTime: true })
        .toJSDate()}
      {...props}
    />
  );
}
