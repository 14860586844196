import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import classNames from "classnames/bind";

import { Button, ButtonGroup, Col, Form, InputGroup } from "react-bootstrap";

import { DateTimeRange } from "components/common/DateTimeRange";
import Octicon from "react-octicon";
import Select, { createFilter } from "react-select";
import { getDurationFromISO, optimizeSelect } from "../utils";
import { DisplayAogs } from "./table";
import { deepMemo, randomId, useDeepCompareEffect } from "utils";
import { getIsAdmin } from "slices/userSlice";
import styles from "./index.module.css";
import { toast } from "react-toastify";
import { ScenarioRequest } from "api";

let cx = classNames.bind(styles);

export { DisplayAogs } from "./table";

export const AOGManager = deepMemo(
  ({
    aogs,
    reference_time,
    invalid_aogs,
    base_scenario_uid,
    onUpdate,
    scenario_stations,
    scenario_aircraft,
    aircraft_mapping,
    stations_mapping,
  }) => {
    const [start, setStart] = useState(reference_time);
    const [end, setEnd] = useState(reference_time);
    const [aircraft, setAircraft] = useState(null);
    const [station, setStation] = useState(null);
    const isAdmin = useSelector(getIsAdmin, shallowEqual)

    const show_bulk = isAdmin;

    useDeepCompareEffect(() => {
      setStart(reference_time);
      setEnd(reference_time);
    }, [reference_time]);

    const resetForm = () => {
      setAircraft(null);
      setStation(null);
      setStart(reference_time);
      setEnd(reference_time);
    };

    const isFormValid = () => {
      return !(!aircraft || !start.isValid || !end.isValid || end < start);
    };

    const onCreateAog = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isFormValid()) {
        toast.warning("WARNING: Select and aircraft and a valid duration.");
        return;
      }
      onUpdate([
        ...aogs,
        {
          id: randomId(),
          aircraft_id: aircraft.value,
          station_id: station?.value,
          start: start.toISO({ includeOffset: false }),
          end: end.toISO({ includeOffset: false }),
        },
      ]);
      resetForm();
    };

    const onAddIdleAogs = () => {
      const idle_request = new ScenarioRequest();
      const getScenarioIdleAogs = async () => {
        const response = await idle_request.get_idle_aircraft_aogs(
          base_scenario_uid
        );
        if (!!response?.aogs?.length)
          onUpdate([
            ...aogs,
            ...response?.aogs.map((aog) => ({ ...aog, id: randomId() })),
          ]);
      };
      getScenarioIdleAogs();
    };

    return (
      <React.Fragment>
        <Form onSubmit={onCreateAog} className={styles.Form}>
          <Form.Row>
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Aircrafts</InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                classNamePrefix="Select"
                value={aircraft}
                className={cx(styles.Selector, "form-control")}
                options={scenario_aircraft}
                onChange={setAircraft}
              />
            </InputGroup>
            <InputGroup as={Col} md={4}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Station</InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                classNamePrefix="Select"
                value={station}
                className={cx(styles.Selector, "form-control")}
                options={scenario_stations}
                onChange={setStation}
                filterOption={createFilter({ ignoreAccents: false })}
                components={optimizeSelect.components}
              />
            </InputGroup>
            <DateTimeRange
              reference_time={reference_time}
              onUpdate={(_start, _end) => {
                setStart(_start);
                setEnd(_end);
              }}
            />
            <InputGroup as={Col}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Duration</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                readOnly
                value={getDurationFromISO(start, end)}
                onChange={() => { }}
              />
            </InputGroup>
            <Form.Group
              as={Col}
              className={cx(styles.ButtonWrapper, "text-center")}
            >
              <ButtonGroup>
                <Button variant="info" type="submit" className={styles.Button}>
                  <Octicon name="plus" />
                </Button>
                {show_bulk && !!base_scenario_uid && (
                  <Button
                    variant="warning"
                    onClick={onAddIdleAogs}
                    className={styles.Button}
                  >
                    AOGs
                  </Button>
                )}
              </ButtonGroup>
            </Form.Group>
          </Form.Row>
        </Form>
        <DisplayAogs
          invalid_aogs={invalid_aogs}
          aogs={aogs}
          onUpdate={onUpdate}
          aircraft_mapping={aircraft_mapping}
          stations_mapping={stations_mapping}
        />
      </React.Fragment>
    );
  }
);
