import { authorizedRequest, httpMethods } from "../utils";

export const CrewTargets = {
  list: () => authorizedRequest("/crew_target", httpMethods.GET),
  create: (crew_target) =>
    authorizedRequest(`/crew_target`, httpMethods.POST, crew_target),
  update: (id, crew_target) =>
    authorizedRequest(`/crew_target/${id}`, httpMethods.PATCH, crew_target),
  delete: (id, force) =>
    authorizedRequest(`/crew_target/${id}`, httpMethods.DELETE),
};
