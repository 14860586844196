import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { Roles } from "api";

const rolesSlice = createSlice({
  name: 'roles',
  initialState: [],
  reducers: {
    fetchRolesSucceeded: (state, action) => action.payload.map(role => ({ value: role.id, label: role.name, role }))
  }
});

rolesSlice.selectors = {
  getRoles: state => state.roles
};

export default rolesSlice;

export const fetchRoles = () => dispatch => {
  return Roles.list()
    .then(({ roles }) =>
      dispatch(rolesSlice.actions.fetchRolesSucceeded(roles))
    )
    .catch((error) => toast.error(`Roles fetch failed: ${error.message}`));
};
