import React, { useState } from "react";
import { Button, Col, Table, Modal, Row } from "react-bootstrap";
import { kpiLegend } from "../utils";

export const KpiLegend = ({ as = Button }) => {
  const Component = as;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Component variant="primary" onClick={handleShow}>
        KPI Legend
      </Component>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>KPI Descriptions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>KPI</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(kpiLegend).map((kpi) => {
                    return (
                      <tr key={kpi}>
                        <td>{kpi}</td>
                        <td>{kpiLegend[kpi]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
