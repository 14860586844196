import React from "react";
import ReactTable from "react-table-6";

export const StatsTable = ({
  title = "",
  columns,
  data = [],
  defaultSorted = [],
  minRows = 1,
}) =>
  data.length || minRows > 1 ? (
    <div className="m-3">
      <h4>{title}</h4>
      <ReactTable
        style={{ marginBottom: "20px" }}
        multiSort
        data={data}
        columns={columns}
        minRows={minRows}
        resizable={false}
        defaultPageSize={5}
        defaultSorted={defaultSorted}
      />
    </div>
  ) : (
    <></>
  );
