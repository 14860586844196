import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, ButtonGroup, Dropdown, DropdownButton, Form, Nav } from "react-bootstrap";

import styles from "./index.module.css";
let cx = classNames.bind(styles);

export const RecoverNav = ({
  onRecoverWithUpdate,
  onRecoverWithoutUpdate,
  show_solve_acmis,
  is_tt_active,
}) => {
  const [prevent_refresh, setPreventRefresh] = useState(false);

  useEffect(() => {
    setPreventRefresh(is_tt_active);
  }, [is_tt_active]);

  const onRecover = (solve_acmis = false) => {
    if (prevent_refresh) {
      onRecoverWithoutUpdate({ solve_acmis });
    } else {
      onRecoverWithUpdate({ solve_acmis });
    }
  };

  return (
    <Nav>
      <Nav.Item>
        <ButtonGroup>
          <Button
            size="lg"
            className={cx(styles.SubmitButtons, styles.Recover)}
            onClick={() => onRecover()}
          >
            Recover
          </Button>
          {show_solve_acmis && <DropdownButton as={ButtonGroup} title="" alignRight>
            <Dropdown.Item onClick={() => onRecover(true)}>Solve with ACMIs</Dropdown.Item>
          </DropdownButton>}
        </ButtonGroup>
      </Nav.Item>
      <Nav.Item>
        <Form.Check
          type="checkbox"
          style={{ lineHeight: "48px", marginLeft: "10px" }}
        >
          <Form.Check.Input
            style={{
              width: "20px",
              position: "relative",
            }}
            type="checkbox"
            isValid
            checked={prevent_refresh}
            onChange={(e) => setPreventRefresh(e.target.checked)}
          />
          <Form.Check.Label
            style={{
              color: "white",
            }}
          >{`Prevent refresh before recover`}</Form.Check.Label>
        </Form.Check>
      </Nav.Item>
    </Nav>
  );
};
