import React, { useEffect, useState } from "react";
import { Card, Nav, Navbar } from "react-bootstrap";
import { LogoSpinner } from "components/common/Loader";

export const DetailSection = ({
  title,
  metrics,
  content,
  tables,
  loading,
  default_show_table = false,
}) => {
  const [show_table, setShowTable] = useState(default_show_table);

  useEffect(() => {
    setShowTable(default_show_table);
  }, [default_show_table]);

  return (
    <>
      <Navbar bg="light-grey" variant="dark" className="trim-navbar">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Nav>
          <Nav.Item>
            <Nav.Link onClick={() => setShowTable((prev) => !prev)}>
              {show_table ? "Hide tables" : "Show tables"}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
      <MetricCards metrics={metrics} content={content} loading={loading} />
      {show_table && <div className="mt-3 mb-3">{tables}</div>}
    </>
  );
};

const metricAccesor = (content, accessor) => {
  if (typeof accessor === "function") return accessor(content);
  return content[accessor];
};

export const MetricCards = ({ metrics, content, loading }) => (
  <div style={{ display: "flex", flexWrap: "nowrap" }}>
    {metrics.map((metric) => {
      const metric_value = metricAccesor(content, metric.accessor);
      const has_metric_value =
        metric_value !== undefined && metric_value !== null;
      const metric_kpi = has_metric_value
        ? `${metric_value} ${metric.units}`
        : "-";
      return (
        metric.show && (
          <Card key={metric.name} style={{ borderRadius: "none", flexGrow: 1 }}>
            <Card.Header as="h5">{metric.name}</Card.Header>
            <Card.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "none",
              }}
            >
              <Card.Title as="h3">
                <LogoSpinner show={loading} />
                {!loading && metric_kpi}
              </Card.Title>
            </Card.Body>
          </Card>
        )
      );
    })}
  </div>
);
