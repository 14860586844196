import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const flightsSlice = createSlice({
  name: "flights",
  initialState: [],
  reducers: {
    fetchFlightsSucceeded: (
      state,
      { payload: { flights, flights_labels } }
    ) => {
      return flights.map((flight, index) => ({
        value: flight,
        label: flights_labels[index],
      }));
    },
  },
});

flightsSlice.selectors = {
  getFlights: (state) => state.flights,
};

export default flightsSlice;

export const getFlights = (state) => state.flights;

export const fetchFlights = () => (dispatch) => {
  return api
    .getFlights()
    .then((result) =>
      dispatch(flightsSlice.actions.fetchFlightsSucceeded(result))
    )
    .catch((error) => toast.error(`Flights fetch failed ${error.message}`));
};
