import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import keyBy from "lodash/keyBy";

import * as api from "api";

const crewsSlice = createSlice({
  name: "crews",
  initialState: [],
  reducers: {
    fetchCrewsSucceeded: (_, action) => {
      return action.payload;
    },
  },
});

crewsSlice.selectors = {
  getCrews: (state) => state.crews,
  getCrewMapping: createSelector(
    (state) => state.crews,
    (state) => keyBy(state, "value")
  ),
};

export default crewsSlice;

export const getCrews = (state) => state.crews;

export const fetchCrews = () => (dispatch) => {
  return api
    .getCrews()
    .then(({ crews }) =>
      dispatch(crewsSlice.actions.fetchCrewsSucceeded(crews))
    )
    .catch((error) => toast.error(`Crews fetch failed: ${error.message}`));
};
