import React, { useState } from "react";
import { SvgViewer } from "components/common/viewers/SvgViewer";
import { Loader } from "components/common/Loader";

import { ScenarioRequest } from "api";

import { StatsTable } from "../StatsNav/components/StatsTable";
import { columns_crew_problems } from "../StatsNav/components/columns";

import { Col, Container, Row } from "react-bootstrap";
import { first } from "lodash";
import { simpleCol, namedCol, hiddenCol, useDeepCompareEffect } from "utils";
import { pullCrewDutyInfo } from "slices/scenarios/scenariosSlice";
import { useDispatch } from "react-redux";
import { Table } from "components/common/Table";

const getCrewMemberLabel = (crew_member) =>
  `${crew_member.CrewName} (${crew_member.CrewID}) ${
    (crew_member.IsPax && "DH") || ""
  } ${crew_member.TrainingPosition || ""}`;

export const FlightCrew = ({
  flight,
  scenario_uid,
  base_scenario_uid,
  show,
  crews,
  crew_problems,
  loading,
}) => {
  const [fdp_image, setFDPImage] = useState({});
  const [fdp_original_image, setFDPOriginalImage] = useState({});
  const [loading_image, setLoading] = useState(false);
  const [selected_crew_member, setSelectedCrewMember] = useState(
    first(crews) || {}
  );

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    const request_scenario = new ScenarioRequest();
    const request_base_scenario = new ScenarioRequest();
    const fetchFDPImages = async () => {
      setLoading(true);
      try {
        const response = await request_scenario.get_crew_duty_image(
          scenario_uid,
          selected_crew_member?.CrewID,
          { scope: "full", base_scenario_uid }
        );
        setFDPImage((prev) => ({
          ...prev,
          [selected_crew_member?.CrewID]: response,
        }));
      } catch (err) {
        console.warn(`Duty image: ${err.message}`);
        setFDPImage((prev) => ({
          ...prev,
          [selected_crew_member?.CrewID]:
            "<center><h3>No flight related activities.</h3></center>",
        }));
      } finally {
        setLoading(false);
      }
      if (base_scenario_uid && base_scenario_uid !== scenario_uid) {
        try {
          const response_original =
            await request_base_scenario.get_crew_duty_image(
              base_scenario_uid,
              selected_crew_member?.CrewID,
              { scope: "full", base_scenario_uid }
            );
          setFDPOriginalImage((prev) => ({
            ...prev,
            [selected_crew_member?.CrewID]: response_original,
          }));
        } catch (err) {
          console.warn(`Duty image: ${err.message}`);
          setFDPOriginalImage((prev) => ({
            ...prev,
            [selected_crew_member?.CrewID]:
              "<center><h3>No flight related activities.</h3></center>",
          }));
        } finally {
          setLoading(false);
        }
      }
    };
    if (scenario_uid && selected_crew_member?.CrewID) {
      fetchFDPImages();
    }
  }, [selected_crew_member, flight, scenario_uid]);

  useDeepCompareEffect(() => {
    dispatch(pullCrewDutyInfo(scenario_uid, flight, base_scenario_uid));
  }, [dispatch, flight, scenario_uid]);

  const bgLookup = (row) => {
    if (row.IsPax) return "grey";
    return "";
  };
  const columns = React.useMemo(
    () => [
      hiddenCol("IsPax", 0),
      hiddenCol("CrewID", 0),
      namedCol("CrewName", (row) => getCrewMemberLabel(row), "CrewName", 400),
      simpleCol("Role", 60),
      simpleCol("FDPStart", 180),
      simpleCol("FDPEnd", 180),
      simpleCol("FDPDuration", 120),
      simpleCol("FDPAllowance", 120),
      simpleCol("FDPMaxEnd", 180),
    ],
    []
  );

  return (
    <Container fluid style={{ display: show ? "block" : "none" }}>
      <Row>
        <Col>
          <h4>Crew details</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            data={crews}
            resizable={false}
            style={{ marginBottom: "20px" }}
            columns={columns}
            minRows={3}
            loading={loading}
            getTrProps={(row) => ({
              style: {
                cursor: "pointer",
                backgroundColor: bgLookup(row.values || {}),
                border:
                  row?.values?.CrewID === selected_crew_member.CrewID
                    ? "3px blue solid"
                    : "",
              },
              onClick: (e, handleOriginal) => {
                setSelectedCrewMember(row.original);
              },
            })}
          />
        </Col>
      </Row>
      {selected_crew_member?.CrewName && (
        <Row>
          <Col>
            <StatsTable
              title="Crew Warnings"
              data={crew_problems?.filter(
                (cm) => cm.CrewID === selected_crew_member.CrewID
              )}
              columns={columns_crew_problems}
            />
          </Col>
        </Row>
      )}
      {selected_crew_member?.CrewName && (
        <Row>
          <Col>
            <h4>{selected_crew_member?.CrewName}</h4>
            <div style={{ position: "relative", minHeight: "200px" }}>
              <Loader show={loading_image} />
              {fdp_image && (
                <>
                  <h5>Duty</h5>
                  <SvgViewer
                    show_zoom_selector
                    svg={fdp_image[selected_crew_member?.CrewID]}
                  />
                </>
              )}
              {base_scenario_uid &&
                base_scenario_uid !== scenario_uid &&
                fdp_original_image && (
                  <>
                    <h5>Original Duty</h5>
                    <SvgViewer
                      show_zoom_selector
                      svg={fdp_original_image[selected_crew_member?.CrewID]}
                    />
                  </>
                )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};
