import React from "react";
import moment from "moment";
import { matchSorter } from "match-sorter";

import { simpleCol, titleRow } from "utils";

const baseFormat = "YYYY/MM/DD HH:mm";

const dateCellRender = (row) => (
  <div>
    <span title={moment(row.value).format(baseFormat)}>
      {moment(row.value).format(baseFormat)}
    </span>
  </div>
);

export const standby_columns = (roleSorting) => [
  {
    Header: "CrewMemberLabel",
    id: "CrewMemberLabel",
    accessor: "CrewMemberLabel",
    maxWidth: 220,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["CrewMemberLabel"] }),
    filterAll: true,
  },
  {
    Header: "Role",
    id: "Role",
    accessor: "Role",
    maxWidth: 120,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Role"] }),
    filterAll: true,
    sortMethod: roleSorting
  },
  {
    Header: "Qualifications",
    id: "Qualifications",
    accessor: (d) => d.Qualifications.toString(),
    maxWidth: 120,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Qualifications"] }),
    filterAll: true,
  },
  {
    Header: "Station",
    id: "Station",
    accessor: (d) => d.Station,
    maxWidth: 120,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Station"] }),
    filterAll: true,
  },
  {
    Header: "Code",
    id: "Code",
    accessor: (d) => d.Code,
    maxWidth: 120,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Code"] }),
    filterAll: true,
  },
  {
    Header: "Categories",
    id: "Categories",
    accessor: (d) => d.Categories.toString(),
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Categories"] }),
    filterAll: true,
  },
  {
    Header: "Type",
    id: "Type",
    accessor: "Type",
    maxWidth: 220,
    style: { textAlign: "center" },
    Cell: titleRow,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, { keys: ["Type"] }),
    filterAll: true,
  },
  {
    Header: "Start",
    accessor: "Start",
    maxWidth: 200,
    style: { textAlign: "center" },
    Cell: dateCellRender,
  },
  {
    Header: "End",
    accessor: "End",
    maxWidth: 200,
    style: { textAlign: "center" },
    Cell: dateCellRender,
  },
];

export const columns_infeasible_aircrafts = [
  simpleCol("Type"),
  simpleCol("Aircraft", 200),
  simpleCol("Activity"),
  simpleCol("Notes"),
];

export const columns_infeasible_curfews = [
  simpleCol("Type"),
  simpleCol("Aircraft", 200),
  simpleCol("Activity"),
  simpleCol("Location"),
];

export const columns_infeasible_crews = [
  simpleCol("Type"),
  simpleCol("Crew"),
  simpleCol("Activity"),
  simpleCol("Aircraft", 200),
  simpleCol("Notes"),
];

export const columns_crew_problems = [
  simpleCol("Type"),
  simpleCol("Activity"),
  simpleCol("Crew"),
  simpleCol("Location"),
  simpleCol("Route"),
  simpleCol("Notes"),
];

export const columns_flight_problems = [
  simpleCol("Type"),
  simpleCol("Crew"),
  simpleCol("Aircraft", 200),
  simpleCol("Location"),
  simpleCol("Route"),
  simpleCol("Notes"),
];

export const columns_pax_problems = [
  simpleCol("Type"),
  simpleCol("Activity", 120),
  simpleCol("Route"),
  simpleCol("Notes"),
];

export const columns_memos = [simpleCol("Memo", undefined, "left")];

const printRoles = (roles) => {
  const roles_list = [];
  for (let role in roles) {
    roles_list.push(`${role}: ${roles[role]}`);
  }
  return roles_list.join(", ");
};

export const columns_uncrew_duties = [
  simpleCol("Flights"),
  {
    Header: "Missing roles",
    id: "MissingRoles",
    accessor: (d) => printRoles(d.MissingRoles),
    style: { textAlign: "center" },
    Cell: titleRow,
  },
];

export const columns_aircraft_balance = [
  simpleCol("Station", 200),
  simpleCol("Type"),
  simpleCol("Balance"),
  simpleCol("RequiredTime"),
];

export const columns_infeasible_d1crews = [
  simpleCol("Type"),
  simpleCol("Crew"),
  simpleCol("Notes"),
];

export const flight_columns = [
  simpleCol("FlightNumber"),
  simpleCol("TailNumber"),
  simpleCol("ETD"),
  simpleCol("Delay"),
  simpleCol("Route"),
];

export const obk_columns = [
  simpleCol("FlightNumber"),
  simpleCol("Route"),
  simpleCol("OverbookedPassengers"),
  simpleCol("SeatClass"),
  simpleCol("NoShow"),
];
