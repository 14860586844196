import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { RecoveryQuery } from "api";

import { Loader } from "components/common/Loader";

export const NextDayRedirect = ({ match: { params } }) => {
  const { query_uid, solution_uid } = params;
  let history = useHistory();

  useEffect(() => {
    if (!!query_uid) {
      RecoveryQuery.next_day(query_uid, solution_uid)
        .then((result) => {
          history.push(`/next_day/${result.uid}`);
        })
        .catch((error) => {
          toast.error(`Error: ${error.message}`);
          history.goBack();
        });
    }
  }, [query_uid, solution_uid, history]);

  return (
    <div>
      <Loader show full message="Preparing the next day plan ..." />
    </div>
  );
};
