import React from "react";
import { connect } from "react-redux";

import legendSlice from "slices/legendSlice";
import { SvgViewer } from "components/common/viewers/SvgViewer";

const LegendViewerComponent = ({ legend_svg }) => (
  <SvgViewer svg={legend_svg} />
);

const mapStateToProps = (state) => ({
  legend_svg: legendSlice.selectors.getLegend(state),
});

export const LegendViewer = connect(mapStateToProps)(LegendViewerComponent);
