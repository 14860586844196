import { authorizedRequest, httpMethods } from "../utils";

export const Users = {
  me: () => authorizedRequest("/user", httpMethods.GET),
  list: () => authorizedRequest("/users", httpMethods.GET),
  create: (user) => authorizedRequest(`/user`, httpMethods.POST, user),
  update: (user) => authorizedRequest(`/user`, httpMethods.PUT, user),
  delete: (email) => authorizedRequest(`/user`, httpMethods.DELETE, { email }),
  forgotPassword: (email) =>
    authorizedRequest("/reset", httpMethods.POST, { email }),
  resetPassword: (token, password) =>
    authorizedRequest(`/reset/${token}`, httpMethods.POST, { password }),
};

export const Roles = {
  list: () => authorizedRequest("/roles", httpMethods.GET),
  create: (name, description) =>
    authorizedRequest("/roles", httpMethods.POST, { name, description }),
};
