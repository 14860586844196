import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { hiddenCol, simpleCol } from "utils";

import { ScenarioRequest } from "api/resources/ScenarioRequest";

import { SvgViewer } from "components/common/viewers/SvgViewer";

import { BaseTable, colors } from "../BaseTable";

const pax_columns = [
  hiddenCol("IsStranded"),
  hiddenCol("ModifiedItineraries"),
  simpleCol("DisruptingFlight"),
  simpleCol("TotalPassengers"),
  simpleCol("MaxDays"),
];

const pax_sub_columns = [

  simpleCol("OriginalFlights"),
  simpleCol("OriginalSeatClass"),
  simpleCol("NewFlights"),
  simpleCol("NewSeatClasses"),
  simpleCol("DisruptingFlight"),
  simpleCol("Passengers"),
  hiddenCol("IsStranded"),
  simpleCol("Days"),

]

const paxBgLookup = ({ IsStranded }) => {
  if (IsStranded) return colors.Red;
  return "";
};

const pax_legend = [{ color: colors.Red, text: "Stranded" }];


export const PaxTables = ({
  solution_scenario_uid,
  base_scenario_uid,
  scope,
}) => {
  const [ovb_op, setOvbOP] = useState([]);
  const [ovb_ns, setOvbNS] = useState([]);
  const [cnl, setCnl] = useState([]);
  const [rsd, setRsd] = useState([]);
  const [mcxn, setMcxn] = useState([]);
  const [div, setDiv] = useState([]);
  const [idiv, setIdiv] = useState([]);
  const [ovb_image, setOvbImage] = useState([]);
  const [cnl_image, setCnlImage] = useState([]);
  const [mcxn_image, setMcxnImage] = useState([]);
  const [div_image, setDivImage] = useState([]);
  const [idiv_image, setIdivImage] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCrewData = async () => {
      setLoading(true);
      try {
        const request = new ScenarioRequest();
        const response = await request.get_pax_changes(
          solution_scenario_uid,
          base_scenario_uid,
          { scope }
        );
        setOvbOP(response.ovb_op);
        setOvbNS(response.ovb_ns);
        setCnl(response.cnl);
        setRsd(response.rsd);
        setMcxn(response.mcxn);
        setDiv(response.div);
        setIdiv(response.idiv);
        setOvbImage(response.ovb_image);
        setCnlImage(response.cnl_image);
        setMcxnImage(response.mcxn_image);
        setDivImage(response.div_image);
        setIdivImage(response.idiv_image);
      } catch (err) {
        toast.error(`Passengers fetch failed: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    if (!!base_scenario_uid && !!solution_scenario_uid) fetchCrewData();
  }, [base_scenario_uid, solution_scenario_uid, scope]);

  return (
    <Container fluid>
      <h3>Affected passengers</h3>
      <Row>
        {ovb_image && (
          <Col>
            <SvgViewer svg={ovb_image} />
          </Col>
        )}
        {cnl_image && (
          <Col>
            <SvgViewer svg={cnl_image} />
          </Col>
        )}
        {mcxn_image && (
          <Col>
            <SvgViewer svg={mcxn_image} />
          </Col>
        )}
        {div_image && (
          <Col>
            <SvgViewer svg={div_image} />
          </Col>
        )}
        {idiv_image && (
          <Col>
            <SvgViewer svg={idiv_image} />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <BaseTable
            title="Operative Overbooking"
            data={ovb_op}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Commercial Overbooking"
            data={ovb_ns}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Cancellations"
            data={cnl}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Rescheduled"
            data={rsd}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Missed Connections"
            data={mcxn}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Diversions"
            data={div}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
          <BaseTable
            title="Island Diversions"
            data={idiv}
            loading={loading}
            columns={pax_columns}
            bgLookup={paxBgLookup}
            defaultSorted={[{ id: "OriginalFlights", desc: false }]}
            legend={pax_legend}
            SubComponent={({ row }) => {
              return <BaseTable
                style={{ margin: 20 }}
                bgLookup={paxBgLookup}
                data={row.ModifiedItineraries}
                loading={loading}
                columns={pax_sub_columns} />
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
