import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { costCol, simpleCol } from "utils";

import { BaseTable } from "../BaseTable";
import { SolutionCostsTable } from "components/common/SolutionCostsTable";

export const GlobalTables = (
  { metrics, loading_metrics, currencyFormatter, base_scenario_uid, solution_uid }
) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <SolutionCostsTable
            data={metrics.Costs}
            loading={loading_metrics}
            currencyFormatter={currencyFormatter}
            base_scenario_uid={base_scenario_uid}
            solution_uid={solution_uid} />
          <BaseTable
            title="Curfews Extensions"
            data={metrics.CurfewsExtended}
            loading={loading_metrics}
            columns={[
              simpleCol("Station"),
              costCol("Cost", "Cost", "Cost", 150, currencyFormatter),
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};
