import { Loader } from "components/common/Loader";
import { merge } from "lodash";
import React from "react";

import { useTable, usePagination, useSortBy, useFlexLayout } from "react-table";

export const Table = ({
  data = [],
  style,
  columns,
  loading,
  getTrProps,
  defaultSorted,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 1000, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: {
        pageIndex: 0,
        defaultSorted,
      }, // Pass our hoisted table state
    },
    useFlexLayout,
    useSortBy,
    usePagination
  );
  const default_styles = {
    style: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      padding: "7px 5px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  };

  const gridStyle = "1px solid rgba(0,0,0,0.1)";
  return (
    <div
      className="ReactTable"
      style={{
        minHeight: "300px",
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSize: "16px",
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <Loader show={loading && !data.length} />
      <div
        style={{
          width: "100%",
          textAlign: "center",
          height: "100%",
          overflow: "auto",
        }}
      >
        <table {...getTableProps({ style: { width: "100%" } })}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers
                  .filter((column) => column.show !== false)
                  .map((column) => {
                    const border = column.isSorted
                      ? column.isSortedDesc
                        ? "borderTop"
                        : "borderBottom"
                      : "";
                    const column_props = column.getHeaderProps(
                      column.getSortByToggleProps(default_styles)
                    );
                    return (
                      <th
                        {...column_props}
                        style={{
                          ...(column.style || {}),
                          ...column_props.style,
                          borderRight: gridStyle,
                          borderTop: gridStyle,
                          borderBottom: gridStyle,
                          [border]: "3px solid rgba(0,0,0,0.3)",
                          fontWeight: 500,
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const row_props = getTrProps
                ? merge(row.getRowProps(), getTrProps(row))
                : row.getRowProps();
              return (
                <tr
                  {...row_props}
                  style={{
                    ...row_props.style,
                    cursor: "default",
                    userSelect: "none",
                  }}
                >
                  {row.cells
                    .filter((cell) => cell.column.show !== false)
                    .map((cell) => {
                      const cell_props = cell.getCellProps(default_styles);
                      return (
                        <td
                          {...cell_props}
                          style={{
                            ...cell_props.style,
                            borderRight: gridStyle,
                            overflow: "hidden",
                          }}
                        >
                          {cell.column.Cell(cell)}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <button
              className="-btn"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
          </div>
          <div className="-center">
            <span className="-pageInfo">
              Page{" "}
              <div className="-pageJump">
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </div>{" "}
              of <span className="-totalPages">{pageOptions.length}</span>
            </span>
            <span className="select-wrap -pageSizeOptions">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} rows
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className="-next">
            <button
              className="-btn"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
