import { LocalCacheGroup, RequestBuilder } from "../utils";

export class ScenarioRequest extends RequestBuilder {
  constructor() {
    super();
    this.cache_group = new LocalCacheGroup("ScenarioRequest");
    window._cache_group_ScenarioRequest = this.cache_group;
  }
  async create_scenario(body) {
    return this._post(`/scenario`, body);
  }
  async get(uid, params) {
    return this._get(`/scenario/${uid}`, params);
  }
  // Viz
  async get_curfews_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/curfews`, params);
  }
  async get_schedule_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/schedule`, params);
  }
  async get_schedule_diff_svg(uid, base, params) {
    return this._getSvg(`/scenario/${uid}/svg/schedule/${base}`, params);
  }
  async get_broken_schedule_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/broken_schedule`, params);
  }
  async get_roster_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/roster`, params);
  }
  async get_roster_diff_svg(uid, base, params) {
    return this._getSvg(`/scenario/${uid}/svg/roster/${base}`, params);
  }
  async get_broken_roster_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/broken_roster`, params);
  }
  async get_ruler_svg(uid, params) {
    return this._getSvg(`/scenario/${uid}/svg/ruler`, params);
  }
  async get_crew_duty_diff_svg(uid, base, crew_id, params) {
    return this._getSvg(`/scenario/${uid}/svg/${base}/crew/${crew_id}`, params);
  }
  // Resources
  async get_aircrafts(uid) {
    return this._get(`/scenario/${uid}/aircrafts`);
  }
  async get_aircraft_types(uid) {
    return this._get(`/scenario/${uid}/aircraft_types`);
  }
  async get_stations(uid) {
    return this._get(`/scenario/${uid}/stations`);
  }
  async get_idle_aircraft_aogs(uid) {
    return this._get(`/scenario/${uid}/idle_aircraft_aogs`);
  }
  async get_memos(uid, params) {
    return this._get(`/scenario/${uid}/memos`, params);
  }
  async get_flight_crews(uid, params) {
    return this._get(`/scenario/${uid}/flight_crews`, params);
  }
  // Augmented
  async get_standbys(uid, params) {
    return this._get(`/scenario/${uid}/standbys`, params);
  }
  async get_problems(uid, params) {
    return this._get(`/scenario/${uid}/problems`, params);
  }
  async get_warnings(uid, params) {
    return this._get(`/scenario/${uid}/warnings`, params);
  }
  async get_operational_cost(uid, params) {
    return this._get(`/scenario/${uid}/operational_cost`, params);
  }
  async get_crew_changes(uid, base, params) {
    return this._get(`/scenario/${uid}/changes/${base}/crews`, params);
  }
  async get_flight_changes(uid, base, params) {
    return this._get(`/scenario/${uid}/changes/${base}/flights`, params);
  }
  async get_aircraft_changes(uid, base, params) {
    return this._get(`/scenario/${uid}/changes/${base}/aircrafts`, params);
  }
  async get_pax_changes(uid, base, params) {
    return this._get(`/scenario/${uid}/changes/${base}/pax`, params);
  }
  async get_xlsx_diff(uid, base) {
    return this._getXLSX(`/scenario/${uid}/xlsx/${base}/diff`);
  }
  async get_xlsx_costs(uid, base, params) {
    return this._getXLSX(`/scenario/${uid}/xlsx/${base}/costs`, params);
  }
  // Details
  async get_flight_crew_duty_info(uid, flight_id, params) {
    return this._get(`/scenario/${uid}/flight/${flight_id}/crew_duty`, params);
  }
  // Difficulty
  async get_difficulty(uid, params) {
    return this._get(`/scenario/${uid}/difficulty`, params);
  }

  async get_crew_duty_image(uid, crew_member_id, params) {
    return this._getSvg(
      `/scenario/${uid}/crew/${crew_member_id}/svg/crew_duty`,
      params
    );
  }
}
