import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { ArrowIcon } from "components/common/Icons";

import { edge_options } from "components/common/viewers/SchedulerSVGViewer";

const crew_options = [
  { id: edge_options.ALL_CREW, label: "All" },
  { id: edge_options.MCXN_CREW, label: "Misconnections" },
  { id: edge_options.COCK, label: "Cockpit" },
  { id: edge_options.CAB, label: "Cabin" },
];

const pax_options = [
  { id: edge_options.ALL_PAX, label: "All" },
  { id: edge_options.MCXN_PAX, label: "Misconnections" },
];

export const PlanEdgesControl = ({ as = Button, onApply, edge_option }) => {
  const Component = as;
  const [show, setShow] = useState(false);
  const [new_edge_option, setEdgeOption] = useState(edge_option);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleApply = () => {
    onApply(new_edge_option);
    handleClose();
  };

  const renderEdgeOption = (sc) => (
    <Form.Check
      type="radio"
      checked={new_edge_option === sc.id}
      name="edge_option"
      key={sc.id}
      id={sc.id}
      onChange={(e) => e.target.checked && setEdgeOption(sc.id)}
      label={sc.label}
    />
  );

  return (
    <>
      <Component variant="primary" onClick={handleShow}>
        <ArrowIcon />
      </Component>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <fieldset>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Crew</Form.Label>
                    {crew_options.map(renderEdgeOption)}
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
            <Col>
              <Form>
                <fieldset>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Pax</Form.Label>
                    {pax_options.map(renderEdgeOption)}
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <fieldset>
                  <Form.Group controlId="formGridEmail">
                    <Form.Check
                      type="radio"
                      checked={new_edge_option === edge_options.NONE}
                      name="edge_option"
                      key={edge_options.NONE}
                      id={edge_options.NONE}
                      onChange={(e) =>
                        e.target.checked && setEdgeOption(edge_options.NONE)
                      }
                      label={"None"}
                    />
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
