import { DateTime } from "luxon";
import React from "react";
import { components } from "react-select";
import { FixedSizeList } from "react-window";
import padStart from "lodash/padStart";

export class CustomOption extends React.Component {
  render() {
    const { innerProps, isFocused, ...otherProps } = this.props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
    return (
      <components.Option
        {...newProps}
        key={this.props.value}
        className="react-select-option-hack"
      >
        {this.props.children}
      </components.Option>
    );
  }
}

function OptimizedMenuList(props) {
  const { options, children, maxHeight, getValue } = props;
  if (!children || !Array.isArray(children)) return null;

  const height = 35;
  const selectedValues = getValue();
  const initialOffset = selectedValues[0]
    ? options.indexOf(selectedValues[0]) * height
    : 0;

  return (
    <FixedSizeList
      width={""}
      itemSize={height}
      height={maxHeight}
      itemCount={children.length}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div className="react-select-option-hack" style={style}>
          {children[index]}
        </div>
      )}
    </FixedSizeList>
  );
}

function OptimizedOption(props) {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
}

export const optimizeSelect = {
  components: {
    MenuList: OptimizedMenuList,
    Option: OptimizedOption,
  },
};

export const getDurationFromISO = (start, end) => {
  const duration = DateTime.fromISO(end).diff(DateTime.fromISO(start), [
    "days",
    "hours",
    "minutes",
  ]);
  let duration_text = `${padStart(duration.hours, 2, "0")}h${padStart(
    duration.minutes,
    2,
    "0"
  )}m`;
  if (duration.days) duration_text = `${duration.days} days ${duration_text}`;
  return duration_text;
};
