import React, { useCallback, useEffect, useRef } from "react";
import Cleave from "cleave.js/react";
import moment from "moment";
import { DateTime } from "luxon";
import { Badge, Form } from "react-bootstrap";
import Select from "react-select";

import { isFunction, isPlainObject, isEqual, merge, map, find } from "lodash";

const hashCode = (s) =>
  [...s].reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0);

export const getObjectKey = (obj) => hashCode(JSON.stringify(obj));

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const isDevelop =
  Boolean(window.location.hostname === "dev.occam.app") ||
  Boolean(window.location.hostname === "dev.loweroccam.com");
export const isPre =
  Boolean(window.location.hostname === "pre.occam.app") ||
  Boolean(window.location.hostname === "pre.loweroccam.com");

export const isNewProd = Boolean(window.location.hostname === "prod.occam.app");

export const isStaging = Boolean(
  window.location.hostname === "stg.loweroccam.com"
);

export const isLower = window.location.hostname.indexOf("lower") > -1;

export const getEnvironment = () => {
  if (isLocalhost) return "LOCAL";
  if (isDevelop) return "DEV";
  if (isPre) return "PRE";
  return "PRO";
};

export const isPro = getEnvironment() === "PRO";

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const randomId = () => Math.random().toString(36).substring(7);

export const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export const prepareAogs = (aogs) => aogs || [];
// (aogs && aogs.map(a => ({ ...a, start: a.start && a.start.toLocaleString('en-GB') }))) || [];

export const prepareCurfews = (curfews) => curfews || [];
// (curfews &&
//   curfews.map(c => ({
//     ...c,
//     start: c.start && c.start.toLocaleString('en-GB'),
//     end: c.end && c.end.toLocaleString('en-GB')
//   }))) ||
// [];

export const humanDuration = (seconds) =>
  moment.duration(seconds, "seconds").humanize(true, {
    d: 7,
    w: 4,
    m: 60,
  });

export const utcNowDate = (now = new Date(), hours = 0) => {
  return new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours() + hours,
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );
};

export const roundDate = (date, type, offset) => {
  let date_clone = date.clone();
  let val = date_clone[type]();
  let roundedVal = Math.round(val / offset) * offset;
  return date_clone[type](roundedVal);
};

export const isToday = (time) => {
  return !!time && time.toDateString() === utcNowDate().toDateString();
};

export const isTimeout = (ref_date, TIMEOUT_SECONDS) => {
  const now = new Date();
  const diff = (now - ref_date) / 1000;
  return diff > TIMEOUT_SECONDS;
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.info("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

function getDomain() {
  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname.slice(hostname.length - 2).join(".");

  return hostname;
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.info("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export const titleRow = (row) => (
  <div>
    <span title={row.value}>{row.value}</span>
  </div>
);

export const selectRow = (row, mapping) => (
  <div>
    <span title={row.value}>{mapping[row.value]}</span>
  </div>
);

export const selectMultiRow = (row, mapping) => (
  <div>
    <span title={row.value.join(", ")}>
      {row.value.map((value) => mapping[value]).join(", ")}
    </span>
  </div>
);

export const dateRow = (row) => (
  <div>
    <span title={row.value}>
      {DateTime.fromISO(row.value).toFormat("yyyy-MM-dd")}
    </span>
  </div>
);

export const boolRow = (row) => (
  <div>
    <span>{row.value ? "✅" : "❌"}</span>
  </div>
);

export const tagRow = (row) => {
  return (
    <div>
      {row.value?.map((tag) => (
        <Badge key={tag} variant="primary" style={{ marginRight: "5px" }}>
          {tag}
        </Badge>
      ))}
    </div>
  );
};

export const copyToClipboard = () => copyTextToClipboard(window.location.href);

export const shareByEmail = () =>
  (window.location.href = `mailto:?body=Solution recovery link: ${window.location.href}&subject=OCCAM Recovery reference`);

export const simpleCol = (name, maxWidth, textAlign = "center") => ({
  Header: name,
  id: name,
  accessor: name,
  style: { textAlign },
  Cell: titleRow,
  maxWidth,
  width: maxWidth,
});

export const namedCol = (
  name,
  accessor,
  id,
  maxWidth,
  textAlign = "center"
) => ({
  Header: name,
  id: id || accessor,
  accessor: accessor,
  style: { textAlign },
  Cell: titleRow,
  maxWidth,
  width: maxWidth,
});

export const costCol = (
  name,
  accessor,
  id,
  maxWidth,
  currencyFormatter,
  textAlign = "center"
) => ({
  Header: name,
  id: id || accessor,
  accessor: accessor,
  style: { textAlign },
  maxWidth,
  width: maxWidth,
  Cell: ({ value }) => {
    const formatedCost = currencyFormatter.format(value);
    return <span title={formatedCost}>{formatedCost}</span>;
  },
});

export const boolCol = (
  name,
  accessor,
  id,
  maxWidth,
  textAlign = "center"
) => ({
  Header: name,
  id: id || accessor,
  accessor: accessor,
  style: { textAlign },
  Cell: boolRow,
  maxWidth,
  width: maxWidth,
});

export const tagCol = (name, accessor, id, maxWidth, textAlign = "center") => ({
  Header: name,
  id: id || accessor,
  accessor: accessor,
  style: { textAlign },
  Cell: tagRow,
  maxWidth,
  width: maxWidth,
});

export const hiddenCol = (name) => ({
  Header: `${name} (hidden)`,
  accessor: name,
  show: false,
  isVisible: false,
});

export const formCellText = (row, onChange, type = "text") => (
  <Form.Control
    type={type}
    value={row.value || ""}
    onChange={(e) => onChange(e.target.value)}
  ></Form.Control>
);

export const formCellNumber = (row, onChange) => (
  <Form.Control
    type="number"
    value={row.value || ""}
    onChange={(e) => onChange(parseInt(e.target.value))}
  ></Form.Control>
);

export const formCellTime = (row, onChange) => (
  <Cleave
    className="form-control"
    options={{
      time: true,
      timePattern: ["h", "m", "s"],
    }}
    value={row.value}
    onChange={(e) => onChange(e.target.value)}
  />
);

export const formCellDate = (row, onChange) => (
  <Cleave
    className="form-control"
    options={{
      date: true,
      delimiter: "-",
      placeholder: "YYYY-MM-DD",
      datePattern: ["Y", "m", "d"],
    }}
    value={row.value || ""}
    onChange={(e) => onChange(e.target.value)}
  />
);

export const formCellSelect = (row, onChange, options) => (
  <div style={{ position: "relative" }}>
    <Select
      placeholder={`Select ${row.column.Header}`}
      classNamePrefix="Select"
      styles={{ container: () => ({ padding: 0 }) }}
      className="form-control"
      options={options}
      value={options.find((option) => option.value === row.value) || null}
      onChange={(el) => onChange(el.value)}
    />
  </div>
);

export const formCellSelectMulti = (row, onChange, options) => (
  <div style={{ position: "relative" }}>
    <Select
      classNamePrefix="Select"
      placeholder={`Select ${row.column.Header}`}
      isMulti
      styles={{ container: () => ({ padding: 0 }) }}
      className="form-control"
      options={options}
      value={
        row.value?.map((val) =>
          options.find((option) => option.value === val)
        ) || []
      }
      onChange={(el) => onChange(el?.map((e) => e.value))}
    />
  </div>
);

export const formCellBool = (row, onChange) => {
  return (
    <Form.Check
      type="checkbox"
      style={{ textAlign: "center" }}
      checked={!!row.value}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
};

export const deepCompareEquals = (a, b) => {
  if (DateTime.isDateTime(a) && DateTime.isDateTime(b)) {
    return (
      +a.set({ seconds: 0, milliseconds: 0 }) ===
      +b.set({ seconds: 0, milliseconds: 0 })
    );
  }
  if (isPlainObject(a) && isPlainObject(b)) return isEqual(a, b);
  return a === b;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (isFunction(ref.current) && isFunction(value)) return ref.current;
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export const useDeepCompareEffect = (callback, dependencies) =>
  useEffect(callback, dependencies.map(useDeepCompareMemoize)); // eslint-disable-line react-hooks/exhaustive-deps

export const useDeepCompareCallback = (callback, dependencies) =>
  useCallback(callback, dependencies.map(useDeepCompareMemoize)); // eslint-disable-line react-hooks/exhaustive-deps

export const deepMemo = (component) =>
  React.memo(component, (prev, next) => {
    for (const key in prev) {
      if (
        Object.hasOwnProperty.call(prev, key) &&
        Object.hasOwnProperty.call(next, key)
      ) {
        const prev_element = prev[key];
        const next_element = next[key];
        if (
          !isFunction(prev_element) &&
          !isFunction(next_element) &&
          !deepCompareEquals(prev_element, next_element)
        ) {
          return false;
        }
      }
    }
    return true;
  });

export const mergeByKey = (arr1, arr2, key) => {
  var criteria = {};
  criteria[key] = null;
  return map(arr1, function (item) {
    criteria[key] = item[key];
    return merge(item, find(arr2, criteria));
  });
};

const _getCookie = (name) => {
  return (
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
    null
  );
};

export const deleteCookies = () => {
  document.cookie =
    "XSRF-TOKEN=; Path=/; domain=" +
    getDomain() +
    "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.cookie =
    "session=; Path=/; domain=" +
    getDomain() +
    "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const getCSRFCookie = () => {
  return _getCookie("XSRF-TOKEN");
};

export const getSessionCookie = () => {
  return _getCookie("session");
};

export const formatDateTime = (date_text) =>
  DateTime.fromISO(date_text, { zone: "utc" }).toFormat("yyyy-MM-dd HH:mm");
