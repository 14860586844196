import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Badge, Nav, Navbar, Tab, Button } from "react-bootstrap";
import unionBy from "lodash/unionBy";
import union from "lodash/union";
import posthog from "posthog-js";
import { AOGManager } from "./components/AOGManager";
import { CloseAirportManager } from "./components/CloseAirportManager";
import { DisruptionsSummary } from "./components/DisruptionsSummary";
import {
  TemporalRulesManager,
  TemporalRulesSummary,
} from "./components/TemporalRulesManager";
import { AirplaneIcon, AirportIcon } from "components/common/Icons";
import {
  getTotalTemporalRules,
  getTotalActiveTemporalRules,
} from "slices/temporalRulesSlice";
import {
  deepCompareEquals,
  deepMemo,
  useDeepCompareEffect,
  usePrevious,
} from "utils";
import { ScenarioRequest } from "api";
import { getStations } from "slices/stationsSlice";
import { getAircrafts } from "slices/aircraftsSlice";
import { Loader } from "components/common/Loader";
import { getAircraftTypes } from "slices/aircraftTypesSlice";

const getOptionsMapping = (options) =>
  options.reduce(
    (original, item) => ({ ...original, [item.value]: item.label }),
    {}
  );

export const DisruptionsManager = deepMemo(
  ({
    user_changes = {},
    loading,
    reference_time,
    base_scenario_uid,
    invalid_aogs = [],
    onUpdate,
    setDisruptions,
  }) => {
    const [active_key, setActiveKey] = useState("");
    const [changes, setChanges] = useState(0);
    const total_aogs = user_changes.aogs?.length || 0;
    const total_curfews = user_changes.curfews?.length || 0;
    const total_disruptions = total_aogs + total_curfews;
    const total_rules = useSelector(getTotalTemporalRules, shallowEqual);
    const total_active_rules = useSelector(
      getTotalActiveTemporalRules,
      shallowEqual
    );
    const [scenario_aircraft, setScenarioAircraft] = useState([]);
    const [scenario_aicraft_types, setAircraftTypes] = useState([]);
    const stations = useSelector(getStations, shallowEqual);
    const current_aircrafts = useSelector(getAircrafts, shallowEqual);
    const current_aicraft_types = useSelector(getAircraftTypes, shallowEqual);

    const aircrafts = unionBy(scenario_aircraft, current_aircrafts, "value");
    const aicraft_types = union(scenario_aicraft_types, current_aicraft_types);

    const prev_reference_time = usePrevious(reference_time);

    useDeepCompareEffect(() => {
      const aircraft_request = new ScenarioRequest();
      const getScenarioAircraft = async () => {
        const response = await aircraft_request.get_aircrafts(
          base_scenario_uid
        );
        if (response?.aircrafts)
          setScenarioAircraft(
            response.aircrafts.map((acrf, index) => ({
              value: acrf,
              label: response.aircrafts_labels[index],
            }))
          );
      };
      const aircraft_types_request = new ScenarioRequest();
      const getScenarioAircraftTypes = async () => {
        const response = await aircraft_types_request.get_aircraft_types(
          base_scenario_uid
        );
        if (response?.aircraft_types) setAircraftTypes(response.aircraft_types);
      };
      if (
        base_scenario_uid &&
        !deepCompareEquals(prev_reference_time, reference_time)
      ) {
        getScenarioAircraft();
        getScenarioAircraftTypes();
      }
    }, [base_scenario_uid]);

    const nonCommitUpdate = (update) => {
      setChanges((prev) => ++prev);
      setDisruptions(update);
    };

    const Item = ({ eventKey, name, icon, total }) => (
      <Nav.Item>
        <Nav.Link active={active_key === eventKey} eventKey={eventKey}>
          {name} {icon}
          {!!total && <Badge variant="info">{total}</Badge>}
        </Nav.Link>
      </Nav.Item>
    );

    const TabContent = () => (
      <Tab.Content className="builder" style={{ position: "relative" }}>
        <Loader show={!!active_key && loading} />
        <Tab.Pane active={active_key === "aog"} title="AOGs">
          <AOGManager
            aogs={user_changes.aogs}
            reference_time={reference_time}
            invalid_aogs={invalid_aogs}
            base_scenario_uid={base_scenario_uid}
            onUpdate={(aogs) => nonCommitUpdate({ aogs })}
            scenario_stations={stations}
            scenario_aircraft={aircrafts}
            aircraft_mapping={getOptionsMapping(aircrafts)}
            stations_mapping={getOptionsMapping(stations)}
          />
        </Tab.Pane>
        <Tab.Pane active={active_key === "curfews"} title="Airport Closures">
          <CloseAirportManager
            curfews={user_changes.curfews}
            reference_time={reference_time}
            onUpdate={(curfews) => nonCommitUpdate({ curfews })}
            scenario_stations={stations}
            stations_mapping={getOptionsMapping(stations)}
            scenario_aicraft_types={aicraft_types}
          />
        </Tab.Pane>
        <Tab.Pane active={active_key === "summary"} title="Summary">
          <DisruptionsSummary
            aogs={user_changes.aogs}
            onUpdateAogs={(aogs) => nonCommitUpdate({ aogs })}
            curfews={user_changes.curfews}
            onUpdateCurfews={(curfews) => nonCommitUpdate({ curfews })}
            aircraft_mapping={getOptionsMapping(aircrafts)}
            stations_mapping={getOptionsMapping(stations)}
          />
        </Tab.Pane>
        <Tab.Pane
          active={active_key === "rules_manager"}
          title="Temporal Rules"
        >
          <TemporalRulesManager
            aircrafts={aircrafts}
            stations={stations}
            scenarioUpdateFn={(updates) => {
              onUpdate(updates)
              posthog.capture('builder_distruption_manager_temporal_rule')
            }}
          />
        </Tab.Pane>
        <Tab.Pane active={active_key === "rules"} title="View">
          <TemporalRulesSummary />
        </Tab.Pane>
      </Tab.Content>
    );

    return (
      <Tab.Container>
        <Navbar
          bg="dark-grey"
          variant="dark"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <Nav
            onSelect={(tab) =>
              setActiveKey((prev) => (tab === prev ? "" : tab))
            }
          >
            <Item eventKey="summary" name="Summary" total={total_disruptions} />
          </Nav>
          |
          <Nav
            className="mr-auto"
            onSelect={(tab) =>
              setActiveKey((prev) => (tab === prev ? "" : tab))
            }
          >
            <Item
              eventKey="aog"
              name="AOGs"
              icon={<AirplaneIcon />}
              total={total_aogs}
            />
            <Item
              eventKey="curfews"
              name="Airport Closures"
              icon={<AirportIcon />}
              total={total_curfews}
            />
          </Nav>
          <Nav
            onSelect={(tab) =>
              setActiveKey((prev) => (tab === prev ? "" : tab))
            }
          >
            <Item
              eventKey="rules_manager"
              name="Rules manager"
              total={total_rules}
            />
            <Item
              eventKey="rules"
              name="Active rules"
              total={total_active_rules}
            />
          </Nav>
        </Navbar>
        <TabContent />
        {!!changes && (
          <Navbar
            bg="dark-grey"
            variant="dark"
            className="justify-content-md-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Nav>
              <Button
                style={{ borderRadius: 0 }}
                onClick={() => {
                  setChanges(0);
                  onUpdate(user_changes);
                }}
              >
                Apply {changes} changes
              </Button>
            </Nav>
          </Navbar>
        )}
      </Tab.Container>
    );
  }
);
