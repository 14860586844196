import React from "react";
import filter from "lodash/filter";
import sum from "lodash/sum";
import { Badge, Table } from "react-bootstrap";
import { Dot, colors } from "../../BaseTable";

const getColor = (cancelable) => {
  if (cancelable === true) return colors.Yellow;
  if (cancelable === false) return colors.Red;
  return colors.Blue;
};

export const AircraftRegBalanceTable = ({ data = [] }) => {
  const stations = [
    ...new Set([
      ...new Set(data.map((arft) => arft.OriginalStation)),
      ...new Set(data.map((arft) => arft.NewStation)),
    ]),
  ];

  const countCanelableTotal = (station_type, station) =>
    filter(data, {
      [station_type]: station,
      HasCancelableMaintenance: true,
    })?.length || 0;
  const countNonCancelableTotal = (station_type, station) =>
    filter(data, {
      [station_type]: station,
      HasCancelableMaintenance: false,
    })?.length || 0;
  const countRegularTotal = (station_type, station) =>
    filter(
      data,
      (o) =>
        o[station_type] === station && o.HasCancelableMaintenance == undefined // eslint-disable-line
    )?.length || 0;

  const original_totals = stations.map((original_station) => [
    countCanelableTotal("OriginalStation", original_station),
    countNonCancelableTotal("OriginalStation", original_station),
    countRegularTotal("OriginalStation", original_station),
  ]);
  const new_totals = stations.map((new_station) => [
    countCanelableTotal("NewStation", new_station),
    countNonCancelableTotal("NewStation", new_station),
    countRegularTotal("NewStation", new_station),
  ]);

  const totalIsEmpty = (totals) => !totals[0] && !totals[1] && !totals[2];

  const renderTotals = (totals, sign = "+") => (
    <>
      {!!totals[0] && (
        <Badge style={{ backgroundColor: colors.Yellow }}>
          {sign} {totals[0]}
        </Badge>
      )}
      {!!totals[1] && (
        <Badge style={{ backgroundColor: colors.Red }}>
          {sign} {totals[1]}
        </Badge>
      )}
      {!!totals[2] && (
        <Badge style={{ backgroundColor: colors.Blue }}>
          {sign} {totals[2]}
        </Badge>
      )}
    </>
  );

  const legend = [
    { color: colors.Blue, text: "Nothing" },
    { color: colors.Yellow, text: "Cancelable" },
    { color: colors.Red, text: "Non Cancelable" },
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>Aircraft Registration Balance</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {legend.map((l) => (
            <Dot key={l.color} color={l.color} text={l.text} />
          ))}
        </div>
      </div>
      <Table bordered>
        <tbody>
          {stations.map((original_station, original_iterator) =>
            totalIsEmpty(original_totals[original_iterator]) ? null : (
              <tr key={original_station}>
                {!original_iterator && (
                  <th
                    style={{ width: "10px", verticalAlign: "middle" }}
                    rowSpan={stations.length + 1}
                  >
                    ORIGINAL
                  </th>
                )}
                <th>
                  {original_station}
                  {":  "}
                  {renderTotals(original_totals[original_iterator], "-")}
                </th>
                {stations.map((new_station, new_iterator) =>
                  totalIsEmpty(new_totals[new_iterator]) ? null : (
                    <td>
                      {filter(data, {
                        OriginalStation: original_station,
                        NewStation: new_station,
                      })?.map((arft) => (
                        <>
                          <Badge
                            style={{
                              backgroundColor: getColor(
                                arft.HasCancelableMaintenance
                              ),
                            }}
                          >
                            {arft.AircraftID}
                          </Badge>
                          <br />
                        </>
                      ))}
                    </td>
                  )
                )}
              </tr>
            )
          )}
          <tr>
            <th>
              {"Total: "}
              {sum(new_totals.map((totals) => sum(totals)))}
            </th>
            {stations.map((new_station, new_iterator) =>
              totalIsEmpty(new_totals[new_iterator]) ? null : (
                <th key={new_station}>
                  {new_station}
                  {":  "}
                  {renderTotals(new_totals[new_iterator])}
                </th>
              )
            )}
          </tr>
          <tr>
            <th colSpan={stations.length + 1} style={{ textAlign: "center" }}>
              CHANGED
            </th>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
