import React from "react";
import classNames from "classnames";

/* eslint-disable import/no-webpack-loader-syntax */
import CrewIconRaw from "-!@svgr/webpack!./svgs/crew.svg";
import PaxIconRaw from "-!@svgr/webpack!./svgs/pax.svg";
import AirplaneIconRaw from "-!@svgr/webpack!./svgs/airplane.svg";
import AirportIconRaw from "-!@svgr/webpack!./svgs/airport.svg";
import SortIconRaw from "-!@svgr/webpack!./svgs/sort.svg";
import FilterIconRaw from "-!@svgr/webpack!./svgs/filter.svg";
import ArrowIconRaw from "-!@svgr/webpack!./svgs/arrow-both.svg";

import styles from "./index.module.css";
import { deepMemo } from "utils";

let cx = classNames.bind(styles);

export const AirplaneIcon = deepMemo(({ active }) => (
  <AirplaneIconRaw
    viewBox="-25 -25 450 450"
    className={cx(styles.Icon, { [styles.Active]: active })}
  />
));
export const CrewIcon = deepMemo(({ active }) => (
  <CrewIconRaw
    viewBox="0 0 512 512"
    className={cx(styles.Icon, { [styles.Active]: active })}
  />
));
export const PaxIcon = deepMemo(({ active }) => (
  <PaxIconRaw
    viewBox="0 0 512 512"
    className={cx(styles.Icon, { [styles.Active]: active })}
  />
));
export const AirportIcon = deepMemo(({ active }) => (
  <AirportIconRaw className={cx(styles.Icon, { [styles.Active]: active })} />
));

export const SortIcon = deepMemo(({ active }) => (
  <SortIconRaw className={cx(styles.Icon, { [styles.Active]: active })} />
));

export const FilterIcon = deepMemo(({ active }) => (
  <FilterIconRaw className={cx(styles.Icon, { [styles.Active]: active })} />
));

export const ArrowIcon = deepMemo(({ active }) => (
  <ArrowIconRaw className={cx(styles.Icon, { [styles.Active]: active })} />
));
