import React, { Component } from "react";
import { connect } from "react-redux";
import find from "lodash/find";
import ReactTable from "react-table-6";
import autoBind from "auto-bind";
import Select from "react-select";
import Octicon from "react-octicon";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";

import usersSlice, {
  createUser,
  deleteUser,
  fetchUsers,
  updateUser,
} from "slices/usersSlice";
import rolesSlice, { fetchRoles } from "slices/rolesSlice";
import { getIsAdmin } from "slices/userSlice";

import { getOptionByValue, organizations } from "./options";

import { toast } from "react-toastify";
import {
  formCellBool,
  titleRow,
  formCellText,
  hiddenCol,
  formCellSelect,
  formCellSelectMulti,
  tagRow,
  boolRow,
} from "utils";

class UsersComponent extends Component {
  state = {
    user: {
      password: undefined,
      email: "",
      organization: undefined,
      name: "",
      title: "",
      roles: [],
    },
    clip_organization: "volotea",
  };

  constructor(props) {
    super(props);
    autoBind(this);
    props.fetchUsers();
    props.fetchRoles();
  }

  handleCopyClipboard(event) {
    event.preventDefault();
    event.stopPropagation();
    const { users } = this.props;
    const airline_emails = users
      .filter(
        (user) =>
          user.organization === this.state.clip_organization &&
          !user.is_demo &&
          !user.is_bigblue
      )
      .map((user) => user.email);
    navigator.clipboard.writeText(airline_emails.join(", "));
    toast.success(
      `List for ${this.state.clip_organization} copied at the clipboard.`
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const { user } = this.state;
    const roles = user.roles.map(
      (role_name) =>
        this.props.roles.find((role) => role.label === role_name).value
    );
    if (user.email && roles) {
      this.props
        .createUser(
          user.email,
          user.password,
          user.organization,
          user.name,
          user.title,
          roles,
          user.is_demo,
          user.is_bigblue
        )
        .then(() => this.clean())
        .catch((err) => toast.error(err.message));
    }
  }

  onChangePassword(event) {
    this.setState({
      user: { ...this.state.user, password: event.target.value },
    });
  }

  onChangeEmail(event) {
    this.setState({ user: { ...this.state.user, email: event.target.value } });
  }

  onChangeName(event) {
    this.setState({ user: { ...this.state.user, name: event.target.value } });
  }

  onChangeTitle(event) {
    this.setState({ user: { ...this.state.user, title: event.target.value } });
  }

  onChangeRoles(values) {
    if (values && values.map) {
      this.setState({
        user: { ...this.state.user, roles: values.map((value) => value.label) },
      });
    }
  }

  onChangeOrganization(event) {
    this.setState({ user: { ...this.state.user, organization: event.value } });
  }

  onChangeClipOrganization(event) {
    this.setState({ clip_organization: event.value });
  }

  onChangeDemo(event) {
    this.setState({
      user: { ...this.state.user, is_demo: event.target.checked },
    });
  }

  onChangeBB(event) {
    this.setState({
      user: { ...this.state.user, is_bigblue: event.target.checked },
    });
  }

  clean() {
    this.setState({
      user: {
        password: "",
        email: "",
        organization: "",
        name: "",
        title: "",
        roles: [],
        is_demo: false,
        is_bigblue: false,
      },
    });
  }

  render() {
    const { user, clip_organization } = this.state;
    const { users, roles, updated } = this.props;

    const role_mapping = {};
    const role_options = roles.map((role) => {
      role_mapping[role.label] = role.role.id;
      return {
        id: role.id,
        value: role.label,
        label: role.label,
      };
    });

    const getNameByValue = (list, value) =>
      ({ ...find(list, { value }) }.label);

    const updateOrDefault = (base, name) =>
      (updated[base.id] && updated[base.id][name]) || base[name];

    const updateOrDefaultBool = (base, name) => {
      if (updated[base.id] && updated[base.id][name] !== undefined)
        return updated[base.id][name];
      return base[name];
    };

    const admin_columns = this.props.isAdmin
      ? [
        {
          id: "password",
          Header: "Password",
          accessor: (d) => updated[d.id]?.password || d.password,
          style: { textAlign: "center" },
          Cell: (row) =>
            !updated[row.original.id]
              ? titleRow({ value: "************" })
              : formCellText(
                row,
                (value) =>
                  this.props.changeUpdate({
                    id: row.original.id,
                    change: { password: value },
                  }),
                "passowrd"
              ),
        },
        {
          id: "organization",
          Header: "Organization",
          accessor: (d) => updateOrDefault(d, "organization"),
          style: { textAlign: "center", overflow: "visible" },
          Cell: (row) =>
            !updated[row.original.id]
              ? titleRow({
                ...row,
                value: getNameByValue(
                  organizations,
                  updateOrDefault(row.original, "organization")
                ),
              })
              : formCellSelect(
                row,
                (value) =>
                  this.props.changeUpdate({
                    id: row.original.id,
                    change: { organization: value },
                  }),
                organizations
              ),
        },
        {
          id: "is_demo",
          Header: "Demo",
          accessor: (d) => updateOrDefaultBool(d, "is_demo"),
          maxWidth: 70,
          style: { textAlign: "center" },
          Cell: (row) =>
            !updated[row.original.id]
              ? boolRow(row)
              : formCellBool(row, (value) =>
                this.props.changeUpdate({
                  id: row.original.id,
                  change: { is_demo: value },
                })
              ),
        },
        {
          id: "is_bigblue",
          Header: "BB",
          accessor: (d) => updateOrDefaultBool(d, "is_bigblue"),
          maxWidth: 70,
          style: { textAlign: "center" },
          Cell: (row) =>
            !updated[row.original.id]
              ? boolRow(row)
              : formCellBool(row, (value) =>
                this.props.changeUpdate({
                  id: row.original.id,
                  change: { is_bigblue: value },
                })
              ),
        },
      ]
      : [];
    const columns = [
      hiddenCol("id"),
      {
        id: "email",
        Header: "Email",
        accessor: (d) => updated[d.id]?.email || d.email,
        style: { textAlign: "center" },
        Cell: (row) => titleRow(row),
      },
      {
        id: "name",
        Header: "Name",
        accessor: (d) => updated[d.id]?.name || d.name,
        style: { textAlign: "center" },
        Cell: (row) =>
          !updated[row.original.id]
            ? titleRow(row)
            : formCellText(row, (value) =>
              this.props.changeUpdate({
                id: row.original.id,
                change: { name: value },
              })
            ),
      },
      {
        id: "title",
        Header: "Title",
        accessor: (d) => updated[d.id]?.title || d.title,
        style: { textAlign: "center" },
        Cell: (row) =>
          !updated[row.original.id]
            ? titleRow(row)
            : formCellText(row, (value) =>
              this.props.changeUpdate({
                id: row.original.id,
                change: { title: value },
              })
            ),
      },
      {
        id: "roles",
        Header: "Roles",
        accessor: (d) => updateOrDefault(d, "roles"),
        style: { textAlign: "center", overflow: "visible" },
        Cell: (row) =>
          !updated[row.original.id]
            ? tagRow(row)
            : formCellSelectMulti(
              row,
              (value) =>
                this.props.changeUpdate({
                  id: row.original.id,
                  change: { roles: value },
                }),
              role_options
            ),
      },
      ...admin_columns,
      {
        id: "actions",
        Header: "Actions",
        maxWidth: 150,
        style: { textAlign: "center" },
        Cell: (row) => {
          const is_editing = !!updated[row.original.id];
          return (
            <ButtonGroup>
              <Button
                variant={!is_editing ? "primary" : "danger"}
                size="sm"
                onClick={() =>
                  is_editing
                    ? this.props.removeUpdate(row.original.id)
                    : this.props.changeUpdate({
                      id: row.original.id,
                      change: {},
                    })
                }
              >
                <Octicon name="pencil" />
              </Button>
              <Button
                variant={!is_editing ? "primary" : "success"}
                size="sm"
                onClick={() => {
                  this.props.updateUser({
                    id: row.original.id,
                    email: updated[row.original.id].email || row.original.email,
                    password:
                      updated[row.original.id].password ||
                      row.original.password,
                    organization:
                      updated[row.original.id].organization ||
                      row.original.organization,
                    name: updated[row.original.id].name || row.original.name,
                    title: updated[row.original.id].title || row.original.title,
                    roles: (
                      updated[row.original.id].roles || row.original.roles
                    )?.map((role) => role_mapping[role]),
                    is_demo: updateOrDefaultBool(row.original, "is_demo"),
                    is_bigblue: updateOrDefaultBool(row.original, "is_bigblue"),
                  });
                }}
                disabled={!is_editing}
              >
                <Octicon name="check" />
              </Button>
              <Button
                size="sm"
                onClick={() => this.props.deleteUser(row.original.email)}
              >
                <Octicon name="trashcan" />
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];

    return (
      <Container style={{ paddingTop: "15px" }} fluid={this.props.isAdmin}>
        <Row>
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <Form.Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      required
                      value={user.email}
                      onChange={this.onChangeEmail}
                      type="email"
                      placeholder="Enter email"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      value={user.name}
                      onChange={this.onChangeName}
                      type="text"
                      placeholder="Enter name"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formBasicTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      value={user.title}
                      onChange={this.onChangeTitle}
                      type="text"
                      placeholder="Enter title (ex. Operational Manager)"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      value={user.password}
                      type="password"
                      placeholder="Password"
                      onChange={this.onChangePassword}
                    />
                  </Form.Group>
                </Col>
                {this.props.isAdmin && (
                  <>
                    <Col>
                      <Form.Group controlId="formBasicOrg">
                        <Form.Label>Organization</Form.Label>
                        <Select
                          classNamePrefix="Select"
                          value={
                            user.organization &&
                            getOptionByValue(organizations, user.organization)
                          }
                          options={organizations}
                          onChange={this.onChangeOrganization}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col>
                  <Form.Group controlId="formBasicRoles">
                    <Form.Label>Roles</Form.Label>
                    <Select
                      classNamePrefix="Select"
                      value={user.roles.map((role_name) =>
                        roles.find((role) => role.label === role_name)
                      )}
                      isMulti
                      options={roles}
                      onChange={this.onChangeRoles}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              {this.props.isAdmin && (
                <>
                  <Form.Group controlId="demo">
                    <Form.Check
                      custom
                      type="checkbox"
                      label="Is Demo account"
                      checked={!!user.is_demo}
                      onChange={this.onChangeDemo}
                    />
                  </Form.Group>
                  <Form.Group controlId="bigblue">
                    <Form.Check
                      custom
                      type="checkbox"
                      label="Is BB account"
                      checked={!!user.is_bigblue}
                      onChange={this.onChangeBB}
                    />
                  </Form.Group>
                </>
              )}
              <Button variant="primary" type="submit">
                Create User
              </Button>
            </Form>
          </Col>
        </Row>
        <hr />
        {this.props.isAdmin && (
          <>
            <Row>
              <Col>
                <Form onSubmit={this.handleCopyClipboard}>
                  <Form.Row>
                    <Col>
                      <Form.Group controlId="formBasicOrg">
                        <Form.Label>Organization</Form.Label>
                        <Select
                          classNamePrefix="Select"
                          value={
                            clip_organization &&
                            getOptionByValue(organizations, clip_organization)
                          }
                          options={organizations}
                          onChange={this.onChangeClipOrganization}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Button variant="primary" type="submit">
                    Copy list
                  </Button>
                </Form>
              </Col>
            </Row>
            <hr />
          </>
        )}
        <Row>
          <Col>
            <ReactTable
              data={users}
              columns={columns}
              minRows={3}
              resizable={false}
              defaultSorted={[
                {
                  id: "id",
                  desc: false,
                },
              ]}
              defaultPageSize={50}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  users: usersSlice.selectors.getUsers(state).current,
  updated: usersSlice.selectors.getUsers(state).updated,
  roles: rolesSlice.selectors.getRoles(state),
  isAdmin: getIsAdmin(state),
});

export const Users = connect(mapStateToProps, {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  fetchRoles,
  changeUpdate: usersSlice.actions.changeUpdate,
  removeUpdate: usersSlice.actions.removeUpdate,
})(UsersComponent);
