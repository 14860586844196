import React, { useCallback, useMemo } from "react";
import ReactTable from "react-table-6";

import { standby_columns } from "./columns";
import { shallowEqual, useSelector } from "react-redux";
import configSlice from "slices/configSlice";
import { getUserOrganization } from "slices/userSlice";
import { Dot } from "pages/Recover/components/SolutionDetails/components/BaseTable";
import { ExportStandbysButton } from "./export";
import { Nav, Navbar } from "react-bootstrap";

const CABIN_COLOR = '#1E90FF' // Light blue
const COCKPIT_COLOR = '#32CD32' // Light peach

const legend = [
  { color: CABIN_COLOR, text: "Cabin" },
  { color: COCKPIT_COLOR, text: "Cockpit" },
]

const roleColors = {
  CO: COCKPIT_COLOR,
  SO: COCKPIT_COLOR,
  SG: CABIN_COLOR,
  AU: CABIN_COLOR,
};

const customCodes = {
  volotea: {
    SBY: '#FFD700', // Early
    SBL: '#FF4500' // Late
  }
}

const order = ["CO", "SO", "SG", "AU"];

export const StandByTable = ({ standbys = [], scope = "standbys" }) => {
  const roles_map = useSelector(configSlice.selectors.getRolesMap, shallowEqual);
  const organization = useSelector(getUserOrganization, shallowEqual);

  const roleSorting = useCallback((a, b) => {
    const aInternal = roles_map[a] || a;
    const bInternal = roles_map[b] || b;
    const aIndex = order.indexOf(aInternal);
    const bIndex = order.indexOf(bInternal);

    const aValue = aIndex !== -1 ? aIndex : order.length;
    const bValue = bIndex !== -1 ? bIndex : order.length;

    return aValue - bValue;
  }, [roles_map]);

  const columns = useMemo(() => standby_columns(roleSorting), [roleSorting])

  return (
    <>
      <Navbar
        bg="light-grey"
        variant="dark"
        className="justify-content-between trim-navbar"
      >
        <Nav className="ml-auto">
          <Nav.Item>
            <ExportStandbysButton standbys={standbys} filename={`Standby${scope}`}>Export {scope} Standbys</ExportStandbysButton>
          </Nav.Item>
          <div style={{ display: "flex", alignItems: "center", color: "white", marginRight: "10px" }}>
            {legend.map((l) => (
              <Dot key={l.color} color={l.color} text={l.text} />
            ))}
          </div>
        </Nav>
      </Navbar>
      <ReactTable
        multiSort
        filterable
        defaultFilterMethod={(filter, row) =>
          row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()) > -1
        }
        data={standbys}
        columns={columns}
        minRows={3}
        resizable={false}
        defaultSorted={[
          {
            id: "Station",
            desc: false,
          },
          {
            id: "Role",
            desc: false,
          },
        ]}
        defaultPageSize={5}
        getTdProps={(state, rowInfo, column) => {
          if (column.id === 'Role' && rowInfo) {
            const internalRole = roles_map[rowInfo.original.Role] || rowInfo.original.Role;
            return {
              style: {
                backgroundColor: roleColors[internalRole] || '',
              },
            };
          }
          if (column.id === 'Code' && rowInfo) {
            const standbyCode = roles_map[rowInfo.original.Code] || rowInfo.original.Code;
            return {
              style: {
                backgroundColor: customCodes?.[organization]?.[standbyCode] || '',
              },
            };
          }
          return {};
        }}
      />
    </>
  )
};
