import React from "react";

import { StatsTable } from "./StatsTable";
import { flight_columns } from "./columns";

const defaultSorted = [
  {
    id: "TailNumber",
    desc: false,
  },
  {
    id: "ETD",
    desc: false,
  },
];

export const OTPReport = ({ title, data }) => {
  return !!data?.length ? (
    <StatsTable
      title={title}
      columns={flight_columns}
      data={data}
      defaultSorted={defaultSorted}
    />
  ) : (
    <div>
      <center>
        <h1>No OTP events</h1>
      </center>
    </div>
  );
};
