import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import posthog from "posthog-js";
import { isLocalhost, isPro } from "utils";

import { Users } from "api";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    fetchUserSucceeded: (state, action) => {
      return action.payload;
    },
    clearUser: () => ({}),
  },
});

userSlice.selectors = {
  getUser: (state) => state.user,
};

export default userSlice;

export const getUser = (state) => state.user || {};

export const getUserRoles = createSelector(
  getUser,
  (state) => state.roles || []
);
export const getUserOrganization = createSelector(
  getUser,
  (state) => state.organization
);
const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const EURFormatter = new Intl.NumberFormat('es-ES', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0
});

const EUR_AIRLINES = ["volotea", "vueling"];

export const getOrgCurrencyFormatter = createSelector(getUser, (state) => {
  if (EUR_AIRLINES.includes(state.organization)) {
    return EURFormatter;
  }
  return USDFormatter;
});
export const getIsAdmin = createSelector(
  getUser,
  (state) => state.roles?.indexOf("admin") > -1
);
export const getIsManager = createSelector(
  getUser,
  (state) => state.roles?.indexOf("manager") > -1
);
export const getIsUser = createSelector(
  getUser,
  (state) => state.roles?.indexOf("user") > -1
);
export const getIsViewer = createSelector(
  getUser,
  (state) => state.roles?.indexOf("viewer") > -1
);
export const getIsBB = createSelector(getUser, (state) => state.is_bigblue);
export const getIsVueling = createSelector(
  getUser,
  (state) => getUserOrganization(state) === "vueling"
);
export const getIsVolotea = createSelector(
  getUser,
  (state) => getUserOrganization(state) === "volotea"
);
export const getUserPreferences = createSelector(
  getUser,
  (state) => state.preferences
);
export const getUserSorting = createSelector(
  getUser,
  (state) => getUserPreferences(state)?.sorting
);
export const getUserCriteria = createSelector(
  getUser,
  (state) => getUserSorting(state)?.criteria
);
export const getUserToggle = createSelector(
  getUser,
  (state) => getUserSorting(state)?.flights_and_bckp_on_top
);

export const updateSorting = (sorting) => async (dispatch, getState) => {
  try {
    const user = getUser(getState());
    const updated_user = {
      email: user.email,
      preferences: { ...user.preferences, sorting },
    };
    await Users.update(updated_user);
    dispatch(userSlice.actions.fetchUserSucceeded(updated_user));
  } catch (error) {
    toast.error(`Users update failed: ${error.message}`);
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    const { user } = await Users.me();
    const is_bigblue = user.is_bigblue ? "Yes" : "No";
    if (!isLocalhost) {
      Sentry.setUser({ email: user.email });
      Sentry.setTag("is_bigblue", is_bigblue);
      Sentry.setTag("organization", user.organization);
    }
    if (isPro) {
      posthog.identify(user.id, {
        is_bigblue: is_bigblue,
        email: user.email,
        roles: user.roles,
        organization: user.organization,
      });
    }
    dispatch(userSlice.actions.fetchUserSucceeded(user));
  } catch (error) {
    return toast.error(`User fetch failed: ${error.message}`);
  }
};
