import React from "react";
import { namedCol, simpleCol } from "utils";

import { StatsTable } from "./StatsTable";

const obk_columns = [
  simpleCol("FlightNumber"),
  simpleCol("Route"),
  simpleCol("OverbookedPassengers"),
  simpleCol("SeatClass"),
  namedCol("NoShow", (d) => parseFloat(d.NoShow).toFixed(3)),
];

export const OVBReport = ({ ovb_flights = [] }) =>
  !!ovb_flights.length ? (
    <StatsTable title="OVB" columns={obk_columns} data={ovb_flights} />
  ) : (
    <div>
      <center>
        <h1>No OVB events</h1>
      </center>
    </div>
  );
