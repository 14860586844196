import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { Users } from "api";

import { fetchUser } from "./userSlice";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    current: [],
    updated: {},
  },
  reducers: {
    replace: (state, { payload }) => {
      state.current = payload;
    },
    changeUpdate: (state, { payload: { id, change } }) => ({
      ...state,
      updated: { ...state.updated, [id]: { ...state.updated[id], ...change } },
    }),
    removeUpdate: (state, { payload }) => {
      delete state.updated[payload];
    },
  },
});

usersSlice.selectors = {
  getUsers: (state) => state.users,
};

export default usersSlice;

export const fetchUsers = () => (dispatch) => {
  return Users.list()
    .then(({ users }) => dispatch(usersSlice.actions.replace(users)))
    .catch((error) => toast.error(`Users fetch failed: ${error.message}`));
};

export const createUser =
  (
    email,
    password,
    organization,
    name,
    title,
    roles,
    is_demo = false,
    is_bigblue = false
  ) =>
  (dispatch) => {
    return Users.create({
      email,
      password,
      organization,
      name,
      title,
      roles,
      is_demo,
      is_bigblue,
    })
      .then(() => {
        dispatch(fetchUsers());
        toast.success(`User create: ${email}`);
      })
      .catch((error) => {
        dispatch(fetchUsers());
        toast.error(`Users create failed: ${error.message}`);
      });
  };

export const updateUser = (user) => (dispatch) => {
  return Users.update({
    email: user.email,
    password: user.password,
    organization: user.organization,
    name: user.name,
    title: user.title,
    roles: user.roles,
    is_demo: user.is_demo,
    is_bigblue: user.is_bigblue,
  })
    .then(() => {
      toast.success(`User update: ${user.email}`);
      dispatch(fetchUsers());
      dispatch(fetchUser());
      dispatch(usersSlice.actions.removeUpdate(user.id));
    })
    .catch((error) => toast.error(`Users update failed: ${error.message}`));
};

export const deleteUser = (email) => (dispatch) => {
  return Users.delete(email)
    .then(() => {
      toast.success(`User delete: ${email}`);
      dispatch(fetchUsers());
    })
    .catch((error) => toast.error(`Users update failed: ${error.message}`));
};
