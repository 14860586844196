import { RecoveryQueryRequest } from "api";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RecoveryQueriesTable } from "./components/RecoveryQueriesTable";
import { useSelector } from "react-redux";
import { getIsAdmin } from "slices/userSlice";

export const Historic = () => {
  const [recoveryQueryList, setRecoveryQueryList] = useState([]);
  const [recoveryQueryDisplayList, setRecoveryQueryDisplayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAdmin = useSelector(getIsAdmin);

  // move filterbyrole to a hook
  const filterByRole = useCallback((list) => {
    // return all for admin and only the ones that are not is_bigblue to the rest
    if (isAdmin) {
      return list;
    } else {
      return list.filter(item => !item.is_bigblue);
    }
  }, [isAdmin]);

  useEffect(() => {
    setRecoveryQueryDisplayList(filterByRole(recoveryQueryList));
  }, [filterByRole, isAdmin, recoveryQueryList]);



  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const request = new RecoveryQueryRequest();
        const response = await request.list();
        setRecoveryQueryList(response.recovery_queries);
        setRecoveryQueryDisplayList(filterByRole(response.recovery_queries));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [filterByRole]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Historic (last 7 days)</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <RecoveryQueriesTable data={recoveryQueryDisplayList} loading={loading} />
        </Col>
      </Row>
    </Container>
  );
};

