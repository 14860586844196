import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Octicon from "react-octicon";
import moment from "moment";
import { Button, ButtonGroup, Col, Form, InputGroup } from "react-bootstrap";
import ReactTable from "react-table-6";
import Select, { createFilter } from "react-select";
import Datetime from "react-datetime";
import { toast } from "react-toastify";

import crewsSlice from "slices/crewsSlice";
import {
  createTemporalRule,
  deleteTemporalRule,
  getActiveTemporalRulesByKind,
  getTemporalRulesByKind,
  getTemporalRulesLoading,
} from "slices/temporalRulesSlice";

import { hiddenCol, namedCol, roundDate, utcNowDate } from "utils";
import { rule_kinds } from "../index";
import { optimizeSelect } from "../../utils";

export const LimitationForm = ({ stations, scenarioUpdateFn }) => {
  const [crew_member, setCrewMember] = useState({});
  const [station, setStation] = useState({});
  const [time, setTime] = useState(
    roundDate(moment(utcNowDate()), "minute", 15)
  );
  const [duration, setDuration] = useState(moment("00:00", "HHmm"));
  const [start, setStart] = useState(moment(utcNowDate()));
  const [end, setEnd] = useState(moment(utcNowDate()));

  const crew_members = useSelector(crewsSlice.selectors.getCrews, shallowEqual);

  const dispatch = useDispatch();

  const createLimitation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!crew_member.value || !station.value) {
      toast.warning("WARNING: Select a crew member and a station.");
      return;
    }
    if (duration.format("HH:mm") === "00:00") {
      toast.warning("WARNING: Select a valid duration.");
      return;
    }
    dispatch(
      createTemporalRule({
        kind: rule_kinds.CREW_MEMBER_FINAL_SPACETIME_LIMITATION,
        data: {
          crew_member_id: `${crew_member.value}`,
          station: station.value,
          time: time.format("HH:mm"),
          duration: duration.format("HH:mm"),
        },
        valid_from: start.format("YYYY-MM-DD"),
        valid_to: end.format("YYYY-MM-DD"),
      }, scenarioUpdateFn)
    );
  };
  return (
    <Form
      onSubmit={createLimitation}
      style={{
        backgroundColor: "#d6d6d3",
        color: "#1a2e57",
        padding: "10px",
      }}
    >
      <Form.Row>
        <InputGroup as={Col}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Crew members</InputGroup.Text>
          </InputGroup.Prepend>
          <Select
            classNamePrefix="Select"
            value={crew_member}
            className="form-control"
            styles={{ container: () => ({ padding: 0 }) }}
            options={crew_members}
            onChange={setCrewMember}
            filterOption={createFilter({ ignoreAccents: false })}
            components={optimizeSelect.components}
          />
        </InputGroup>
        <InputGroup as={Col} md={3}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Stations</InputGroup.Text>
          </InputGroup.Prepend>
          <Select
            classNamePrefix="Select"
            value={station}
            className="form-control"
            styles={{ container: () => ({ padding: 0 }) }}
            options={stations}
            onChange={setStation}
            filterOption={createFilter({ ignoreAccents: false })}
            components={optimizeSelect.components}
          />
        </InputGroup>
        <Col md={1}>
          <Datetime
            value={time}
            initialViewMode="time"
            onChange={setTime}
            dateFormat={false}
            timeFormat="HH:mm"
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff" },
            }}
            timeConstraints={{ minutes: { step: 15 } }}
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Time</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
        <Col md={1}>
          <Datetime
            value={duration}
            onChange={setDuration}
            dateFormat={false}
            timeFormat="HH:mm"
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff", padding: "5px" },
            }}
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Duration</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
        <Col>
          <Datetime
            value={start}
            onChange={setStart}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff" },
            }}
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Start</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
        <Col>
          <Datetime
            value={end}
            inputProps={{
              readOnly: true,
              style: { backgroundColor: "#fff" },
            }}
            onChange={setEnd}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            renderInput={(props) => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">End</InputGroup.Text>
                </InputGroup.Prepend>
                <input {...props} />
              </InputGroup>
            )}
          />
        </Col>
        <Form.Group
          as={Col}
          className="text-center"
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexGrow: 0,
          }}
        >
          <Button variant="info" type="submit" style={{ width: "100%" }}>
            <Octicon name="plus" />
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export const LimitationTable = ({ active, scenarioUpdateFn }) => {
  const limitations = useSelector(
    getTemporalRulesByKind(rule_kinds.CREW_MEMBER_FINAL_SPACETIME_LIMITATION),
    shallowEqual
  );
  const active_limitations = useSelector(
    getActiveTemporalRulesByKind(
      rule_kinds.CREW_MEMBER_FINAL_SPACETIME_LIMITATION
    ),
    shallowEqual
  );
  const loading = useSelector(getTemporalRulesLoading, shallowEqual);
  const crew_members_mapping = useSelector(
    crewsSlice.selectors.getCrewMapping,
    shallowEqual
  );

  const dispatch = useDispatch();

  const columns = [
    hiddenCol("uid"),
    namedCol(
      "CrewMember",
      (d) => crew_members_mapping[d.data?.crew_member_id]?.label
    ),
    namedCol("Station", (d) => d.data?.station),
    namedCol("Time", (d) => d.data?.time),
    namedCol("Duration", (d) => d.data?.duration),
    namedCol("Start", (d) => d.valid_from),
    namedCol("End", (d) => d.valid_to),
    {
      id: "actions",
      Header: "Actions",
      style: { textAlign: "center" },
      Cell: (row) => (
        <ButtonGroup>
          <Button
            size="sm"
            onClick={() => {
              dispatch(deleteTemporalRule(row.original.uid, scenarioUpdateFn));
            }}
          >
            <Octicon name="trashcan" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <ReactTable
      multiSort
      loading={loading}
      data={active ? active_limitations : limitations}
      columns={columns}
      minRows={3}
      resizable={false}
      defaultPageSize={5}
      noDataText={"No user crew member final spacetime limitation found"}
    />
  );
};

export const CrewMemberFinalSpacetimeLimitation = ({
  stations,
  scenarioUpdateFn,
}) => {
  return (
    <>
      <LimitationForm stations={stations} scenarioUpdateFn={scenarioUpdateFn} />
      <LimitationTable scenarioUpdateFn={scenarioUpdateFn} />
    </>
  );
};
