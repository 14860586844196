import React, { useEffect, useState } from "react";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { toast } from "react-toastify";
import { Badge, Col, Container, Row } from "react-bootstrap";
import findIndex from "lodash/findIndex";
import ReactTable from "react-table-6";
import { hiddenCol, namedCol, simpleCol } from "utils";
import { Loader } from "components/common/Loader";
import { SvgViewer } from "components/common/viewers/SvgViewer";

import { ScenarioRequest } from "api/resources/ScenarioRequest";

import { BaseTable } from "../BaseTable";

const base_options = [
  { label: "Any", value: "all" },
  { label: "Without Problems", value: "without" },
  { label: "With Problems", value: "with" },
];

const activated_columns = [
  namedCol(
    "CrewMembers",
    (c) => c.CrewMembers?.join(", "),
    "CrewMembers",
    undefined,
    "left"
  ),
  simpleCol("NewDuty", undefined, "left"),
  simpleCol("DutyStart"),
  simpleCol("DutyEnd"),
];

const activated_sorted = [
  {
    id: "DutyStart",
    desc: true,
  },
];

export const CrewTables = ({
  base_scenario_uid,
  solution_scenario_uid,
  metrics,
  loading_metrics,
}) => {
  const [duties_d0, setDutiesD0] = useState([]);
  const [duties_d1, setDutiesD1] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCrewData = async () => {
      setLoading(true);
      try {
        const request = new ScenarioRequest();
        const response = await request.get_crew_changes(
          solution_scenario_uid,
          base_scenario_uid,
          { scope: "d0" }
        );
        setDutiesD0(response.crew);
        const request_d1 = new ScenarioRequest();
        const response_d1 = await request_d1.get_crew_changes(
          solution_scenario_uid,
          base_scenario_uid,
          { scope: "d1" }
        );
        setDutiesD1(response_d1.crew);
      } catch {
        console.warn("Problem fetching crew data");
      } finally {
        setLoading(false);
      }
    };
    if (!!base_scenario_uid && !!solution_scenario_uid) fetchCrewData();
  }, [base_scenario_uid, solution_scenario_uid]);

  return (
    <Container fluid>
      <h3>Affected crews</h3>
      <Row>
        <Col>
          <ModifiedDuties
            title="Crew Duties D0"
            duties={duties_d0}
            loading={loading}
            base_scenario_uid={base_scenario_uid}
            solution_scenario_uid={solution_scenario_uid}
          />

          <ModifiedDuties
            title="Crew Duties D1"
            duties={duties_d1}
            loading={loading}
            base_scenario_uid={base_scenario_uid}
            solution_scenario_uid={solution_scenario_uid}
          />
          <BaseTable
            title="Standbys Activated"
            data={metrics.StandbysActivated}
            loading={loading_metrics}
            defaultSorted={activated_sorted}
            columns={activated_columns}
          />
          <BaseTable
            title="Reserves Activated"
            data={metrics.ReservesActivated}
            loading={loading_metrics}
            defaultSorted={activated_sorted}
            columns={activated_columns}
          />
        </Col>
      </Row>
    </Container>
  );
};

const colorMapping = {
  Mispositioned: "#FF9D9A",
  RestPeriod: "#B6992D",
  CaptainDiscretion: "#A0CBE8",
  DelayedReporting: "#499894",
  ForcedChange: "#F28E2B",
  TimeOff: "#D4A6C8",
  ModifiedDuty: "#DCDCDC",
  IncreasedSectors: "#990099",
  FlightDutyPeriodWarning: "#FF7F24",
};

const ModifiedDutiesTable = ({
  duties = [],
  crew_text,
  loading,
  current_crew,
  fetchCrew,
}) => {
  const problem_options = React.useMemo(
    () =>
      uniq(flatten(duties.map((duty) => duty.Problems))).map((problem) => ({
        label: problem,
        value: problem,
      })),
    [duties]
  );
  const columns = React.useMemo(
    () => [
      simpleCol("CrewMemberLabel", 170, "left"),
      simpleCol("Role", 70),
      simpleCol("Base", 100),
      simpleCol("OriginalDuty", undefined, "left"),
      simpleCol("NewDuty", undefined, "left"),
      simpleCol("FinalStation", 100),
      namedCol("RestPeriod", (c) => c.RestPeriod, "Details", 150),
      {
        Header: "Tags",
        id: "Problems",
        accessor: "Problems",
        style: { textAlign: "center" },
        maxWidth: 300,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "with") {
            return !!row[filter.id]?.length;
          }
          if (filter.value === "without") {
            return !row[filter.id]?.length;
          }
          return (
            findIndex(row[filter.id], (o) => o.indexOf(filter.value) !== -1) !==
            -1
          );
        },
        Cell: ({ row }) => {
          return (
            <div>
              {row.Problems?.map((problem) => (
                <Badge
                  key={problem}
                  style={{
                    backgroundColor: colorMapping[problem] || "",
                    marginRight: "5px",
                  }}
                  variant="secondary"
                >
                  {problem}
                </Badge>
              ))}
            </div>
          );
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            {[...base_options, ...problem_options].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ),
      },
      hiddenCol("CrewMemberID"),
      hiddenCol("IsMispositioned"),
      hiddenCol("ViolatingRestPeriod"),
      hiddenCol("FDPRisk"),
      hiddenCol("RequireCaptainExtension"),
      hiddenCol("RequireDelayedCheckin"),
      hiddenCol("HasForcedChange"),
    ],
    [problem_options]
  );
  let defaultFiltered = [];
  if (crew_text) {
    defaultFiltered = [{ id: "NewDuty", value: crew_text.split("-")[1] || "" }];
  }

  return (
    <ReactTable
      data={duties}
      style={{ marginBottom: "20px" }}
      resizable={false}
      filterable
      defaultFiltered={defaultFiltered}
      defaultFilterMethod={(filter, row) =>
        row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()) > -1
      }
      FilterComponent={({ filter, onChange, column }) => (
        <input
          type="text"
          style={{
            width: "100%",
          }}
          placeholder={column.Placeholder}
          value={filter ? filter.value : ""}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        />
      )}
      columns={columns}
      minRows={3}
      loading={loading}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "OriginalDuty",
          desc: false,
        },
        {
          id: "NewDuty",
          desc: false,
        },
      ]}
      getTrProps={(state, rowInfo, b) => {
        let dh_filter_new =
          state.filtered.find((filter) => filter.id === "NewDuty")?.value +
          "(DH)";
        let dh_filter_original =
          state.filtered.find((filter) => filter.id === "OriginalDuty")?.value +
          "(DH)";
        let new_duty_dh =
          rowInfo && rowInfo.row.NewDuty.indexOf(dh_filter_new) > -1;
        let original_duty_dh =
          rowInfo && rowInfo.row.NewDuty.indexOf(dh_filter_original) > -1;
        return {
          style: {
            borderTop:
              rowInfo && rowInfo.row.CrewMemberID === current_crew
                ? "3px blue solid"
                : "",
            borderBottom:
              rowInfo && rowInfo.row.CrewMemberID === current_crew
                ? "3px blue solid"
                : "",
            backgroundColor: new_duty_dh || original_duty_dh ? "grey" : "",
          },
          onClick: () => fetchCrew(rowInfo.row.CrewMemberID),
        };
      }}
    />
  );
};

export const ModifiedDuties = ({
  title = "Crew Duties",
  duties,
  loading,
  base_scenario_uid,
  solution_scenario_uid,
  crew_text,
}) => {
  const [current_crew, setCrew] = useState();
  const [duty_image, setImage] = useState();
  const [loading_image, setLoading] = useState(false);

  const fetchCrew = async (crew_id) => {
    setCrew(crew_id);
    setLoading(true);
    try {
      const request = new ScenarioRequest();
      const response = await request.get_crew_duty_diff_svg(
        solution_scenario_uid,
        base_scenario_uid,
        crew_id
      );
      setImage(response);
    } catch (err) {
      toast.error(`Crew diff duty: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>{title}</h4>
      </div>
      <ModifiedDutiesTable
        duties={duties}
        loading={loading}
        current_crew={current_crew}
        fetchCrew={fetchCrew}
        crew_text={crew_text}
      />
      {(loading_image || !!duty_image) && (
        <div
          style={{ minHeight: "200px", position: "relative", margin: "25px 0" }}
        >
          <Loader show={loading_image} message="Loading crew duty ..." />
          <SvgViewer show_zoom_selector svg={duty_image} />
        </div>
      )}
    </>
  );
};
