import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import { namedCol, simpleCol } from "utils";

import { ScenarioRequest } from "api/resources/ScenarioRequest";

import { BaseTable } from "../BaseTable";
import { AircraftRegBalanceTable } from "./components/AircraftRegBalanceTable";
import { AircraftFleetBalanceTable } from "./components/AircraftFleetBalanceTable";

export const AircraftTables = ({
  base_scenario_uid,
  solution_scenario_uid,
  metrics,
  scope,
  loading_metrics,
}) => {
  const [aircrafts, setAircrafts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCrewData = async () => {
      setLoading(true);
      try {
        const request = new ScenarioRequest();
        const response = await request.get_aircraft_changes(
          solution_scenario_uid,
          base_scenario_uid,
          { scope }
        );
        setAircrafts(response.aircrafts);
      } catch (err) {
        toast.error(`Aircraft fetch failed: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    if (!!base_scenario_uid && !!solution_scenario_uid) fetchCrewData();
  }, [base_scenario_uid, solution_scenario_uid, scope]);

  return (
    <Container fluid>
      <Row>
        <Col>
          {metrics.AircraftFleetBalanceTable || metrics.FleetBalance ? (
            <>
              <AircraftRegBalanceTable
                data={metrics.RegistrationNumberBalance}
              />
              <AircraftFleetBalanceTable data={metrics.FleetBalance} />
            </>
          ) : (
            <h4>No aircraft overnight station changes</h4>
          )}
          <BaseTable
            title="Aircrafts Reassigned"
            data={aircrafts}
            loading={loading}
            columns={[
              simpleCol("AircraftID"),
              namedCol(
                "OriginalRotation",
                (c) => c.OriginalRotation?.join(", "),
                "OriginalRotation"
              ),
              namedCol(
                "NewRotation",
                (c) => c.NewRotation?.join(", "),
                "NewRotation"
              ),
            ]}
          />
          <BaseTable
            title="Changed Fleet Flights"
            data={metrics.ChangedFleetFlights}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("OriginalFleet"),
              simpleCol("NewFleet"),
            ]}
          />
          <BaseTable
            title="Positional Created"
            data={metrics.PositionalCreated}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("STD"),
            ]}
          />
          <BaseTable
            title="Post Diversion Legs"
            data={metrics.post_diversion_created}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("STD"),
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};
