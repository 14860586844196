export const primary_metrics = (currencyFormatter) => [
  {
    accessor: (c) => currencyFormatter.format(c.TotalCost),
    name: "Cost",
    show: true,
    units: "",
  },
  { accessor: "TotalOTP180Violations", name: "OTP 180", show: true, units: "" },
  { accessor: "TotalOTP120Violations", name: "OTP 120", show: true, units: "" },
  { accessor: "TotalOTP15Violations", name: "OTP 15", show: true, units: "" },
  { accessor: "otp180_risk", name: "OTP 180 Risk", show: true, units: "" },
];

export const flight_metrics = [
  { accessor: "TotalCancellations", name: "CNL", show: true, units: "" },
  {
    accessor: "TotalRescheduledFlights",
    name: "Rescheduled",
    show: true,
    units: "",
  },
  {
    accessor: "TotalReroutedFlights",
    name: "Rerouted Flights",
    show: true,
    units: "",
  },
  {
    accessor: "TotalDivertedFlights",
    name: "Diverted Flights",
    show: true,
    units: "",
  },
];

export const aircraft_metrics = [
  {
    accessor: "TotalPositionalCreated",
    name: "New Positionals",
    show: true,
    units: "",
  },
  {
    accessor: "total_post_diversion_created",
    name: "Post Diversion ",
    show: true,
    units: "",
  },
  {
    accessor: "TotalChangedFleetFlights",
    name: "Fleet Changes",
    show: true,
    units: "",
  },
  {
    accessor: "TotalPositionalCanceled",
    name: "Positional Canceled",
    show: true,
    units: "",
  },
  {
    accessor: "TotalMispositionedAircrafts",
    name: "Reg. Balance",
    show: true,
    units: "",
  },
  {
    accessor: "TotalOffbalance",
    name: "Fleet Balance",
    show: true,
    units: "",
  },
  {
    accessor: "TotalAircraftsReassigned",
    name: "Reassigned Aircrafts",
    show: true,
    units: "",
  },
  {
    accessor: "TotalCanceledMaintenances",
    name: "Canceled Maintenances",
    show: true,
    units: "",
  },
];

export const crew_metrics = [
  {
    accessor: "TotalCrewMembersReassigned",
    name: "Reassigned Crew",
    show: true,
    units: "",
  },
  {
    accessor: "total_activated_standbys",
    name: "Standbys Activated",
    show: true,
    units: "",
  },
  {
    accessor: "total_activated_reserves",
    name: "Reserves Activated",
    show: true,
    units: "",
  },
  {
    accessor: "TotalCanceledExtraCrewActivities",
    name: "Crew Activities Cancelled ",
    show: true,
    units: "",
  },
  {
    accessor: "TotalCreatedGroundTransports",
    name: "Ground Transports Created",
    show: true,
    units: "",
  },
  {
    accessor: "total_crews_requiring_extension",
    name: "Crews Requiring Extension",
    show: true,
    units: "",
  },
  {
    accessor: "total_affected_rest_periods",
    name: "Affected Rest Periods",
    show: true,
    units: "",
  },
  {
    accessor: "TotalMispositionedCrewMembers",
    name: "Mispositioned Crew",
    show: true,
    units: "",
  },
];

export const pax_metrics = [
  {
    accessor: "total_overbooked_pax",
    name: "Overbook PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_overbooked_rebooked_pax",
    name: "OBV - Rebooked PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_canceled_pax",
    name: "Cancelled PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_canceled_rebooked_pax",
    name: "CNL - Rebooked PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_misconnected_pax",
    name: "Misconnected PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_mcnx_rebooked_pax",
    name: "MCNX - Rebooked PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_island_diverted_pax",
    name: "Diverted PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_rebooked_pax_idiv",
    name: "All DIV - Rebooked PAX",
    show: true,
    units: "",
  },
  {
    accessor: "total_pax_affected_by_delays",
    name: "PAX Affected by Delay",
    show: true,
    units: "",
  },
];
