import {
  deleteCookies,
  getCSRFCookie,
  isLocalhost,
} from "utils";
import { DateTime } from "luxon";

const DEFAULT_CONNECTION = "Default";

export const get_host = () => {
  let host = `${window.location.origin}/api`;
  if (isLocalhost) {
    host = "http://localhost:9200";
  }
  return host;
};

export const HOST = get_host();

export const httpMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const responseDispatcher = (resp) =>
  resp.status === 200 ? resp : Promise.reject(resp);

const responseJsonizer = (resp) => (resp.json && resp.json()) || {};
const responseToText = (resp) => (resp.text && resp.text()) || "";

export const errorHandler = (err) => {
  if (err.status === 401) {
    deleteCookies();
    window.history.go("/login");
  }
  if (err.json) {
    return err.json().then((result) => Promise.reject(result));
  }
  return Promise.reject(err);
};

export const authorizedRequestCancel = (
  path = "",
  method = httpMethods.GET,
  body = undefined,
  params = {}
) => {
  const url = new URL(HOST + path);
  const connection = window.localStorage?.getItem("occam_connection");
  if (connection && connection !== DEFAULT_CONNECTION) {
    params = { ...params, conn: connection };
  }
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  const controller = new AbortController();
  const signal = controller.signal;
  return {
    rq: fetch(url, {
      method: method,
      credentials: "include",
      signal,
      headers: {
        "Content-Type": "application/json",
        ...(method !== httpMethods.GET
          ? { "X-XSRF-Token": getCSRFCookie() }
          : {}),
      },
      body: body && JSON.stringify(body),
    })
      .then(responseDispatcher)
      .then(responseJsonizer)
      .then((resp) => resp.result || resp)
      .catch(errorHandler),
    c: controller,
  };
};

export const authorizedRequest = (
  path = "",
  method = httpMethods.GET,
  body = undefined,
  params = {}
) => {
  const url = new URL(HOST + path);
  const connection = window.localStorage?.getItem("occam_connection");
  if (connection && connection !== DEFAULT_CONNECTION) {
    params = { ...params, conn: connection };
  }
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return fetch(url, {
    method: method,
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      ...(method !== httpMethods.GET
        ? { "X-XSRF-Token": getCSRFCookie() }
        : {}),
    },
    body: body && JSON.stringify(body),
  })
    .then(responseDispatcher)
    .then(responseJsonizer)
    .then((resp) => resp.result || resp)
    .catch(errorHandler);
};

export class LocalCacheGroup {
  constructor(key) {
    if (!window.localStorage) {
      throw new Error("LocalCacheGroup is not supported ..");
    }
    if (!key) {
      throw new Error("Key was not provided ..");
    }
    this._key = `${key.toUpperCase()}.`;
  }

  _prepareKey(key) {
    return this._key + key;
  }

  get(key) {
    key = this._prepareKey(key);

    let result = localStorage.getItem(key);
    if (result) {
      result = JSON.parse(result);
      if (!result.expire) return result.value;
      const now = DateTime.utc();
      const expire = DateTime.fromISO(result.expire, { zone: "utc" });
      if (expire >= now) return result.value;
      localStorage.removeItem(key);
    }
    return;
  }

  add(key, value, duration = { days: 7 }) {
    const item = {
      key: this._prepareKey(key),
      value,
      expire: DateTime.utc().plus(duration),
    };

    try {
      localStorage.setItem(item.key, JSON.stringify(item));
    } catch (e) {
      if (this._isQuotaExceeded(e)) {
        this._clearOldKeys();
      }
    }
  }

  _isQuotaExceeded(error) {
    // All browsers
    if (error?.code === 22) return true;
    // Firefox
    if (error?.code === 1014 && error?.name === "NS_ERROR_DOM_QUOTA_REACHED")
      return true;
    // IE
    if (error?.code === -2147024882) return true;
    return false;
  }

  _clearOldKeys() {
    const to_clear = Math.ceil(localStorage.length * 0.2);
    const key_values = Object.entries(localStorage)
      .filter((entry) => entry[0].includes(this._key)) // Check Entry key
      .map((entry) => entry[1]) // Get Entry value
      .map((value) => JSON.parse(value)); // Parse value

    const num_to_clear =
      key_values.length > to_clear ? to_clear : key_values.length - 1;

    key_values
      .sort((a, b) => new Date(a.expire) > new Date(b.expire)) // Sort by expriration date
      .slice(0, num_to_clear) // Select the oldest ones
      .map((value) => value.key) // Get keys to delete
      .forEach((key) => localStorage.removeItem(key));
  }

  clear() {
    for (let key in localStorage) {
      if (key.includes(this._key)) {
        localStorage.removeItem(key);
      }
    }
  }

  delete(key) {
    key = this._prepareKey(key);
    localStorage.removeItem(key);
  }
}

export class NullCacheGroup {
  add() {
    return;
  }
  get() {
    return;
  }
}

export class RequestBuilder {
  constructor() {
    this.controller = new AbortController();
    this.cache_group = new NullCacheGroup();
  }

  abort() {
    this.controller.abort && this.controller.abort();
  }

  _get_url(path) {
    return new URL(HOST + path);
  }

  async _get(path, params = {}) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    let result = this.cache_group.get(url.href);
    if (result !== undefined) return result;
    try {
      const resp = await fetch(url, {
        method: httpMethods.GET,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseJsonizer(resp_1);
      result = resp_2.result || resp_2;
      this.cache_group.add(url.href, result);
      return result;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _getXLSX(path, params = {}) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    let result = this.cache_group.get(url.href);
    if (result !== undefined) return result;
    try {
      const resp = await fetch(url, {
        method: httpMethods.GET,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        },
      });
      return resp;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _postXLSX(path, file, body) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    const fd = new FormData();
    fd.append("spreadsheet", file);
    fd.append("body", JSON.stringify(body));
    try {
      const resp = await fetch(url, {
        method: httpMethods.POST,
        credentials: "include",
        headers: {
          "X-XSRF-Token": getCSRFCookie(),
        },
        body: fd,
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseJsonizer(resp_1);
      return resp_2.result || resp_2;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _getSvg(path, params = {}) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    Object.keys(params).forEach((key) => {
      let value = params[key];
      if (Array.isArray(params[key])) value = JSON.stringify(params[key]);
      url.searchParams.append(key, value);
    });
    let result = this.cache_group.get(url.href);
    if (result !== undefined) return result;
    try {
      const resp = await fetch(url, {
        method: httpMethods.GET,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseToText(resp_1);
      result = resp_2.result || resp_2;
      this.cache_group.add(url.href, result);
      return result;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _post(path, body = {}) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    try {
      const resp = await fetch(url, {
        method: httpMethods.POST,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-Token": getCSRFCookie(),
        },
        body: JSON.stringify(body),
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseJsonizer(resp_1);
      return resp_2.result || resp_2;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _patch(path, body = {}) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    try {
      const resp = await fetch(url, {
        method: httpMethods.PATCH,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-Token": getCSRFCookie(),
        },
        body: JSON.stringify(body),
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseJsonizer(resp_1);
      return resp_2.result || resp_2;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }

  async _delete(path) {
    if (!path) throw new Error("Missing URL path");
    const url = this._get_url(path);
    try {
      const resp = await fetch(url, {
        method: httpMethods.DELETE,
        signal: this.controller.signal,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-XSRF-Token": getCSRFCookie(),
        },
      });
      const resp_1 = await responseDispatcher(resp);
      const resp_2 = await responseJsonizer(resp_1);
      return resp_2.result || resp_2;
    } catch (err) {
      if (err && err.name === "AbortError") return Promise.reject(err);
      return errorHandler(err);
    }
  }
}
