import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { StatsTable } from "../StatsNav/components/StatsTable";
import { columns_memos } from "../StatsNav/components/columns";

export const Memos = ({ show, memos }) => {
  return (
    <Container fluid style={{ display: show ? "block" : "none" }}>
      <Row>
        <Col>
          <StatsTable
            title="Memos"
            data={memos?.filter((memo) => !!memo.Memo)}
            minRows={3}
            columns={columns_memos}
          />
        </Col>
      </Row>
    </Container>
  );
};
