import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deepMemo } from "utils";
import { Button, Container, Modal, Table } from "react-bootstrap";
import styles from "./index.module.css";

import {
  pullStats,
  pullQueuedJobs,
  pullCanceledJobs,
  pullDeferredJobs,
  pullFailedJobs,
  pullFinishedJobs,
  pullScheduledJobs,
  pullStartedJobs,
  pullJobDetails,
  pullCancelJob,
  pullRequeueJob,
  selectStats,
  selectQueuedJobs,
  selectCanceledJobs,
  selectDeferredJobs,
  selectFailedJobs,
  selectFinishedJobs,
  selectScheduledJobs,
  selectStartedJobs,
  selectJobDetails,
} from "slices/queues/queuesSlice";

export const Queues = deepMemo(() => {
  const [job_details_id, setJobDetailsId] = useState();
  const [show_modal, setShowModal] = useState(false);

  const stats = useSelector(selectStats, shallowEqual);
  const queued_jobs = useSelector(selectQueuedJobs, shallowEqual);
  const canceled_jobs = useSelector(selectCanceledJobs, shallowEqual);
  const deferred_jobs = useSelector(selectDeferredJobs, shallowEqual);
  const failed_jobs = useSelector(selectFailedJobs, shallowEqual);
  const finished_jobs = useSelector(selectFinishedJobs, shallowEqual);
  const scheduled_jobs = useSelector(selectScheduledJobs, shallowEqual);
  const started_jobs = useSelector(selectStartedJobs, shallowEqual);
  const job_details = useSelector(
    selectJobDetails(job_details_id),
    shallowEqual
  );

  const dispatch = useDispatch();

  const queues_list = Object.keys(stats?.queues || {});

  const onRefreshQueue = useCallback(
    (queue_name) => {
      dispatch(pullQueuedJobs(queue_name));
      dispatch(pullCanceledJobs(queue_name));
      dispatch(pullDeferredJobs(queue_name));
      dispatch(pullFailedJobs(queue_name));
      dispatch(pullFinishedJobs(queue_name));
      dispatch(pullScheduledJobs(queue_name));
      dispatch(pullStartedJobs(queue_name));
    },
    [dispatch]
  );

  const onCancelJob = (queue_name, job_id) => {
    dispatch(pullCancelJob(queue_name, job_id));
    onRefreshQueue(queue_name);
  };

  const onRequeueJob = (queue_name, job_id) => {
    dispatch(pullRequeueJob(queue_name, job_id));
    onRefreshQueue(queue_name);
  };

  const onRefreshStats = useCallback(() => {
    dispatch(pullStats());
  }, [dispatch]);

  useEffect(() => {
    dispatch(pullStats());
  }, [dispatch]);

  const renderJobs = (jobs = [], queue) =>
    jobs.map((job_id) => (
      <span
        key={job_id}
        onClick={() => {
          setJobDetailsId(job_id);
          setShowModal(true);
          dispatch(pullJobDetails(queue, job_id));
        }}
        style={{ cursor: "pointer" }}
      >
        {job_id},{" "}
      </span>
    ));
  return (
    <Container>
      <h1>RQ Dashboard</h1>
      <Button onClick={() => onRefreshStats()}>Refresh Stats</Button>
      <div>
        <strong>Global Stats</strong>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Failed jobs</th>
              <th>Successful jobs</th>
              <th>Total working time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{stats.failed_job_count}</td>
              <td>{stats.successful_job_count}</td>
              <td>{stats.total_working_time}</td>
            </tr>
          </tbody>
        </Table>
        <strong>Worker Stats</strong>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>name</th>
              <th>queues</th>
              <th>state</th>
              <th>current_job</th>
              <th>version</th>
              <th>python_version</th>
            </tr>
          </thead>
          <tbody>
            {stats.workers?.map((worker) => (
              <tr key={worker.name}>
                <td>{worker.name}</td>
                <td>{worker.queues.join(", ")}</td>
                <td>{worker.state}</td>
                <td>{worker.current_job?.description}</td>
                <td>{worker.version}</td>
                <td>{worker.python_version}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <strong>Queue Stats</strong>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Queue</th>
              <th>Canceled</th>
              <th>Deferred</th>
              <th>Failed</th>
              <th>Finished</th>
              <th>Queued</th>
              <th>Scheduled</th>
              <th>Started</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(stats?.queues || {}).map((queue) => (
              <tr key={queue}>
                <td>{queue}</td>
                <td>{stats.queues[queue].canceled}</td>
                <td>{stats.queues[queue].deferred}</td>
                <td>{stats.queues[queue].failed}</td>
                <td>{stats.queues[queue].finished}</td>
                <td>{stats.queues[queue].queued}</td>
                <td>{stats.queues[queue].scheduled}</td>
                <td>{stats.queues[queue].started}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {queues_list.map((queue) => (
        <Table striped bordered hover size="sm" key={queue}>
          <thead>
            <tr>
              <th>{queue}</th>
              <th>
                <Button onClick={() => onRefreshQueue(queue)}>
                  Refresh {queue} queue
                </Button>
              </th>
            </tr>
            <tr>
              <th>Status</th>
              <th>Tasks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Queued</td>
              <td>{renderJobs(queued_jobs[`${queue}`], queue)}</td>
            </tr>
            <tr>
              <td>Canceled</td>
              <td>{renderJobs(canceled_jobs[`${queue}.canceled`], queue)}</td>
            </tr>
            <tr>
              <td>Deferred</td>
              <td>{renderJobs(deferred_jobs[`${queue}.deferred`], queue)}</td>
            </tr>
            <tr>
              <td>Failed</td>
              <td>{renderJobs(failed_jobs[`${queue}.failed`], queue)}</td>
            </tr>
            <tr>
              <td>Finished</td>
              <td>{renderJobs(finished_jobs[`${queue}.finished`], queue)}</td>
            </tr>
            <tr>
              <td>Scheduled</td>
              <td>{renderJobs(scheduled_jobs[`${queue}.scheduled`], queue)}</td>
            </tr>
            <tr>
              <td>Started</td>
              <td>{renderJobs(started_jobs[`${queue}.started`], queue)}</td>
            </tr>
          </tbody>
        </Table>
      ))}
      <Modal
        dialogClassName={styles.Dialog}
        size="lg"
        show={show_modal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Job {job_details_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(job_details || {}).map((key, index) => {
                return key === "exc_info" ? (
                  <tr key={index}>
                    <td>{key}</td>
                    <td colSpan="2">
                      <pre>{job_details[key]}</pre>
                    </td>
                  </tr>
                ) : (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>
                      {typeof job_details[key] === "object"
                        ? JSON.stringify(job_details[key])
                        : job_details[key]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {["queued", "started"].indexOf(job_details?.status) >= 0 ? (
            <Button
              variant="danger"
              onClick={() => onCancelJob(job_details.origin, job_details_id)}
            >
              Cancel Job
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => onRequeueJob(job_details.origin, job_details_id)}
            >
              Requeue Job
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
});
