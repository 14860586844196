import React from "react";
import { Navbar } from "react-bootstrap";
import { deepMemo } from "utils";

export const ErrorNav = deepMemo(({ error, bg = "danger" }) => {
  return (
    (error && (
      <Navbar bg={bg} variant="dark">
        <Navbar.Brand>{error}</Navbar.Brand>
      </Navbar>
    )) ||
    null
  );
});
