import React from "react";

import { SchedulerSVGViewer } from "components/common/viewers/SchedulerSVGViewer";
import { Loader } from "../Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  pullScenarioSchedule,
  selectScenarioBrokenSchedule,
  selectScenarioSchedule,
  selectScenarioScheduleDiff,
  selectScenarioScheduleDiffLoading,
  selectScenarioScheduleLoading,
} from "slices/scenarios/scenariosSlice";
import { deepMemo, useDeepCompareEffect } from "utils";

export const ScenarioSchedulerViewer = deepMemo(
  ({
    scenario_uid,
    hide,
    sorting,
    filters,
    show_diff,
    base_scenario_uid,
    ...rest
  }) => {
    const loading = useSelector(
      selectScenarioScheduleLoading(scenario_uid),
      shallowEqual
    );
    const loading_diff = useSelector(
      selectScenarioScheduleDiffLoading(scenario_uid),
      shallowEqual
    );
    const schedule_image = useSelector(
      selectScenarioSchedule(scenario_uid),
      shallowEqual
    );
    const broken_schedule_image = useSelector(
      selectScenarioBrokenSchedule(scenario_uid),
      shallowEqual
    );
    const diff_schedule_image =
      useSelector(selectScenarioScheduleDiff(scenario_uid), shallowEqual) ||
      "<center><h2>No schedule changes.</h2></center>";

    const has_active_filters = !!Object.keys(filters?.filters || []).filter(
      (key) => filters.filters[key] && filters.filters[key].active
    ).length;

    const show_spinner = show_diff ? loading_diff : loading;
    const dispatch = useDispatch();
    useDeepCompareEffect(() => {
      if (
        !show_diff &&
        scenario_uid &&
        !loading &&
        !schedule_image &&
        !broken_schedule_image
      ) {
        dispatch(pullScenarioSchedule(scenario_uid, base_scenario_uid));
      }
    }, [
      dispatch,
      loading,
      schedule_image,
      broken_schedule_image,
      scenario_uid,
      show_diff,
    ]);

    return (
      <>
        <Loader show={show_spinner} />
        <SchedulerSVGViewer
          hide={hide || has_active_filters || show_diff}
          svg={broken_schedule_image}
          {...rest}
        />
        <SchedulerSVGViewer
          hide={hide}
          svg={show_diff ? diff_schedule_image : schedule_image}
          {...rest}
        />
      </>
    );
  }
);
