import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { deepCompareEquals, mergeByKey } from "utils";

import {
  fetchScenarioInfo,
  fetchScenarioSchedule,
  fetchScenarioBrokenSchedule,
  fetchScenarioScheduleDiff,
  fetchScenarioRoster,
  fetchScenarioBrokenRoster,
  fetchScenarioRosterDiff,
  fetchScenarioProblems,
  fetchScenarioMemos,
  fetchScenarioFlightCrews,
  fetchScenarioWarningsD0,
  fetchScenarioWarningsD1,
  fetchScenarioStandbys,
  fetchScenarioCurfew,
  fetchScenarioFlightCrewDutyInfo,
  fetchScenarioDutiesD1,
  fetchScenarioDutiesD0,
} from "./fetch";

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  deepCompareEquals,
  deepCompareEquals
);

const scenariosAdapter = createEntityAdapter({
  selectId: (scenario) => scenario.uid,
});

const initialState = scenariosAdapter.getInitialState();

export const pullScenario = (scenario_uid) => (dispatch) => {
  if (scenario_uid) {
    dispatch(pullScenarioSchedule(scenario_uid));
  }
};

export const pullScenarioData = (scenario_uid) => (dispatch) => {
  if (scenario_uid) {
    dispatch(fetchScenarioInfo({ scenario_uid }));
    dispatch(fetchScenarioWarningsD0({ scenario_uid }));
    dispatch(fetchScenarioWarningsD1({ scenario_uid }));
    dispatch(fetchScenarioStandbys({ scenario_uid }));
    dispatch(fetchScenarioCurfew({ scenario_uid }));
  }
};

export const pullScenarioScheduleWithFilters =
  (scenario_uid, base_scenario_uid, params) => (dispatch) => {
    if (scenario_uid && base_scenario_uid) {
      dispatch(
        fetchScenarioSchedule({
          scenario_uid,
          params: { ...params, base_scenario_uid },
        })
      );
    } else if (scenario_uid) {
      dispatch(fetchScenarioSchedule({ scenario_uid, params }));
    }
  };

export const pullScenarioSchedule =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    if (scenario_uid && base_scenario_uid) {
      dispatch(
        fetchScenarioSchedule({ scenario_uid, params: { base_scenario_uid } })
      );
      dispatch(
        fetchScenarioBrokenSchedule({
          scenario_uid,
          params: { base_scenario_uid },
        })
      );
    } else if (scenario_uid) {
      dispatch(fetchScenarioSchedule({ scenario_uid }));
      dispatch(fetchScenarioBrokenSchedule({ scenario_uid }));
    }
  };

export const pullScenarioSolution =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    dispatch(fetchScenarioScheduleDiff({ scenario_uid, base_scenario_uid }));
  };

export const pullScenarioSolutionData =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    dispatch(fetchScenarioStandbys({ scenario_uid }));
    dispatch(fetchScenarioInfo({ scenario_uid }));
    dispatch(fetchScenarioWarningsD0({ scenario_uid, base_scenario_uid }));
    dispatch(fetchScenarioWarningsD1({ scenario_uid, base_scenario_uid }));
    dispatch(fetchScenarioCurfew({ scenario_uid }));
  };

export const pullScenarioRoster =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    if (scenario_uid && base_scenario_uid) {
      dispatch(
        fetchScenarioRoster({ scenario_uid, params: { base_scenario_uid } })
      );
      dispatch(
        fetchScenarioBrokenRoster({
          scenario_uid,
          params: { base_scenario_uid },
        })
      );
    } else if (scenario_uid) {
      dispatch(fetchScenarioRoster({ scenario_uid }));
      dispatch(fetchScenarioBrokenRoster({ scenario_uid }));
    }
    if (base_scenario_uid) {
      dispatch(fetchScenarioRosterDiff({ scenario_uid, base_scenario_uid }));
    }
  };

export const pullFlightsData =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    if (scenario_uid) {
      if (base_scenario_uid) {
        dispatch(fetchScenarioProblems({ scenario_uid, base_scenario_uid }));
      } else {
        dispatch(fetchScenarioProblems({ scenario_uid }));
      }
      dispatch(fetchScenarioMemos({ scenario_uid }));
      dispatch(fetchScenarioFlightCrews({ scenario_uid }));
    }
  };

export const pullScenarioDuties =
  (scenario_uid, base_scenario_uid) => (dispatch) => {
    dispatch(fetchScenarioDutiesD0({ scenario_uid, base_scenario_uid }));
    dispatch(fetchScenarioDutiesD1({ scenario_uid, base_scenario_uid }));
  };

export const pullCrewDutyInfo =
  (scenario_uid, flight, base_scenario_uid) => (dispatch) => {
    dispatch(
      fetchScenarioFlightCrewDutyInfo({
        scenario_uid,
        flight,
        base_scenario_uid,
      })
    );
  };

const buildFetchCases = (builder, thunk, name) => {
  builder
    .addCase(thunk.fulfilled, (state, action) => {
      const { uid, result } = action.payload;
      if (!state.entities[uid]) state.entities[uid] = {};
      if (!state.entities[uid][name]) state.entities[uid][name] = {};
      state.entities[uid][name].loading = false;
      state.entities[uid][name].data = result;
    })
    .addCase(thunk.rejected, (state, action) => {
      const { uid } = action.payload;
      if (!state.entities[uid]) state.entities[uid] = {};
      if (!state.entities[uid][name]) state.entities[uid][name] = {};
      state.entities[uid][name].loading = false;
      state.entities[uid][name].error = true;
    })
    .addCase(thunk.pending, (state, action) => {
      const {
        arg: { scenario_uid },
      } = action.meta;
      if (!state.entities[scenario_uid]) state.entities[scenario_uid] = {};
      if (!state.entities[scenario_uid][name])
        state.entities[scenario_uid][name] = {};
      state.entities[scenario_uid][name].loading = true;
    });
};

export const scenariosSlice = createSlice({
  name: "scenarios",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildFetchCases(builder, fetchScenarioSchedule, "schedule");
    buildFetchCases(builder, fetchScenarioBrokenSchedule, "broken_schedule");
    buildFetchCases(builder, fetchScenarioScheduleDiff, "schedule_diff");
    buildFetchCases(builder, fetchScenarioRoster, "roster");
    buildFetchCases(builder, fetchScenarioBrokenRoster, "broken_roster");
    buildFetchCases(builder, fetchScenarioRosterDiff, "roster_diff");
    buildFetchCases(builder, fetchScenarioCurfew, "curfew");
    buildFetchCases(builder, fetchScenarioInfo, "info");
    buildFetchCases(builder, fetchScenarioProblems, "problems");
    buildFetchCases(builder, fetchScenarioMemos, "memos");
    buildFetchCases(builder, fetchScenarioFlightCrews, "flight_crews");
    buildFetchCases(builder, fetchScenarioWarningsD0, "warnings_d0");
    buildFetchCases(builder, fetchScenarioWarningsD1, "warnings_d1");
    buildFetchCases(builder, fetchScenarioStandbys, "standbys");
    buildFetchCases(builder, fetchScenarioDutiesD0, "duties_d0");
    buildFetchCases(builder, fetchScenarioDutiesD1, "duties_d1");
    builder.addCase(
      fetchScenarioFlightCrewDutyInfo.fulfilled,
      (state, action) => {
        const { uid, flight, result } = action.payload;
        scenariosAdapter.upsertOne(state, {
          uid,
          flight_crews_duties: {
            ...(state.entities[uid]?.flight_crews_duties?.data || {}),
            [flight]: result,
          },
        });
      }
    );
  },
});

const scenarioSelectors = scenariosAdapter.getSelectors(
  (state) => state.scenarios
);

export const selectScenarioByUid = (uid) => (state) =>
  scenarioSelectors.selectById(state, uid);

export const selectScenarioInfo = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.info,
    (state) => state
  );

export const selectScenarioProblems = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.problems,
    (state) => state
  );

export const selectScenarioFlightCrewsLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.flight_crews,
    (state) => state?.loading
  );

export const selectScenarioFlightCrews = (uid, flight) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.flight_crews,
    (state) => state.scenarios.entities[uid]?.flight_crews_duties,
    (flight_crews = {}, flight_crews_duties = {}) => {
      if (
        flight_crews?.data &&
        flight_crews?.data[flight] &&
        flight_crews_duties[flight]
      ) {
        return mergeByKey(
          flight_crews?.data[flight].map((obj) => Object.assign({}, obj)),
          flight_crews_duties[flight].map((obj) => Object.assign({}, obj)),
          "CrewID"
        );
      } else {
        return (flight_crews?.data && flight_crews?.data[flight]) || [];
      }
    }
  );

export const selectScenarioMemos = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.memos,
    (state) => state
  );

export const selectScenarioWarningsD0 = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.warnings_d0,
    (state) => state
  );

export const selectScenarioWarningsD1 = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.warnings_d1,
    (state) => state
  );

export const selectScenarioStandbys = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.standbys,
    (state) => state
  );

export const selectScenarioCurfew = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.curfew,
    (state) => state
  );

export const selectScenarioSchedule = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.schedule,
    (state) => state?.data
  );

export const selectScenarioBrokenSchedule = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.broken_schedule,
    (state) => state?.data
  );

export const selectScenarioScheduleDiff = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.schedule_diff,
    (state) => state?.data
  );

export const selectScenarioRoster = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.roster,
    (state) => state?.data
  );

export const selectScenarioBrokenRoster = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.broken_roster,
    (state) => state?.data
  );

export const selectScenarioRosterDiff = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.roster_diff,
    (state) => state?.data
  );

export const selectScenarioReady = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.schedule,
    (state) => state.scenarios.entities[uid]?.schedule_diff,
    (schedule, schedule_diff) => {
      return !!(schedule?.data || schedule_diff?.data);
    }
  );

export const selectScenarioScheduleLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.schedule,
    (state) => state?.loading
  );
export const selectScenarioBrokenScheduleLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.broken_roster,
    (state) => state?.loading
  );
export const selectScenarioRosterLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.roster,
    (state) => state?.loading
  );
export const selectScenarioBrokenRosterLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.broken_roster,
    (state) => state?.loading
  );

export const selectScenarioScheduleDiffLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.schedule_diff,
    (state) => state?.loading
  );
export const selectScenarioRosterDiffLoading = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.roster_diff,
    (state) => state?.loading
  );

export const selectScenarioDutiesD0 = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.duties_d0,
    (state) => state
  );
export const selectScenarioDutiesD1 = (uid) =>
  createDeepEqualSelector(
    (state) => state.scenarios.entities[uid]?.duties_d1,
    (state) => state
  );
