import { createSelector, createSlice } from "@reduxjs/toolkit";
import difference from "lodash/difference";

const disruptionsSlice = createSlice({
  name: "disruptions",
  initialState: {
    aogs: [],
    curfews: [],
    vip_flights: [],
    no_claim_flights: [],
    no_shows: [],
    delays: [],
  },
  reducers: {
    updateAogs: (state, { payload }) => {
      state.aogs = payload;
    },
    updateCurfews: (state, { payload }) => {
      state.curfews = payload;
    },
    updateNoClaimFlights: (state, { payload }) => {
      state.no_claim_flights = payload;
      state.vip_flights = difference(state.vip_flights, payload);
    },
    updateVipFlights: (state, { payload }) => {
      state.vip_flights = payload;
      state.no_claim_flights = difference(state.no_claim_flights, payload);
    },
    updateDelays: (state, { payload }) => {
      state.delays = payload;
    },
  },
});

export const getDisruptions = (state) => state.disruptions;
export const getAogs = createSelector(getDisruptions, (state) => state.aogs);
export const getCurfews = createSelector(
  getDisruptions,
  (state) => state.curfews
);
export const getVipFlights = createSelector(
  getDisruptions,
  (state) => state.vip_flights
);
export const getNoClaimFlights = createSelector(
  getDisruptions,
  (state) => state.no_claim_flights
);
export const getNoShows = createSelector(
  getDisruptions,
  (state) => state.no_shows
);
export const getDelays = createSelector(
  getDisruptions,
  (state) => state.delays
);

export const getTotalAogs = createSelector(
  getDisruptions,
  (state) => getAogs(state).length
);
export const getTotalCurfews = createSelector(
  getDisruptions,
  (state) => getCurfews(state).length
);
export const getTotalVipFlights = createSelector(
  getDisruptions,
  (state) => getVipFlights(state).length
);
export const getTotalNoClaimFlights = createSelector(
  getDisruptions,
  (state) => getNoClaimFlights(state).length
);
export const getTotalNoShows = createSelector(
  getDisruptions,
  (state) => getNoShows(state).length
);
export const getTotalDelays = createSelector(
  getDisruptions,
  (state) => getDelays(state).length
);

export default disruptionsSlice;
