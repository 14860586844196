import React, { useState } from "react";

import {
  aircraft_metrics,
  crew_metrics,
  flight_metrics,
  pax_metrics,
  primary_metrics,
} from "./metrics";
import { DetailSection } from "./components/DetailSection";
import { FlightTables } from "./components/FlightTables";
import { CrewTables } from "./components/CrewTables";
import { PaxTables } from "./components/PaxTables";
import { GlobalTables } from "./components/GlobalTables";
import { AircraftTables } from "./components/AircraftTables";
import Octicon from "react-octicon";

import { Nav, Navbar } from "react-bootstrap";
import { deepMemo } from "utils";

import { getRQSelectedSolution } from "slices/solutions/solutionsSlice";
import { shallowEqual, useSelector } from "react-redux";
import { getOrgCurrencyFormatter } from "slices/userSlice";

const Navigation = deepMemo(({ title, toggle, setToggle }) => (
  <Navbar
    bg="dark-grey"
    variant="dark"
    className="trim-navbar justify-content-between"
    onClick={() => setToggle((prev) => !prev)}
  >
    <Navbar.Brand>{title}</Navbar.Brand>
    <Nav>
      <Nav.Item>
        <Nav.Link>
          {toggle ? (
            <Octicon name="chevron-up" />
          ) : (
            <Octicon name="chevron-down" />
          )}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  </Navbar>
));

export const SolutionDetails = deepMemo(
  ({ title, solution_scenario_uid, base_scenario_uid, scope = "d0" }) => {
    const [toggle, setToggle] = useState(false);
    const current_metrics =
      useSelector(getRQSelectedSolution, shallowEqual) || {};
    const currencyFormatter = useSelector(
      getOrgCurrencyFormatter,
      shallowEqual
    );

    const metrics = scope === "d1" ? current_metrics.d1 : current_metrics;

    return (
      <>
        <Navigation title={title} toggle={toggle} setToggle={setToggle} />
        {toggle && (
          <>
            <DetailSection
              title="Global"
              metrics={primary_metrics(currencyFormatter)}
              content={metrics}
              tables={
                <GlobalTables
                  metrics={metrics}
                  base_scenario_uid={base_scenario_uid}
                  solution_uid={solution_scenario_uid}
                  currencyFormatter={currencyFormatter}
                />
              }
            />
            <DetailSection
              title="Aircraft Details"
              metrics={aircraft_metrics}
              content={metrics}
              tables={
                <AircraftTables
                  base_scenario_uid={base_scenario_uid}
                  solution_scenario_uid={solution_scenario_uid}
                  metrics={metrics}
                  scope={scope}
                />
              }
            />
            <DetailSection
              title="Flights Disruptions"
              metrics={flight_metrics}
              content={metrics}
              tables={
                <FlightTables
                  base_scenario_uid={base_scenario_uid}
                  solution_scenario_uid={solution_scenario_uid}
                  metrics={metrics}
                  scope={scope}
                />
              }
            />
            <DetailSection
              title="Crew Details"
              metrics={crew_metrics}
              content={metrics}
              tables={
                <CrewTables
                  base_scenario_uid={base_scenario_uid}
                  solution_scenario_uid={solution_scenario_uid}
                  metrics={metrics}
                  scope={scope}
                />
              }
            />
            <DetailSection
              title="Passenger Details"
              metrics={pax_metrics}
              content={metrics}
              tables={
                <PaxTables
                  base_scenario_uid={base_scenario_uid}
                  solution_scenario_uid={solution_scenario_uid}
                  scope={scope}
                />
              }
            />
          </>
        )}
      </>
    );
  }
);
