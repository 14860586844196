import { ScenarioRequest } from "api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const colors = {
  Red: "#e15759",
  Yellow: "#edc949",
  Green: "#bfed49",
};

const mapping = {
  EASY: colors.Green,
  MEDIUM: colors.Yellow,
  DIFFICULT: colors.Red,
};

export const ScenarioDifficulty = ({ scenario_uid }) => {
  const [difficulty, setDifficulty] = useState();

  useEffect(() => {
    const fetchDifficulty = async () => {
      try {
        const request = new ScenarioRequest();
        const response = await request.get_difficulty(scenario_uid);
        setDifficulty(response.difficulty);
      } catch (err) {
        toast.error(`Problem computing difficulty: ${err.message}`);
      }
    };
    if (!!scenario_uid) fetchDifficulty();
  }, [scenario_uid]);

  return (
    <div
      style={{
        minHeight: "10px",
        width: "100%",
        backgroundColor: mapping[difficulty],
      }}
    />
  );
};
