import { createAsyncThunk } from "@reduxjs/toolkit";

import { ScenarioRequest } from "api/resources/ScenarioRequest";

export const fetchScenarioInfo = createAsyncThunk(
  "scenario/pullScenarioInfo",
  async ({ scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get(scenario_uid);
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioSchedule = createAsyncThunk(
  "scenario/pullScenarioSchedule",
  async ({ scenario_uid, params = {} }, { rejectWithValue }) => {
    const request_params = {
      ...params,
      filters: Object.keys(params.filters || [])
        .filter((key) => params.filters[key] && params.filters[key].active)
        .map((key) => ({
          kind: key,
          params: params.filters[key].params,
        })),
      scope: "full",
    };
    try {
      const request = new ScenarioRequest();
      const result = await request.get_schedule_svg(
        scenario_uid,
        request_params
      );
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioBrokenSchedule = createAsyncThunk(
  "scenario/pullScenarioBrokenSchedule",
  async ({ scenario_uid, params = {} }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_broken_schedule_svg(scenario_uid, {
        scope: "full",
        ...params,
      });
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioScheduleDiff = createAsyncThunk(
  "scenario/pullScenarioScheduleDiff",
  async (
    { scenario_uid, base_scenario_uid, params = {} },
    { rejectWithValue }
  ) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_schedule_diff_svg(
        scenario_uid,
        base_scenario_uid,
        { scope: "full", ...params }
      );
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioRoster = createAsyncThunk(
  "scenario/pullScenarioRoster",
  async ({ scenario_uid, params = {} }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_roster_svg(scenario_uid, {
        scope: "full",
        ...params,
      });
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioBrokenRoster = createAsyncThunk(
  "scenario/pullScenarioBrokenRoster",
  async ({ scenario_uid, params = {} }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_broken_roster_svg(scenario_uid, {
        scope: "full",
        ...params,
      });
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioRosterDiff = createAsyncThunk(
  "scenario/pullScenarioRosterDiff",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_roster_diff_svg(
        scenario_uid,
        base_scenario_uid,
        { scope: "full" }
      );
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioProblems = createAsyncThunk(
  "scenario/pullScenarioProblems",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const args = { scope: "full" };
      if (base_scenario_uid) args.base_scenario_uid = base_scenario_uid;
      const result = await request.get_problems(scenario_uid, args);
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioMemos = createAsyncThunk(
  "scenario/pullScenarioMemos",
  async ({ scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_memos(scenario_uid, { scope: "full" });
      return { uid: scenario_uid, result: result.memos };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioFlightCrews = createAsyncThunk(
  "scenario/pullScenarioFlightCrews",
  async ({ scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_flight_crews(scenario_uid, {
        scope: "full",
      });
      return { uid: scenario_uid, result: result.flight_crews };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioWarningsD0 = createAsyncThunk(
  "scenario/pullScenarioWarningsD0",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const args = { scope: "d0" };
      if (base_scenario_uid) args.base_scenario_uid = base_scenario_uid;
      const result = await request.get_warnings(scenario_uid, args);
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioWarningsD1 = createAsyncThunk(
  "scenario/pullScenarioWarningsD1",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const args = { scope: "d1" };
      if (base_scenario_uid) args.base_scenario_uid = base_scenario_uid;
      const result = await request.get_warnings(scenario_uid, args);
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioStandbys = createAsyncThunk(
  "scenario/pullScenarioStandbys",
  async ({ scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_standbys(scenario_uid);
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioCurfew = createAsyncThunk(
  "scenario/pullScenarioCurfew",
  async ({ scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_curfews_svg(scenario_uid, {
        scope: "full",
      });
      return { uid: scenario_uid, result };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioFlightCrewDutyInfo = createAsyncThunk(
  "scenario/pullScenarioFlightCrewDutyInfo",
  async ({ scenario_uid, flight, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_flight_crew_duty_info(
        scenario_uid,
        flight,
        { base_scenario_uid }
      );
      return { uid: scenario_uid, flight, result: result.fdp_info };
    } catch {
      return rejectWithValue({ uid: scenario_uid, flight });
    }
  }
);

export const fetchScenarioDutiesD0 = createAsyncThunk(
  "scenario/pullScenarioDutiesD0",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_crew_changes(
        scenario_uid,
        base_scenario_uid,
        { scope: "d0" }
      );
      return { uid: scenario_uid, result: result.crew };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);

export const fetchScenarioDutiesD1 = createAsyncThunk(
  "scenario/pullScenarioDutiesD1",
  async ({ scenario_uid, base_scenario_uid }, { rejectWithValue }) => {
    try {
      const request = new ScenarioRequest();
      const result = await request.get_crew_changes(
        scenario_uid,
        base_scenario_uid,
        { scope: "d1" }
      );
      return { uid: scenario_uid, result: result.crew };
    } catch {
      return rejectWithValue({ uid: scenario_uid });
    }
  }
);
