import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as api from "api";

const legendSlice = createSlice({
  name: "legend",
  initialState: { data: "" },
  reducers: {
    fetchLegendSucceeded: (state, action) => {
      state.data = action.payload;
    },
  },
});

legendSlice.selectors = {
  getLegend: createSelector(
    (state) => state.legend,
    (state) => state.data
  ),
};

export default legendSlice;

export const fetchLegend = () => (dispatch) => {
  return api
    .getLegend()
    .then(({ legend }) =>
      dispatch(legendSlice.actions.fetchLegendSucceeded(legend))
    )
    .catch((error) => toast.error(`Legend fetch failed: ${error.message}`));
};
