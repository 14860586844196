import React from "react";
import Octicon from "react-octicon";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactTable from "react-table-6";
import { deepMemo, hiddenCol, namedCol } from "utils";
import { DateTime } from "luxon";
import { getDurationFromISO } from "../utils";

export const DisplayCurfews = deepMemo(
  ({ curfews, onUpdate, stations_mapping = {} }) => {
    const columns = React.useMemo(
      () => [
        hiddenCol("id"),
        namedCol(
          "Station",
          (d) => stations_mapping[d.station_id],
          "STATION",
          250
        ),
        namedCol("Alternatives to exclude", (d) =>
          d.alternatives_to_exclude
            .map((alternative) => stations_mapping[alternative])
            .join(", ")
        ),
        namedCol("Event", (d) => d.event, "EVENT", 120),
        namedCol(
          "Start",
          (d) => DateTime.fromISO(d.start).toFormat("yyyy/MM/dd HH:mm"),
          "START",
          150
        ),
        namedCol(
          "End",
          (d) => DateTime.fromISO(d.end).toFormat("yyyy/MM/dd HH:mm"),
          "END",
          150
        ),
        namedCol(
          "Duration",
          (d) => getDurationFromISO(d.start, d.end),
          "DURATION",
          200
        ),
        {
          id: "actions",
          Header: "Actions",
          style: { textAlign: "center" },
          maxWidth: 100,
          Cell: (row) => (
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() =>
                  onUpdate(curfews.filter((aog) => aog.id !== row.original.id))
                }
              >
                <Octicon name="trashcan" />
              </Button>
            </ButtonGroup>
          ),
        },
      ],
      [curfews, onUpdate, stations_mapping]
    );

    return (
      <ReactTable
        multiSort
        data={curfews}
        columns={columns}
        minRows={3}
        resizable={false}
        defaultPageSize={5}
        noDataText={"No user Airport Closures found"}
      />
    );
  }
);
