import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { deepMemo } from "utils";

import {
  AircraftFinalSpacetimeLimitation,
  LimitationTable as AircraftTable,
} from "./rules/AircraftFinalSpacetimeLimitation";
import {
  CrewMemberFinalSpacetimeLimitation,
  LimitationTable as CrewTable,
} from "./rules/CrewMemberFinalSpacetimeLimitation";

export const rule_kinds = {
  AIRCRAFT_FINAL_SPACETIME_LIMITATION: "AIRCRAFT_FINAL_SPACETIME_LIMITATION",
  CREW_MEMBER_FINAL_SPACETIME_LIMITATION:
    "CREW_MEMBER_FINAL_SPACETIME_LIMITATION",
};

const mode_options = [
  {
    value: rule_kinds.AIRCRAFT_FINAL_SPACETIME_LIMITATION,
    label: "Aircraft final spacetime limitation",
  },
  {
    value: rule_kinds.CREW_MEMBER_FINAL_SPACETIME_LIMITATION,
    label: "Crew final spacetime limitation",
  },
];

export const TemporalRulesManager = deepMemo(
  ({ aircrafts, stations, scenarioUpdateFn }) => {
    const [mode, setMode] = useState(mode_options[0]);

    return (
      <React.Fragment>
        <Form
          style={{
            backgroundColor: "#d6d6d3",
            color: "#1a2e57",
            padding: "10px",
          }}
        >
          <Form.Row>
            <InputGroup as={Col} md={{ span: 4, offset: 4 }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Rule kind</InputGroup.Text>
              </InputGroup.Prepend>
              <Select
                classNamePrefix="Select"
                value={mode}
                styles={{ container: () => ({ padding: 0 }) }}
                className="form-control"
                options={mode_options}
                onChange={setMode}
              />
            </InputGroup>
          </Form.Row>
        </Form>
        {mode.value === rule_kinds.AIRCRAFT_FINAL_SPACETIME_LIMITATION && (
          <AircraftFinalSpacetimeLimitation
            aircrafts={aircrafts}
            stations={stations}
            scenarioUpdateFn={scenarioUpdateFn}
          />
        )}
        {mode.value === rule_kinds.CREW_MEMBER_FINAL_SPACETIME_LIMITATION && (
          <CrewMemberFinalSpacetimeLimitation
            stations={stations}
            scenarioUpdateFn={scenarioUpdateFn}
          />
        )}
      </React.Fragment>
    );
  }
);

export const TemporalRulesSummary = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>Aircraft final spacetime limitation</h2>
          <AircraftTable active />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Crew member final spacetime limitation</h2>
          <CrewTable active />
        </Col>
      </Row>
    </Container>
  );
};
