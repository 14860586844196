import { RequestBuilder } from "../utils";

export class QueuesRequest extends RequestBuilder {
  async get_stats() {
    return this._get(`/rqs`);
  }
  async get_queued_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}`);
  }
  async get_canceled_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=canceled`);
  }
  async get_deferred_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=deferred`);
  }
  async get_failed_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=failed`);
  }
  async get_finished_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=finished`);
  }
  async get_scheduled_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=scheduled`);
  }
  async get_started_jobs(queue_name) {
    return this._get(`/rqs/${queue_name}?registry=started`);
  }
  async get_job_details(queue_name, job_id) {
    return this._get(`/rqs/${queue_name}/${job_id}`);
  }
  async requeue_job(queue_name, job_id) {
    return this._post(`/rqs/${queue_name}/${job_id}`);
  }
  async cancel_job(queue_name, job_id) {
    return this._delete(`/rqs/${queue_name}/${job_id}`);
  }
}
