import { createSelector, createSlice } from "@reduxjs/toolkit";
import { findLastIndex } from "lodash";
import { toast } from "react-toastify";

import * as api from "api";

const configSlice = createSlice({
  name: "config",
  initialState: {},
  reducers: {
    fetchConfigSucceeded: (state, action) => {
      return action.payload;
    },
    updateTarget: (state, action) => {
      const { base, fleet, shift, role, new_target } = action.payload;
      const index = findLastIndex(state.STANDBYS_TARGETS, {
        base,
        fleet,
        shift,
      });
      state.STANDBYS_TARGETS[index].required_crews[role] = new_target;
      return state;
    },
  },
});

configSlice.selectors = {
  getConfig: (state) => state.config,
  getServiceType: createSelector(
    (state) => state.config,
    (state) =>
      state.FLIGHT_TYPE_CODES?.map((c) => ({ value: c, label: c })) ?? []
  ),
  getFlightStatus: createSelector(
    (state) => state.config,
    (state) =>
      state.LEG_STATUS_CODES?.map((c) => ({ value: c, label: c })) ?? []
  ),
  getRolesMap: createSelector(
    state => state.config,
    state => Object.fromEntries(Object.entries((state.INVERTED_ROLE_MAP || {})).map(([key, value]) => [value, key]))
  ),
  getIntertedRolesMap: createSelector(
    state => state.config,
    state => state.INVERTED_ROLE_MAP || {}
  ),
  getSolveAcmis: createSelector(
    state => state.config,
    state => state.ACMI_TOGGLE || false
  )
};

export default configSlice;

export const fetchConfig = () => (dispatch) => {
  return api
    .getConfig()
    .then(({ config }) =>
      dispatch(configSlice.actions.fetchConfigSucceeded(config))
    )
    .catch((error) => toast.error(`Config fetch failed: ${error.message}`));
};
