import * as React from 'react'
import {
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { DateTime } from 'luxon'
import { Alert, Col, Container, InputGroup, Row, Table } from "react-bootstrap";
import { formatDateTime } from 'utils'
import { useState } from 'react';
import Select from "react-select";
import { DateTimeRange } from 'components/common/DateTimeRange';

import styles from "./RecoveryQueriesTable.module.css";
import { Loader } from 'components/common/Loader';


const titleRow = ({ getValue }) => <span title={getValue()}>{getValue()}</span>;

const linkRow = ({ getValue }) => (
    <div>
        <a
            rel="noreferrer"
            target="_blank"
            href={getValue()}
        >
            View More
        </a>
    </div>
);

const columns = [
    {
        header: "Name",
        id: "name",
        accessorFn: (d) => d.display_name,
        cell: titleRow,
        enableColumnFilter: false
    },
    {
        header: "Current Time",
        id: "current_time",
        accessorFn: (d) => formatDateTime(d.current_time),
        cell: titleRow,
        enableColumnFilter: false
    },
    {
        header: "Execution Time",
        id: "datetime",
        accessorFn: (d) => formatDateTime(d.datetime),
        cell: titleRow,
        enableColumnFilter: false
    },
    {
        header: "User",
        id: "user",
        accessorFn: (d) => d.user_email,
        cell: titleRow,
    },
    {
        header: "Link",
        id: "link",
        accessorFn: (d) => `/recover/${d.uid}`,
        cell: linkRow,
        enableColumnFilter: false
    }
]

export const RecoveryQueriesTable = ({ data, loading }) => {
    const [columnFilters, setColumnFilters] = useState([])
    const [sorting, setSorting] = useState([])


    const table = useReactTable({
        data,
        columns,
        state: { columnFilters, sorting },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Alert variant="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <span>Filter by:</span>
                        {table.getHeaderGroups().map(headerGroup => (
                            <React.Fragment key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return !header.isPlaceholder && header.column.getCanFilter() ? (
                                        <div className="ml-3" key={header.column.id} >
                                            <Filter column={header.column} table={table} />
                                        </div>
                                    ) : null
                                })}
                            </React.Fragment>
                        ))}
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Loader show={loading} />
                    <Table bordered className={styles.Table}>
                        <thead className={styles.Thead}>
                            {table.getHeaderGroups().map(headerGroup => (
                                <React.Fragment key={headerGroup.id}>
                                    <tr className={styles.Tr} key={`sort_${headerGroup.id}`}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <th className={styles.Th} key={header.id} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: ' 🔼',
                                                                desc: ' 🔽',
                                                            }[header.column.getIsSorted()] ?? null}
                                                        </div>
                                                    )}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </thead>
                        <tbody className={styles.Tbody}>
                            {table.getRowModel().rows.map(row => (
                                <tr className={styles.Tr} style={{ backgroundColor: row.original.is_admin ? 'lightgrey' : '' }} key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td className={styles.Td} key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}


const Filter = ({ column, ...rest }) => {
    const columnFilterValue = column.getFilterValue()

    if (column.id === 'user') {
        const options = Array.from(column.getFacetedUniqueValues().keys()).map((value) => ({ value, label: value }))
        options.unshift({ value: undefined, label: 'All' })

        return (
            <InputGroup style={{ maxWidth: 400, minWidth: 300 }}>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">{column.id}</InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                    placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                    classNamePrefix="Select"
                    styles={{ container: () => ({ padding: 0 }) }}
                    className="form-control"
                    options={options}
                    value={options.find((option) => option.value === columnFilterValue) || null}
                    onChange={option => column.setFilterValue(option.value)}
                />
            </InputGroup >
        )
    } else if (column.id === 'current_time' || column.id === 'datetime') {
        const { start, end } = JSON.parse(columnFilterValue || '{}')
        return (
            <DateTimeRange
                reference_time={DateTime.utc()}
                defaultStart={start}
                defaultEnd={end}
                onUpdate={(_start, _end) => column.setFilterValue(JSON.stringify({ start: _start, end: _end }))}
            />
        )
    }
    return null


}