import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { DateTime } from "luxon";

import { getAircraftTypes } from "slices/aircraftTypesSlice";
import { getStations } from "slices/stationsSlice";
import { getFlights } from "slices/flightsSlice";
import { getCountries } from "slices/countriesSlice";
import configSlice from "slices/configSlice";

import { FilterIcon } from "components/common/Icons";
import { DateTimeRange } from "components/common/DateTimeRange";

import { buildConditions } from "./utils";

export const PlanFilterControl = ({
  as = Button,
  filters = {},
  reference_time = DateTime.utc(),
  onUpdate,
}) => {
  const Component = as;
  const [show, setShow] = useState(false);
  const [condition_filters, setConditions] = useState(filters.filters || {});
  const [filters_apply_all, setFiltersApplyAll] = useState(
    filters.filters_apply_all || true
  );
  const [filters_start, setFiltersStart] = useState(reference_time);
  const [filters_end, setFiltersEnd] = useState(
    reference_time.plus({ days: 1 })
  );
  const aircraft_types = useSelector(getAircraftTypes, shallowEqual);
  const stations = useSelector(getStations, shallowEqual);
  const flights = useSelector(getFlights, shallowEqual);
  const countries = useSelector(getCountries, shallowEqual);
  const service_types = useSelector(
    configSlice.selectors.getServiceType,
    shallowEqual
  );
  const flight_status = useSelector(
    configSlice.selectors.getFlightStatus,
    shallowEqual
  );

  const handleApply = () => {
    onUpdate({
      filters_apply_all,
      filters_start: filters_start.toISO({ includeOffset: false }),
      filters_end: filters_end.toISO({ includeOffset: false }),
      filters: condition_filters,
    });
    handleClose();
  };

  const handleReset = () => {
    const start = DateTime.utc();
    const end = DateTime.utc().plus({ days: 1 });
    setFiltersStart(start);
    setFiltersEnd(end);
    setFiltersApplyAll(true);
    setConditions({});
    onUpdate({
      filters_apply_all: true,
      filters_start: start.toISO({ includeOffset: false }),
      filters_end: end.toISO({ includeOffset: false }),
      filters: [],
    });
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const conditions = buildConditions({
    stations,
    countries,
    aircraftTypes: aircraft_types,
    flights,
    flightsStatus: flight_status,
    serviceType: service_types,
  });
  return (
    <>
      <Component variant="primary" onClick={handleShow}>
        <FilterIcon />
      </Component>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row
              style={{
                justifyContent: "space-between",
                marginBottom: "1rem",
                alignItems: "center",
              }}
            >
              Apply:
              <Form.Check
                type="radio"
                name="apply-all-or-any"
                style={{ marginLeft: "20px" }}
                id="all"
                checked={filters_apply_all}
                onChange={(e) => e.target.checked && setFiltersApplyAll(true)}
                label="All"
              />
              <Form.Check
                type="radio"
                name="apply-all-or-any"
                style={{ marginLeft: "10px" }}
                checked={!filters_apply_all}
                onChange={(e) => e.target.checked && setFiltersApplyAll(false)}
                label="Any"
              />
              <span style={{ marginLeft: "20px" }}>Effective period</span>
              <DateTimeRange
                reference_time={reference_time}
                default_start={filters_start}
                default_end={filters_end}
                onUpdate={(_start, _end) => {
                  setFiltersStart(_start);
                  setFiltersEnd(_end);
                }}
              />
            </Form.Row>
            <Row>
              <Col>
                {conditions.map((condition) => (
                  <Condition
                    key={condition.id}
                    condition={condition}
                    value={condition_filters[condition.id]}
                    default_value={condition_filters[condition.id]}
                    onChange={(id, value) => {
                      setConditions((prev) => ({ ...prev, [id]: value }));
                    }}
                  />
                ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Condition = ({ condition, default_value = {}, onChange }) => {
  const [active, setActive] = useState(default_value.active);
  const [options, setOptions] = useState(default_value.options);
  const [extra_options, setExtraOptions] = useState(
    default_value.extra_options
  );
  const [percentage, setPercentage] = useState(default_value.percentage ?? 0);

  useEffect(() => {
    onChange(condition.id, {
      active,
      options,
      extra_options,
      percentage,
      params: condition.paramExtractor({
        active,
        options,
        extra_options,
        percentage,
      }),
    });
    // eslint-disable-next-line
  }, [active, options, extra_options, percentage]);

  const select_styles = {
    container: () => ({
      minWidth: "200px",
      position: "relative",
      flexGrow: 2,
    }),
  };
  return (
    <Form.Row style={{ minHeight: "54px" }}>
      <Form.Group
        controlId={condition.id}
        style={{ display: "inline-flex", width: "100%" }}
      >
        <Form.Check
          type="checkbox"
          label={condition.label}
          checked={!!active}
          onChange={(e) => setActive(e.target.checked)}
          style={{
            marginRight: "20px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        />
        {!!condition.options && (
          <Select
            classNamePrefix="Select"
            styles={select_styles}
            value={options}
            isMulti
            onChange={(opt) => {
              setOptions(opt);
              if (opt) {
                setActive(true);
              } else {
                setActive(false);
              }
            }}
            options={condition.options}
          />
        )}
        {condition.id === "SECTOR" && (
          <Select
            classNamePrefix="Select"
            styles={select_styles}
            isMulti
            value={extra_options}
            onChange={setExtraOptions}
            options={condition.options}
          />
        )}
        {!!condition.percentage && (
          <InputGroup>
            <Form.Control
              type="number"
              min="0"
              max="100"
              value={percentage}
              styles={{ minWidth: "200px", position: "relative", flexGrow: 2 }}
              onChange={(e) => setPercentage(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        )}
      </Form.Group>
    </Form.Row>
  );
};
