import { RecoveryQuery } from "api";
import * as utils from "utils";

let cancelables = [];
const INTERVAL = 10 * 1000; // 10 seconds in milliseconds

// TODO: Future https://javascript.info/long-polling

export class RecoveryPulling {
  cancelables = [];
  subscribe(uid, cb) {
    this.clean();
    const cancelable = RecoveryQuery.get_(uid);
    cancelables.push(cancelable.c);
    return cancelable.rq.then((r) => {
      if (cb(r) && !this.killAll) {
        utils.sleep(INTERVAL).then(() => {
          this.subscribe(uid, cb);
        });
      }
    });
  }
  clean() {
    this.cancelables.forEach((controller) => {
      controller.abort();
    });
    this.cancelables = [];
  }
  unsubscribe() {
    this.killAll = true;
    this.cancelables.forEach((controller) => {
      controller.abort();
    });
    this.cancelables = [];
  }
}

export const cancellAll = () => {
  cancelables.forEach((controller) => {
    controller.abort();
  });
  cancelables = [];
};

const TIMEOUT_SECONDS = 15 * 60; // 15 minutes in seconds

export const isTimeout = (ref_date) => {
  const now = new Date();
  const diff = (now - ref_date) / 1000;
  return diff > TIMEOUT_SECONDS;
};

export const twoDaySortingStrategy = (a, b, desc) => {
  // Extracted from https://www.npmjs.com/package/react-table-v6
  // and adapted to work for solve 2d functionality
  // where column accessor returns an object like {d1: { value, label }, d2: { value, label }}
  a = a.d1?.value;
  b = b.d1?.value;
  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  // force any string values to lowercase
  a = a === "string" ? a.toLowerCase() : a;
  b = b === "string" ? b.toLowerCase() : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
  return 0;
};

const colors = {
  NotRecommended: "#A2A2A2",
  Redundant: "#43C1EA",
  Private: "#b3a8e6",
  Trivial: "#EAA043",
};

export const colors_legend = Object.keys(colors).map((key) => ({
  color: colors[key],
  text: key,
}));

export const backgroundColorByRow = (row = {}) => {
  if (row.is_redundant) {
    // only Big Blue users should be able to see these
    return colors.Redundant;
  } else if (row.is_private) {
    return colors.Private;
  } else if (row.is_trivial) {
    return colors.Trivial;
  } else if (!row.is_useful) {
    return colors.NotRecommended;
  }
  return "";
};

export const kpiLegend = {
  "ID": "Identifier of the solution",
  "D": "Day to which the KPIs are referred (D0 or D1)",
  "Labels": "Highlight solutions which are best according to some metrics (cost, cancellations, OTP15); * denotes the recommended solution",
  "Solution Time": "Total time that took to calculate current solution (mm:ss)",
  "Aircraft emoji": "Denotes whether the aircraft part of the solution is solved",
  "Crew emoji": "Denotes whether the crew part of the solution is solved",
  "Warning emoji": "Denotes whether there are warnings associated with the solution",
  "Total Cost": "Total cost of the solution",
  "BU": "Number of backup aircraft used",
  "STBY": "Number of standby crew members used",
  "CNL": "Number of cancelled flights",
  "RSD": "Number of flights rescheduled in the next day",
  "C15": "Number of flights arriving with more than 15 minutes of delay",
  "C180 (R)": "Number of flights arriving with more than 180 minutes of delay. In brackets the number of flights with delay between 150 and 180 minutes",
  "DV / RT": "Number of flights diverted and rerouted",
  "FL. B.": "Number of aircraft missing for operating flights/as backup/as spare in the following day",
  "BASE CHG": "Number of aircraft that end the day in a different station",
  "POS (CNL)": "Number of positionals flights created; in brackets number of positional flights cancelled",
  "PD": "Number of flights created to move passengers after a diversion",
  "MSP": "Number of crew members that end the day in a different station",
  "REST": "Number of crew members whose duty has a rest period violation",
  "FDP": "Number of crew members whose duty has an FDP warning",
  "F. CHG": "Number of crew members whose duty has a forced change",
  "EXT": "Number of crew members whose duty has been extended",
  "INC. F.": "Number of flights whose crew is incomplete",
  "OVB / RBK": "Number of passengers affected by operational overbooking and number of them rebooked",
  "CNL / RBK": "Number of passangers affected by a cancellation and number of them rebooked",
  "MCNX / RBK": "Number of passengers with misconnection and number of them rebooked",
  "DIV / RBK": "Number of passengers affected by a diversion and number of them rebooked",
  "AC": "Number of aircraft whose rotation has been modified",
  "DUTIES": "Number of crew members whose duty has been modified"
}
