import { authorizedRequest, httpMethods } from "./utils";

export { Auth } from "./auth";

export { Period } from "./resources/Period";
export { CrewTargets } from "./resources/CrewTargets";
export { AircraftTargets } from "./resources/AircraftTargets";
export { RecoveryQuery } from "./resources/RecoveryQuery";
export { Users, Roles } from "./resources/Users";
export { TemporalRules } from "./resources/TemporalRules";
export { ScenarioRequest } from "./resources/ScenarioRequest";
export { RecoveryQueryRequest } from "./resources/RecoveryQueryRequest";
export { TimelineRequest } from "./resources/TimelineRequest";

export const getTimeline = (reference_date) =>
  authorizedRequest("/timeline", httpMethods.GET, null, {
    reference_date,
  });
// Current resources
export const getAircraftTypes = () =>
  authorizedRequest("/aircraft_types", httpMethods.GET);
export const getAircrafts = () =>
  authorizedRequest("/aircraft", httpMethods.GET);
export const getFlights = () => authorizedRequest("/flights", httpMethods.GET);
export const getCountries = () =>
  authorizedRequest("/countries", httpMethods.GET);
export const getStations = () =>
  authorizedRequest("/stations", httpMethods.GET);

export const getCrews = () => authorizedRequest("/crews", httpMethods.GET);

// ???
export const getConfig = () => authorizedRequest("/config", httpMethods.GET);

// Global resources
export const getLegend = () => authorizedRequest("/legend", httpMethods.GET);

// Reports
export const getShiftHandoverData = () =>
  authorizedRequest("/shift_handover_report", httpMethods.GET);
export const setShiftHandoverData = (id, data) =>
  authorizedRequest(`/shift_handover_report/${id}`, httpMethods.POST, data);

export const getOCCBriefingData = () =>
  authorizedRequest(`/occ_briefing_report`, httpMethods.GET);
