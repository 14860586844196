import React from "react";
import { ConnectedRouter } from "connected-react-router";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import posthog from "posthog-js";

import { App } from "./components/App";
import store, { history } from "./store";
import { getEnvironment, isLocalhost, isPro } from "utils";

import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import "react-table-6/react-table.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

toast.configure();

if (!isLocalhost) {
  Sentry.init({
    dsn: "https://f82ab7307578461a8fad24074b35baa0@sentry.io/1524329",
    environment: getEnvironment(),
    // integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tracesSampleRate: 1.0,
  });
}

if (isPro) {
  posthog.init("qpD0eu6i9LRF7ysuwN9jIesEar5tHH9R1YsgzFBSoLs", {
    api_host: "https://app.posthog.com",
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
        <App />
      </>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
