import min from "lodash/min";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { deepCompareEquals } from "utils";

export const isSolutionRedundant = (solution, other_solutions) => {
  if (!solution.can_be_recommended) return false;

  const candidate_solutions = other_solutions.filter(
    (solution) => solution.can_be_recommended && !solution.is_redundant
  );

  const min_cancelations = candidate_solutions
    ? min(candidate_solutions.map((solution) => solution.TotalCancellations))
    : Infinity;
  
  if (solution.internal_id.includes("free-swap") && solution.TotalCancellations > min_cancelations)
    return true;
  
  for (let index = 0; index < candidate_solutions.length; index++) {
    const reference_solution = candidate_solutions[index];
    const reference_d0_dominates = isSolutionDayDominating(reference_solution, solution);
    if (reference_d0_dominates && !!reference_solution.d1 && !!solution.d1) {
      const reference_d1_dominates = isSolutionDayDominating(reference_solution.d1, solution.d1);
      if (reference_d1_dominates) {
        return true;
      }
    }
  }

  return false;
};

export const label_criterias = {
  COST: ["TotalCost", "TotalOTP15Violations"],
  CNL: ["TotalCancellations", "TotalCost", "TotalOTP15Violations"],
  OTP15: ["TotalCancellations", "TotalOTP15Violations", "TotalCost"],
};

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  deepCompareEquals,
  deepCompareEquals
);

const comparison_metrics = [
  "TotalCost",
  "TotalCancellations",
  "TotalCancellationsOTP180Violations",
  "TotalMispositionedAircrafts",
  "TotalMispositionedCrewMembers",
  "total_canceled_pax",
  "TotalOTP120ViolationsHighPriority",
  "TotalWarnings",
  "TotalOTP15Violations",
];

const isSolutionDayDominating = (ref_solution_day_metrics, new_solution_day_metrics) => {
  return comparison_metrics
    .map((metric) => new_solution_day_metrics[metric] >= ref_solution_day_metrics[metric])
    .every((result) => result);}