import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";

import { ErrorNav } from "components/common/ErrorNav";
import { deepMemo, useDeepCompareCallback } from "utils";
import { AddSolution } from "../../AddSolution";
import { Dot } from "../../SolutionDetails/components/BaseTable";
import { colors_legend } from "../utils";
import { KpiLegend } from "./KpiLegend";
import { ScenarioRequest } from "api";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { Loader } from "components/common/Loader";


export const SolutionsNav = deepMemo(
  ({
    loading,
    completed,
    error = "",
    query_uid,
    base_scenario_uid,
    onSolutionUploadSuccess,
    showAddSolution,
    show_legend,
    solution_uid
  }) => {
    const [exporting_solution, setExportingSolution] = useState(false)
    let title = "";
    if (loading) title += "Looking for solutions ...";
    if (!loading && completed) title += "Search completed";

    const showDownloadSolution = !!solution_uid && !!base_scenario_uid

    const onDownloadSolution = useDeepCompareCallback(async () => {
      setExportingSolution(true)
      try {
        const request = new ScenarioRequest();
        const response = await request.get_xlsx_diff(
          solution_uid,
          base_scenario_uid
        );
        const blob = await response.blob();
        FileSaver.saveAs(
          blob,
          `solution_export_${solution_uid}__${base_scenario_uid}.xlsx`
        );
      } catch (error) {
        toast.error("Error exporting solution")
      } finally {
        setExportingSolution(false)
      }

    }, [solution_uid, base_scenario_uid]);

    return (
      <>
        <Loader show={exporting_solution} />
        <Navbar
          bg="light-grey"
          variant="dark"
          className="justify-content-between trim-navbar"
        >
          <Navbar.Brand>{title}</Navbar.Brand>
          <Nav>
            {show_legend && (
              <div style={{ display: "flex", alignItems: "center", color: "white", marginRight: "10px" }}>
                {colors_legend.map((l) => (
                  <Dot key={l.color} color={l.color} text={l.text} />
                ))}
              </div>
            )}
            {!!showDownloadSolution &&
              <Nav.Item>
                <Nav.Link onClick={onDownloadSolution}>Download Solution</Nav.Link>
              </Nav.Item>
            }
            {!!showAddSolution && (
              <Nav.Item>
                <AddSolution
                  as={Nav.Link}
                  recovery_query_uid={query_uid}
                  base_scenario_uid={base_scenario_uid}
                  onSuccess={onSolutionUploadSuccess}
                >
                  Add Solution
                </AddSolution>
              </Nav.Item>
            )}
            <Nav.Item>
              <KpiLegend
                as={Nav.Link}
              />
            </Nav.Item>
          </Nav>
        </Navbar>
        <ErrorNav
          error={error.includes("timed out after 900 seconds") ? "" : error}
        />
      </>
    );
  }
);
