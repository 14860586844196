import React from "react";

import { RosterSVGViewer } from "components/common/viewers/RosterSVGViewer";
import { Loader } from "../Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  pullScenarioRoster,
  selectScenarioBrokenRoster,
  selectScenarioRoster,
  selectScenarioRosterDiff,
  selectScenarioRosterDiffLoading,
  selectScenarioRosterLoading,
} from "slices/scenarios/scenariosSlice";
import { deepMemo, useDeepCompareEffect } from "utils";

export const ScenarioRosterViewer = deepMemo(
  ({ scenario_uid, base_scenario_uid, percentage, hide, show_diff }) => {
    const loading = useSelector(
      selectScenarioRosterLoading(scenario_uid),
      shallowEqual
    );
    const loading_diff = useSelector(
      selectScenarioRosterDiffLoading(scenario_uid),
      shallowEqual
    );
    const roster_image = useSelector(
      selectScenarioRoster(scenario_uid),
      shallowEqual
    );
    const broken_roster_image = useSelector(
      selectScenarioBrokenRoster(scenario_uid),
      shallowEqual
    );
    const diff_roster_image = useSelector(
      selectScenarioRosterDiff(scenario_uid),
      shallowEqual
    );

    const show_spinner = show_diff ? loading_diff : loading;

    const dispatch = useDispatch();

    useDeepCompareEffect(() => {
      if (
        !show_diff &&
        scenario_uid &&
        !loading &&
        !roster_image &&
        !broken_roster_image &&
        !hide
      ) {
        dispatch(pullScenarioRoster(scenario_uid, base_scenario_uid));
      }
    }, [
      dispatch,
      loading,
      roster_image,
      broken_roster_image,
      scenario_uid,
      show_diff,
      hide,
    ]);

    return (
      <>
        <Loader show={show_spinner} />
        <RosterSVGViewer
          hide={hide || show_diff}
          svg={broken_roster_image}
          percentage={percentage}
        />
        <RosterSVGViewer
          hide={hide}
          svg={show_diff ? diff_roster_image : roster_image}
          percentage={percentage}
        />
      </>
    );
  }
);
