import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import { simpleCol } from "utils";

import { ScenarioRequest } from "api/resources/ScenarioRequest";

import { BaseTable, colors } from "../BaseTable";


export const FlightTables = ({
  solution_scenario_uid,
  base_scenario_uid,
  metrics,
  scope,
  loading_metrics,
}) => {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCrewData = async () => {
      setLoading(true);
      try {
        const request = new ScenarioRequest();
        const response = await request.get_flight_changes(
          solution_scenario_uid,
          base_scenario_uid,
          { scope }
        );
        setFlights(response.flights);
      } catch (err) {
        toast.error(`Flights fetch failed: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    if (!!base_scenario_uid && !!solution_scenario_uid) fetchCrewData();
  }, [base_scenario_uid, solution_scenario_uid, scope]);

  return (
    <Container fluid>
      <h3>Affected flights</h3>
      <Row>
        <Col>
          <BaseTable
            title="Flights with delay"
            data={flights}
            loading={loading}
            columns={[
              simpleCol("FlightNumber", 170),
              simpleCol("Route"),
              simpleCol("Delay"),
              simpleCol("IntroducedDelay"),
              simpleCol("ETD"),
              simpleCol("STD"),
              {
                Header: "IsRegulated",
                id: "IsRegulated",
                accessor: (d) => (d.IsRegulated ? "Yes" : "No"),
                style: { textAlign: "center" },
              },
            ]}
            bgLookup={({ DelayMinutes }) => {
              if (DelayMinutes > 180) {
                return colors.Red;
              }
              if (DelayMinutes > 120) {
                return colors.Yellow;
              }
              return "";
            }}
            defaultSorted={[
              {
                id: "DelayMinutes",
                desc: true,
              },
            ]}
            legend={[
              { color: colors.Red, text: " > 180" },
              { color: colors.Yellow, text: " > 120" },
            ]}
          />
          <BaseTable
            title="Cancellations"
            data={metrics.Cancellations}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("STD"),
              simpleCol("RebookingDays"),
            ]}
          />
          <BaseTable
            title="Rescheduled"
            data={metrics.Rescheduled}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("OldSTD"),
              simpleCol("NewSTD"),
            ]}
          />
          <BaseTable
            title="Diverted Flights"
            data={metrics.DivertedFlights}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("OriginalRoute"),
              simpleCol("STD"),
              simpleCol("ETD"),
            ]}
          />
          <BaseTable
            title="Rerouted Flights"
            data={metrics.ReroutedFlights}
            loading={loading_metrics}
            columns={[
              simpleCol("FlightNumber"),
              simpleCol("Route"),
              simpleCol("OriginalRoute"),
              simpleCol("STD"),
              simpleCol("ETD"),
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};
