import React from "react";
import { Nav } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import { standby_columns } from "./columns";

export const exportStandBys = (standbys, fileName = "standbys") => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const current_time_text = moment().format(
    "YYYY/MM/DD-HH:mm"
  );
  const columnOrder = standby_columns().map(column => column.accessor instanceof Function ? column.id : column.accessor);

  const Sheets = {
    Standbys: XLSX.utils.json_to_sheet(standbys, { header: columnOrder }),
  };
  const wb = {
    Sheets,
    SheetNames: Object.keys(Sheets),
  };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + current_time_text + fileExtension);
};

export const ExportStandbysButton = ({ standbys, filename, children, ...props }) => {
  const buildReport = () => {
    exportStandBys(standbys, filename);
  };
  return (
    <Nav.Link {...props} onClick={buildReport}>
      {children}
    </Nav.Link>
  );
};
